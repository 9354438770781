import { useAuth0 } from '@auth0/auth0-react';
import config from '../config/configuration';
import { useTgAuth } from './use-tg-auth';
const extraConfig = config().extra;

export const useIsUserHasPermission = (permission: string): boolean => {
  const { user } = useAuth0();
  const { isTGAuthAuthenticated } = useTgAuth();

  // Will assume any user with a valid TG Auth token has permission
  // Backend will return 401 if user is not admin or superadmin
  return isTGAuthAuthenticated || Boolean(user?.[`https://${extraConfig.hostname}/permissions`]?.includes(permission));
};
