import React from 'react';
import { LoginPageProps } from './login.types';
import { Box, GoogleButton } from '@theguarantors/ui-kit-v3';
import { OneColumnLayout } from '../../components';
import { Header, Text } from '@theguarantors/ui-kit-v3/design-system';
import { FeedbackMessage } from '@theguarantors/ui-kit-v3/components/FeedbackMessage';
import './login.scss';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const LoginPage: React.FC<LoginPageProps> = ({ ...props }) => {
  const { state: locationState } = useLocation<Record<string, string>>();
  const locationErrorMessage = locationState?.errorDescription;

  const { loginWithRedirect } = useAuth0();
  const googleLogin = async (): Promise<void> =>
    loginWithRedirect({
      connection: 'Google-OAuth2-Connection',
    });

  return (
    <OneColumnLayout>
      <Header as="h1" color="neutral.main" width="100%" justifyContent="center" textAlign="center" mb="md">
        Welcome to TheGuarantors
      </Header>
      <Box {...props} m="auto" className="login-form">
        <Text as="p" color="neutral.main" textAlign="center" mb="sm" mt="clear">
          Use your TheGuarantors email account to login
        </Text>
        {locationErrorMessage && (
          <Box mb="md" width="100%">
            <FeedbackMessage type="error">{locationErrorMessage}</FeedbackMessage>
          </Box>
        )}
        <GoogleButton onClick={googleLogin}>Continue with Google</GoogleButton>
      </Box>
    </OneColumnLayout>
  );
};

export default LoginPage;
