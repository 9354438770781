"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentWrapper = exports.ContentBlock = exports.Wrapper = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var design_system_1 = require("../../design-system");
var wrapperMobileStyles = (0, design_system_1.forMobiles)((0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: column;\n"], ["\n  flex-direction: column;\n"]))));
exports.Wrapper = (0, styled_components_1.default)(design_system_1.Box).attrs(function (_a) {
    var _b = _a.maxHeight, maxHeight = _b === void 0 ? "20rem" : _b;
    return ({
        maxHeight: maxHeight,
    });
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid var(--color-table-disabledBackground);\n  padding: var(--space-sm);\n  display: flex;\n\n  ", "\n"], ["\n  border: 1px solid var(--color-table-disabledBackground);\n  padding: var(--space-sm);\n  display: flex;\n\n  ", "\n"])), wrapperMobileStyles);
exports.ContentBlock = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: var(--space-xs);\n"], ["\n  padding: var(--space-xs);\n"])));
var contentWrapperMobileStyle = (0, design_system_1.forMobiles)((0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: calc(100% - var(--space-xxl));\n"], ["\n  flex: calc(100% - var(--space-xxl));\n"]))));
exports.ContentWrapper = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 100%;\n  overflow-y: auto;\n\n  ", "\n"], ["\n  flex: 100%;\n  overflow-y: auto;\n\n  ", "\n"])), contentWrapperMobileStyle);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
