import { LazyQueryResult, OperationVariables, QueryLazyOptions, gql, useLazyQuery } from '@apollo/client';
import { PaginatedSummaryApplications } from '../api/api-types';
import { ReadableAnswers } from '../pages/details/details.constants';

interface Result {
  paginatedApplications: PaginatedSummaryApplications | undefined;
  loading: boolean;
  called: boolean;
  fetchPaginatedApplications: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => Promise<LazyQueryResult<ApplicationResponse, OperationVariables>>;
}

interface ApplicationResponse {
  paginatedSummaryApplications: PaginatedSummaryApplications;
}

export const Applications = gql`
  query Applications($pagination: PaginatedApplicationsInput) {
    paginatedSummaryApplications(pagination: $pagination) {
      data {
        id
        applicant
        status
        email
        monthlyRent
        leaseStartDate
        buildingAndUnit
      }
      total
      count
      page
      pageCount
    }
  }
`;

export function usePaginatedApplications(): Result {
  const [fetchPaginatedApplications, { data, loading, called }] = useLazyQuery<ApplicationResponse>(Applications);
  const mappedPaginatedApplications: PaginatedSummaryApplications | undefined = data && {
    ...data.paginatedSummaryApplications,
    data: data.paginatedSummaryApplications.data.map((app) => ({
      ...app,
      monthlyRent: app.monthlyRent || ReadableAnswers.NULL,
      leaseStartDate: app.leaseStartDate || ReadableAnswers.NULL,
    })),
  };

  return {
    paginatedApplications: mappedPaginatedApplications,
    loading,
    called,
    fetchPaginatedApplications,
  };
}
