/**
 * All the error codes we can receive from the BFF.
 *
 * Ideally this file should be shared between the two projects (TODO: client-monorepo?)
 *
 * At the top you have a list of Apollo Server error codes.
 * Apollo error codes (as scattered strings) can be only be found (afaik) in the source code 🤷‍♂️:
 * https://github.com/apollographql/apollo-server/blob/main/packages/apollo-server-errors/src/index.ts
 *
 * At the bottom you can add any custom (more specific) BFF error codes
 */
export enum BffErrorCodes {
  // APOLLO CODES
  /**
   * We don't know what happened or we don't want you to know
   */
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',

  /**
   * The dev probably messed up the query
   */
  GRAPHQL_PARSE_FAILED = 'GRAPHQL_PARSE_FAILED',

  /**
   * The client-side validation most likely needs to be strengthened
   */
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',

  /**
   *  User didn't authenticate (a.k.a. I got no token or it's too old)
   */
  UNAUTHENTICATED = 'UNAUTHENTICATED',

  /**
   *  User doesn't have the right permissions
   */
  FORBIDDEN = 'FORBIDDEN',

  /**
   * User entered the wrong thing -- can be used for server-side validation errors:
   * https://www.apollographql.com/blog/full-stack-error-handling-with-graphql-apollo-5c12da407210/#5e67
   */
  BAD_USER_INPUT = 'BAD_USER_INPUT',

  // Feature flags from client is not sync to BFF feature flags
  FEATURE_FLAG_VALIDATION_FAILED = 'FEATURE_FLAG_VALIDATION_FAILED',

  /**
   * FOR-THE-FUTURE: some fancy stuff (?)
   */
  PERSISTED_QUERY_NOT_FOUND = 'PERSISTED_QUERY_NOT_FOUND',

  /**
   * FOR-THE-FUTURE: some fancy stuff (?)
   */
  PERSISTED_QUERY_NOT_SUPPORTED = 'PERSISTED_QUERY_NOT_SUPPORTED',

  // TG BFF CUSTOM CODES
  // our custom BFF codes here

  /**
   * The entity requested does not exist
   */
  NOT_FOUND = 'NOT_FOUND',

  /**
   * The requested resource is behind a feature flag that is disabled or not defined in split.io
   */
  FEATURE_DISABLED = 'FEATURE_DISABLED',
}
