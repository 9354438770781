"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToggleSwitch = void 0;
var react_1 = __importStar(require("react"));
var styled_system_1 = require("styled-system");
var design_system_1 = require("../../design-system");
var ToggleSwitch_styles_1 = require("./ToggleSwitch.styles");
var useToggleState = function (_a) {
    var uncontrolled = _a.uncontrolled, _b = _a.value, value = _b === void 0 ? false : _b, _c = _a.onChange, onChange = _c === void 0 ? function () { } : _c;
    var _d = (0, react_1.useState)(value || false), stateIsChecked = _d[0], setStateIsChecked = _d[1];
    if (uncontrolled) {
        return [value, onChange];
    }
    return [stateIsChecked, setStateIsChecked];
};
var ToggleSwitch = function (_a) {
    var label = _a.label, _b = _a.color, _color = _b === void 0 ? "primary.main" : _b, _c = _a.onChange, _onChange = _c === void 0 ? function () { } : _c, _value = _a.value, disabled = _a.disabled, labelProps = _a.labelProps, containerProps = _a.containerProps, _d = _a.uncontrolled, uncontrolled = _d === void 0 ? false : _d, props = __rest(_a, ["label", "color", "onChange", "value", "disabled", "labelProps", "containerProps", "uncontrolled"]);
    var color = (0, react_1.useMemo)(function () { return (0, styled_system_1.get)(design_system_1.colors, _color) || _color; }, [_color]);
    var _e = useToggleState({
        uncontrolled: uncontrolled,
        value: _value,
        onChange: _onChange,
    }), value = _e[0], onChange = _e[1];
    var __onChange = (0, react_1.useCallback)(function (e) {
        if (disabled) {
            return;
        }
        var checked = e.target.checked;
        onChange(checked);
        onChange === null || onChange === void 0 ? void 0 : onChange(checked);
    }, [disabled, onChange]);
    return (react_1.default.createElement(ToggleSwitch_styles_1.SwitchWrapper, __assign({}, props),
        react_1.default.createElement(ToggleSwitch_styles_1.SwitchContainer, __assign({ value: value, color: color }, containerProps),
            react_1.default.createElement(ToggleSwitch_styles_1.SwitchInput, { type: "checkbox", onChange: __onChange, disabled: disabled }),
            react_1.default.createElement(ToggleSwitch_styles_1.ToggleElement, { disabled: disabled })),
        react_1.default.createElement(ToggleSwitch_styles_1.SwitchLabel, __assign({}, labelProps, { disabled: disabled }), label)));
};
exports.ToggleSwitch = ToggleSwitch;
