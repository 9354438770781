"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoResultsMessage = exports.SearchInput = exports.TableBody = exports.TableHeader = exports.SortingIcon = void 0;
var react_1 = __importStar(require("react"));
var Input_1 = require("../Input");
var TextInput_component_1 = require("../TextInput/TextInput.component");
var design_system_1 = require("../../design-system");
var icons_1 = require("../../icons");
var Table_styles_1 = require("./Table.styles");
var SortingIcon = function (_a) {
    var isSorted = _a.isSorted, isSortedDesc = _a.isSortedDesc;
    var reusableProps = {
        width: "0.75rem",
        height: "0.75rem",
        color: "primary.main",
    };
    var reusableUnactiveProps = {
        width: "0.5rem",
        height: "0.5rem",
        color: "neutral.main",
    };
    return (react_1.default.createElement(Table_styles_1.SortingIconContainer, null, isSorted ? (isSortedDesc ? (react_1.default.createElement(icons_1.Icon, __assign({ name: "expandMore" }, reusableProps))) : (react_1.default.createElement(icons_1.Icon, __assign({ name: "expandLess" }, reusableProps)))) : (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(icons_1.Icon, __assign({ name: "expandLess" }, reusableUnactiveProps)),
        react_1.default.createElement(icons_1.Icon, __assign({ name: "expandMore" }, reusableUnactiveProps))))));
};
exports.SortingIcon = SortingIcon;
function TableHeader(_a) {
    var headerGroups = _a.headerGroups, sortable = _a.sortable, getColumnProps = _a.getColumnProps, getHeaderProps = _a.getHeaderProps;
    var headerPropsOptions = (0, react_1.useCallback)(function (column) {
        var reusableOptions = [
            {
                className: column.className,
                style: column.style,
            },
            getColumnProps(column),
            getHeaderProps(column),
        ];
        return sortable ? __spreadArray([column.getSortByToggleProps()], reusableOptions, true) : reusableOptions;
    }, [sortable, getColumnProps, getHeaderProps]);
    return (react_1.default.createElement(Table_styles_1.THead, null, headerGroups.map(function (headerGroup) { return (react_1.default.createElement(Table_styles_1.Tr, __assign({}, headerGroup.getHeaderGroupProps()), headerGroup.headers.map(function (column) { return (react_1.default.createElement(Table_styles_1.Th, __assign({}, column.getHeaderProps.apply(column, headerPropsOptions(column)), { isSorted: column.isSorted }),
        react_1.default.createElement(Table_styles_1.ThContainer, null,
            column.render("Header"),
            sortable && react_1.default.createElement(exports.SortingIcon, { isSorted: column.isSorted, isSortedDesc: column.isSortedDesc })))); }))); })));
}
exports.TableHeader = TableHeader;
var cellRenderer = function (cell, index) { var _a, _b, _c; return typeof cell.value === "object" ? (_b = (_a = cell === null || cell === void 0 ? void 0 : cell.value) === null || _a === void 0 ? void 0 : _a.customRender) === null || _b === void 0 ? void 0 : _b.call(_a, (_c = cell === null || cell === void 0 ? void 0 : cell.value) === null || _c === void 0 ? void 0 : _c.value, index) : cell.render("Cell"); };
function TableBody(_a) {
    var getTableBodyProps = _a.getTableBodyProps, list = _a.list, prepareRow = _a.prepareRow, getRowProps = _a.getRowProps, getColumnProps = _a.getColumnProps, getCellProps = _a.getCellProps;
    return (react_1.default.createElement("tbody", __assign({}, getTableBodyProps()), list.map(function (row) {
        prepareRow(row);
        return (react_1.default.createElement(Table_styles_1.TableRow, __assign({}, row.getRowProps(getRowProps(row))), row.cells.map(function (cell, index) { return (react_1.default.createElement(Table_styles_1.TableCell, __assign({}, cell.getCellProps([getColumnProps(cell === null || cell === void 0 ? void 0 : cell.column), getCellProps(cell)])), cellRenderer(cell, index))); })));
    })));
}
exports.TableBody = TableBody;
var SearchInput = function (_a) {
    var onChange = _a.onChange, _b = _a.extraProps, extraProps = _b === void 0 ? {} : _b;
    var _c = (0, TextInput_component_1.useChangeValue)("", onChange), value = _c[0], onTextChange = _c[1];
    var stateProps = {
        value: value,
        onChange: onTextChange,
        handleOnLabelClick: function () {
            onTextChange === null || onTextChange === void 0 ? void 0 : onTextChange({ target: { value: "" } });
        },
    };
    return (react_1.default.createElement(design_system_1.Box, { width: "24rem", maxWidth: "100%", marginRight: "lg" },
        react_1.default.createElement(Input_1.Input, __assign({ type: "text", icon: value ? "close" : "search", placeholder: "Search", height: "xl", variant: "simple" }, extraProps, stateProps))));
};
exports.SearchInput = SearchInput;
var NoResultsMessage = function (_a) {
    var children = _a.children;
    return (react_1.default.createElement(design_system_1.Box, { p: "md", display: "flex", justifyContent: "center" }, children));
};
exports.NoResultsMessage = NoResultsMessage;
