import { RESIDENCY_STATUS } from '@theguarantors/tg-modern-business';

export const TRUE_STR = 'true';
export const FALSE_STR = 'false';

export enum ReadableAnswers {
  NULL = '--',
  YES = 'Yes',
  NO = 'No',
}

export enum DateFormats {
  WITH_DASHES = 'yyyy-MM-dd',
  WITH_SLASHES = 'yyyy/MM/dd',
  PLACEHOLDER = 'YYYY/MM/DD',
}

export const MAX_DATE_YEAR = 2040;

export const residencyDisplayAnswers: Record<string, string> = {
  [RESIDENCY_STATUS.US_CITIZEN.toLowerCase()]: 'US Citizen',
  [RESIDENCY_STATUS.GREEN_CARD_HOLDER.toLowerCase()]: 'Green Card holder',
  [RESIDENCY_STATUS.NEITHER.toLowerCase()]: 'Neither',
  yes: 'US Citizen',
  no: 'Neither',
};

export const DEFAULT_PREMIUM_CANCELLATION_FEE = 100;
