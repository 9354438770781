import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomRenderer } from '@theguarantors/ui-kit-v3/components/Table/Table.types';
import { ApplicationEntity } from '../../../api/api-types';
import { CONTACT_TYPE, NormalizedContactDetailsFields, NormalizedContactDetailsResult } from '../normalize-data';
import { normalizeContactDetailData } from '../normalize-data';

interface Params {
  readonly application: ApplicationEntity | undefined;
  readonly isReady: boolean;
}

interface ContactDetail {
  readonly value: string;
  readonly customRender: CustomRenderer;
}

export type ContactDetailsResult = {
  [key in NormalizedContactDetailsFields]: ContactDetail;
};

type FieldFunction = (value: string) => React.ReactElement;

type EnhancedFields = {
  [key in NormalizedContactDetailsFields]: FieldFunction;
};

type AllowedEnhancedFields = Extract<NormalizedContactDetailsFields, 'email'>;

type AllowedEnhancers = Pick<EnhancedFields, AllowedEnhancedFields>;

export const useContactDetails = (params: Params): ContactDetailsResult[] => {
  const { application, isReady } = params;
  const [data, setData] = useState<ContactDetailsResult[]>();

  const getEmailField = useCallback((value: string): React.ReactElement => {
    return <a href="/">{value}</a>;
  }, []);

  const getField = useCallback((value: string): React.ReactElement => {
    return <span>{value}</span>;
  }, []);

  const enhancedFieldsMap: AllowedEnhancers = useMemo(
    () => ({
      email: getEmailField,
    }),
    [getEmailField],
  );

  const render = useCallback(
    (key: string, value: string): React.ReactElement => {
      const handler: FieldFunction = enhancedFieldsMap[key as AllowedEnhancedFields];
      if (handler) {
        return handler(value);
      }

      return getField(value);
    },
    [enhancedFieldsMap, getField],
  );

  const getContactDetails = useCallback(
    <T extends unknown>(contactType: CONTACT_TYPE, entity: T): ContactDetailsResult | undefined => {
      const result = {} as ContactDetailsResult;
      const details = normalizeContactDetailData(contactType, entity) as NormalizedContactDetailsResult;
      const keys: string[] = Object.keys(details || {});
      return keys.length
        ? keys.reduce<ContactDetailsResult>((res: ContactDetailsResult, key: string): ContactDetailsResult => {
            const currentValue = details[key as NormalizedContactDetailsFields];
            res[key as NormalizedContactDetailsFields] = {
              value: currentValue,
              customRender: (value) => typeof value === 'string' && render(key, value),
            };
            return res;
          }, result)
        : undefined;
    },
    [render],
  );

  useEffect(() => {
    if (isReady) {
      const applicant = getContactDetails(CONTACT_TYPE.APPLICANT, application);
      const leasingAgent = getContactDetails(
        CONTACT_TYPE.LEASING_AGENT,
        application?.lease?.property?.primaryRole?.contact,
      );
      setData([applicant, leasingAgent].filter(Boolean) as ContactDetailsResult[]);
    }
  }, [application, getContactDetails, isReady]);

  return data ? data : [];
};
