import { ApolloError, MutationFunction, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { UpdatePolicyActionTypes } from '../details.types';

interface PolicyUpdateVariables {
  id: number;
  applicationId: number;
  isSkipPayment?: boolean;
  actionType: UpdatePolicyActionTypes;
  refundInCents?: number | null;
  returnedPremiumInCents?: number;
}

interface UsePolicyAmendResult {
  data: any | null | undefined;
  loading: boolean;
  error?: ApolloError;
  policyUpdate: MutationFunction<any, PolicyUpdateVariables>;
}

export const PolicyAmendMutation = gql`
  mutation policyUpdate(
    $id: Int!
    $applicationId: Int!
    $isSkipPayment: Boolean
    $actionType: String!
    $refundInCents: Int
    $returnedPremiumInCents: Int
  ) {
    policyUpdate(
      id: $id
      applicationId: $applicationId
      isSkipPayment: $isSkipPayment
      actionType: $actionType
      refundInCents: $refundInCents
      returnedPremiumInCents: $returnedPremiumInCents
    )
  }
`;

export const usePolicyUpdate = (): UsePolicyAmendResult => {
  const [policyUpdate, { data, loading, error }] = useMutation<any, PolicyUpdateVariables>(PolicyAmendMutation);

  return { data, loading, error, policyUpdate };
};
