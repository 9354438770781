"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsCard = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("..");
var Button_1 = require("../Button");
var design_system_1 = require("../../design-system");
var hooks_1 = require("../../hooks");
var icons_1 = require("../../icons");
var Card_styles_1 = require("./Card.styles");
var HeadingRibbon_1 = require("./HeadingRibbon");
exports.SettingsCard = (0, react_1.memo)(function (_a) {
    var heading = _a.heading, message = _a.message, _b = _a.variant, variant = _b === void 0 ? "default" : _b, icon = _a.icon, _c = _a.iconColor, iconColor = _c === void 0 ? "neutral.main" : _c, _d = _a.iconSize, iconSize = _d === void 0 ? "xl" : _d, price = _a.price, minPrice = _a.minPrice, maxPrice = _a.maxPrice, content = _a.content, _e = _a.stepPrice, stepPrice = _e === void 0 ? 1000 : _e, _f = _a.showToggleSwitch, showToggleSwitch = _f === void 0 ? false : _f, toggleSwitchLabel = _a.toggleSwitchLabel, props = __rest(_a, ["heading", "message", "variant", "icon", "iconColor", "iconSize", "price", "minPrice", "maxPrice", "content", "stepPrice", "showToggleSwitch", "toggleSwitchLabel"]);
    var breakpointState = (0, hooks_1.useBreakpointState)();
    var isMobile = breakpointState === "mobile";
    var _g = (0, react_1.useState)(false), showContent = _g[0], toggleShowContent = _g[1];
    var _h = (0, react_1.useState)(false), toggleState = _h[0], setToggleState = _h[1];
    var _j = (0, react_1.useState)(price), priceValue = _j[0], setPriceValue = _j[1];
    var variantColor = showToggleSwitch ? (toggleState ? "primary" : "disabled") : variant;
    var handleMinPrice = (0, react_1.useCallback)(function () {
        if (priceValue && minPrice && priceValue - stepPrice >= minPrice)
            setPriceValue(priceValue - stepPrice);
    }, [minPrice, priceValue, stepPrice]);
    var handleMaxPrice = (0, react_1.useCallback)(function () {
        if (priceValue && maxPrice && priceValue + stepPrice <= maxPrice)
            setPriceValue(priceValue + stepPrice);
    }, [maxPrice, priceValue, stepPrice]);
    return (react_1.default.createElement(Card_styles_1.SettingCardContainer, { "data-testid": props["data-testid"], filter: 1 },
        react_1.default.createElement(HeadingRibbon_1.HeadingRibbon, { variant: variantColor }),
        react_1.default.createElement(design_system_1.Box, { display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "center", alignItems: "center" },
            icon && react_1.default.createElement(icons_1.Icon, { name: icon, size: iconSize, color: iconColor }),
            react_1.default.createElement(Card_styles_1.ContentContainer, { isMobile: isMobile },
                react_1.default.createElement(react_1.default.Fragment, null,
                    heading && react_1.default.createElement(design_system_1.Header3, null, heading),
                    message && react_1.default.createElement("div", null,
                        message,
                        " "),
                    react_1.default.createElement(design_system_1.Text, { display: "flex", alignItems: "baseline", cursor: "pointer", color: "primary.main", onClick: function () { return toggleShowContent(!showContent); } },
                        "View Details ",
                        react_1.default.createElement(icons_1.Icon, { name: showContent ? "expandLess" : "expandMore", size: "xxs", ml: "xs" })))),
            showToggleSwitch ? (react_1.default.createElement(components_1.ToggleSwitch, { uncontrolled: true, label: toggleSwitchLabel, width: "12rem", justifyContent: "center", alignItems: "center", value: toggleState, onChange: function () { return setToggleState(!toggleState); } })) : (react_1.default.createElement(design_system_1.Box, { display: "flex", flexDirection: "column", width: isMobile ? "100%" : "12rem" },
                react_1.default.createElement(design_system_1.Box, { display: "flex", justifyContent: "space-around", alignItems: "center" },
                    react_1.default.createElement(Button_1.RoundButton, { size: isMobile ? "l" : "m", bType: "primary", iconName: "remove", onClick: handleMinPrice, disabled: minPrice && priceValue && priceValue - stepPrice < minPrice }),
                    price && react_1.default.createElement(design_system_1.Header3, { fontSize: isMobile ? "xl" : "lg" },
                        " $",
                        priceValue,
                        " "),
                    react_1.default.createElement(Button_1.RoundButton, { size: isMobile ? "l" : "m", bType: "primary", iconName: "plus", onClick: handleMaxPrice, disabled: priceValue && maxPrice && priceValue + stepPrice > maxPrice })),
                !isMobile && (react_1.default.createElement(design_system_1.Box, { display: "flex", justifyContent: "space-between" },
                    react_1.default.createElement(design_system_1.Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                        react_1.default.createElement(design_system_1.Text, null, "min"),
                        react_1.default.createElement(design_system_1.Text, null,
                            "$",
                            minPrice)),
                    react_1.default.createElement(design_system_1.Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                        react_1.default.createElement(design_system_1.Text, null, "max"),
                        react_1.default.createElement(design_system_1.Text, null,
                            "$",
                            maxPrice))))))),
        showContent && react_1.default.createElement(Card_styles_1.DetailContainer, null, content)));
});
