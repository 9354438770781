"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneNumber = void 0;
var react_1 = __importStar(require("react"));
var react_select_1 = require("react-select");
var const_1 = require("../../const");
var usePhoneFormat_1 = require("../../hooks/usePhoneFormat");
var countryCodes_1 = require("../../mock/countryCodes");
var utils_1 = require("../../utils");
var Flag_1 = require("./Component/Flag");
var PhoneNumber_styles_1 = require("./PhoneNumber.styles");
var Option = function (props) {
    return (react_1.default.createElement(react_select_1.components.Option, __assign({}, props),
        react_1.default.createElement(Flag_1.Flag, { name: props.data.name, code: props.data.code }),
        props.data.dial_code));
};
var PhoneNumber = function (_a) {
    var _b = _a.onChange, onChange = _b === void 0 ? function () { } : _b, _c = _a.onChangeCountryCode, onChangeCountryCode = _c === void 0 ? function () { } : _c, props = __rest(_a, ["onChange", "onChangeCountryCode"]);
    var _d = (0, usePhoneFormat_1.usePhoneFormat)(props.value), format = _d.format, country = _d.country, setCountry = _d.setCountry;
    var countryList = countryCodes_1.countries;
    var setCountryCode = (0, react_1.useCallback)(function (option) {
        setCountry(option === null || option === void 0 ? void 0 : option.code);
        onChangeCountryCode(option);
    }, [setCountry, onChangeCountryCode]);
    var countryListWithFlag = (0, react_1.useMemo)(function () {
        return countryList.map(function (count) {
            return __assign(__assign({}, count), { label: (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(Flag_1.Flag, { name: count.name, code: count.code }),
                    count.dial_code)), value: count.code });
        });
    }, [countryList]);
    var countryCode = (0, react_1.useMemo)(function () { return countryListWithFlag.filter(function (cnty) { return cnty.code === country; }); }, [countryListWithFlag, country]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var handleChange = (0, react_1.useCallback)((0, utils_1.debounce)(function (e) {
        if (!onChange)
            return;
        var value = e.target.value;
        onChange(__assign(__assign({}, e), { target: __assign(__assign({}, e.target), { value: value }), currentTarget: __assign(__assign({}, e.target), { value: value }) }));
    }, const_1.DEBOUNCE_TIMEOUT), [const_1.DEBOUNCE_TIMEOUT, onChange]);
    var onNumberChange = function (e) {
        handleChange(e);
    };
    var filterOption = function (_a, keyword) {
        var _b, _c, _d, _e;
        var label = _a.label, value = _a.value;
        if (keyword) {
            return ((_d = (_c = (_b = label.props) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c[1]) === null || _d === void 0 ? void 0 : _d.includes(keyword)) || ((_e = value.toString()) === null || _e === void 0 ? void 0 : _e.includes(keyword === null || keyword === void 0 ? void 0 : keyword.toUpperCase()));
        }
        else {
            return true;
        }
    };
    return (react_1.default.createElement(PhoneNumber_styles_1.PhoneNumberContainer, { "data-testid": props["data-testid"] },
        react_1.default.createElement(PhoneNumber_styles_1.FlagDropdown, { className: "test-sel", value: countryCode, isDisabled: props.disabled, components: { Option: Option }, onChange: setCountryCode, options: countryListWithFlag, styles: PhoneNumber_styles_1.CustomStyles, "data-testid": "flagDropdown-".concat(props["data-testid"]), filterOption: filterOption }),
        react_1.default.createElement(PhoneNumber_styles_1.PhoneInput, __assign({ format: format, onChange: onNumberChange, label: props.label || "Phone Number", isIconVisible: false }, props, { "data-testid": "input-".concat(props["data-testid"]) }))));
};
exports.PhoneNumber = PhoneNumber;
