import React from 'react';
import clsx from 'clsx';

import { useResponsive, ResponsiveBreakpoint } from './../../../hooks';

import './one-column-layout.scss';

export interface TwoColumnLayoutProps {
  readonly children?: React.ReactNode | React.ReactNode[];
}

export const OneColumnLayout = ({ children }: TwoColumnLayoutProps) => {
  const breakpoint = useResponsive();

  return (
    <div
      className={clsx({
        'one-column-layout': true,
        'one-column-layout--desktop': breakpoint === ResponsiveBreakpoint.Desktop,
        'one-column-layout--tablet': breakpoint === ResponsiveBreakpoint.Tablet,
        'one-column-layout--mobile': breakpoint === ResponsiveBreakpoint.Mobile,
      })}
    >
      <main className="one-column-layout__content">{children}</main>
    </div>
  );
};
