import { ApolloError, MutationFunction, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

interface EvaluateApplicationVariables {
  applicationId: number;
}

interface EvaluateApplicationResponse {
  evaluateApplication: {
    reportStatus?: string;
    approvalStatus?: string;
    error?: { message?: string };
  };
}

interface EvaluateApplicationResult {
  data?: EvaluateApplicationResponse['evaluateApplication'] | null;
  loading: boolean;
  error?: ApolloError;
  evaluateApplication: MutationFunction<any, EvaluateApplicationVariables>;
}

export const EvaluateApplicationMutation = gql`
  mutation EvaluateApplication($applicationId: Int!) {
    evaluateApplication(applicationId: $applicationId) {
      reportStatus
      approvalStatus
      error {
        message
      }
    }
  }
`;

export const useEvaluateApplication = (): EvaluateApplicationResult => {
  const [evaluateApplication, { data, loading, error }] = useMutation<
    EvaluateApplicationResponse,
    EvaluateApplicationVariables
  >(EvaluateApplicationMutation);

  return { data: data?.evaluateApplication, loading, error, evaluateApplication };
};
