import configuration from '../config/configuration';
import { useState } from 'react';

// Custom hook to handle authentication
export function useTgAuth(): {
  isTGAuthAuthenticated: boolean;
  setIsTGAuthAuthenticated: (value: boolean) => void;
  tgLogout: () => void;
} {
  const tgAccessToken = localStorage.getItem('tgAccessToken');
  const [isTGAuthAuthenticated, setIsTGAuthAuthenticated] = useState(Boolean(tgAccessToken));

  const tgLogout = () => {
    localStorage.removeItem('tgAccessToken');
    window.location.replace(`${configuration().tgAuthUrls.loginUrl}/logout`);
  };

  return { isTGAuthAuthenticated, setIsTGAuthAuthenticated, tgLogout };
}
