"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyConfirmationCard = void 0;
var react_1 = __importStar(require("react"));
var design_system_1 = require("../../design-system");
var hooks_1 = require("../../hooks");
var Card_styles_1 = require("./Card.styles");
var HeadingRibbon_1 = require("./HeadingRibbon");
exports.PolicyConfirmationCard = (0, react_1.memo)(function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? "default" : _b, message = _a.message, heading = _a.heading, amount = _a.amount, address = _a.address, policyDate = _a.policyDate, policies = _a.policies, otherPolicies = _a.otherPolicies, props = __rest(_a, ["variant", "message", "heading", "amount", "address", "policyDate", "policies", "otherPolicies"]);
    var breakpointState = (0, hooks_1.useBreakpointState)();
    var isMobile = breakpointState === "mobile";
    return (react_1.default.createElement(Card_styles_1.PolicyConfirmationCardContainer, { isMobile: isMobile, "data-testid": props["data-testid"], filter: 1 },
        react_1.default.createElement(HeadingRibbon_1.HeadingRibbon, { variant: variant }),
        react_1.default.createElement(design_system_1.Box, { textAlign: "left", mt: variant === "default" ? "clear" : "sm" },
            isMobile && react_1.default.createElement(design_system_1.Text, null, policyDate),
            heading && (react_1.default.createElement(design_system_1.Header2, { mb: isMobile ? "xs" : "lg", mt: isMobile ? "xs" : "initial" }, heading)),
            react_1.default.createElement(design_system_1.Box, { display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "space-between" },
                react_1.default.createElement(design_system_1.Text, null, " Renters insurance policy for"),
                react_1.default.createElement(design_system_1.Text, null, isMobile ? (react_1.default.createElement("b", null, address === null || address === void 0 ? void 0 :
                    address.addressLine1,
                    ", ", address === null || address === void 0 ? void 0 :
                    address.city,
                    ", ", address === null || address === void 0 ? void 0 :
                    address.state,
                    " ",
                    (address === null || address === void 0 ? void 0 : address.zipCode) + ".")) : (react_1.default.createElement("span", null, address === null || address === void 0 ? void 0 :
                    address.addressLine1,
                    ", ", address === null || address === void 0 ? void 0 :
                    address.city,
                    ", ", address === null || address === void 0 ? void 0 :
                    address.state,
                    " ", address === null || address === void 0 ? void 0 :
                    address.zipCode))),
                !isMobile && react_1.default.createElement(design_system_1.Text, null, policyDate)),
            react_1.default.createElement(Card_styles_1.AmountContainer, null,
                amount && react_1.default.createElement("h1", null, amount),
                react_1.default.createElement(design_system_1.MicroText, null, "/ Paid Annually")),
            react_1.default.createElement("div", null,
                react_1.default.createElement(design_system_1.Header3, { mt: "lg" }, "Coverage Details"),
                react_1.default.createElement(Card_styles_1.CoverageDetails, null,
                    react_1.default.createElement("ul", null,
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("p", null, "Personal Property"),
                            react_1.default.createElement("p", null, policies.personalProperty)),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("p", null, "Personal Liability"),
                            react_1.default.createElement("p", null, policies.personalLiability)),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("p", null, "Medical Liability"),
                            react_1.default.createElement("p", null, policies.medicalLiability)),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("p", null, "Loss of Use"),
                            react_1.default.createElement("p", null, policies.lossOfUse)),
                        react_1.default.createElement("li", null,
                            react_1.default.createElement("p", null, "Deductible"),
                            react_1.default.createElement("p", null, policies.deductible)), otherPolicies === null || otherPolicies === void 0 ? void 0 :
                        otherPolicies.map(function (policy) { return (react_1.default.createElement("li", null,
                            react_1.default.createElement("p", null, policy.policyName),
                            react_1.default.createElement("p", null, policy.amount))); })))))));
});
