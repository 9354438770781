"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.POLICY_STATUSES = void 0;
var POLICY_STATUSES;
(function (POLICY_STATUSES) {
    POLICY_STATUSES["CREATED"] = "created";
    POLICY_STATUSES["PROCESSING_PAYMENT"] = "processing-payment";
    POLICY_STATUSES["PAID"] = "paid";
    POLICY_STATUSES["ABORTED"] = "aborted";
    POLICY_STATUSES["ACTIVE"] = "active";
    POLICY_STATUSES["AMENDED"] = "amended";
    POLICY_STATUSES["CANCELED"] = "canceled";
    POLICY_STATUSES["ARCHIVED"] = "archived";
})(POLICY_STATUSES = exports.POLICY_STATUSES || (exports.POLICY_STATUSES = {}));
