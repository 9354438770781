"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Box = exports.theme = exports.for4kDisplay = exports.forQHDDisplay = exports.forDesktop = exports.forTablets = exports.forTabletsAndDesktop = exports.forMobilesAndTablets = exports.forMobiles = exports.transitions = exports.fontWeights = exports.breakpoints = exports.iconSizes = exports.fontSizes = exports.fontFamilies = exports.lineHeights = exports.spaces = exports.zIndeces = exports.filter = exports.elevation = exports.colors = void 0;
var Box_1 = require("./Box");
Object.defineProperty(exports, "Box", { enumerable: true, get: function () { return Box_1.Box; } });
var breakpoints_1 = require("./breakpoints");
Object.defineProperty(exports, "breakpoints", { enumerable: true, get: function () { return breakpoints_1.breakpoints; } });
Object.defineProperty(exports, "forDesktop", { enumerable: true, get: function () { return breakpoints_1.forDesktop; } });
Object.defineProperty(exports, "forMobiles", { enumerable: true, get: function () { return breakpoints_1.forMobiles; } });
Object.defineProperty(exports, "forMobilesAndTablets", { enumerable: true, get: function () { return breakpoints_1.forMobilesAndTablets; } });
Object.defineProperty(exports, "forTablets", { enumerable: true, get: function () { return breakpoints_1.forTablets; } });
Object.defineProperty(exports, "forTabletsAndDesktop", { enumerable: true, get: function () { return breakpoints_1.forTabletsAndDesktop; } });
Object.defineProperty(exports, "forQHDDisplay", { enumerable: true, get: function () { return breakpoints_1.forQHDDisplay; } });
Object.defineProperty(exports, "for4kDisplay", { enumerable: true, get: function () { return breakpoints_1.for4kDisplay; } });
var colors_1 = require("./colors");
Object.defineProperty(exports, "colors", { enumerable: true, get: function () { return colors_1.colors; } });
var elevation_1 = require("./elevation");
Object.defineProperty(exports, "elevation", { enumerable: true, get: function () { return elevation_1.elevation; } });
var filter_1 = require("./filter");
Object.defineProperty(exports, "filter", { enumerable: true, get: function () { return filter_1.filter; } });
var iconSizes_1 = require("./iconSizes");
Object.defineProperty(exports, "iconSizes", { enumerable: true, get: function () { return iconSizes_1.iconSizes; } });
var spaces_1 = require("./spaces");
Object.defineProperty(exports, "spaces", { enumerable: true, get: function () { return spaces_1.spaces; } });
var typography_1 = require("./typography");
Object.defineProperty(exports, "fontFamilies", { enumerable: true, get: function () { return typography_1.fontFamilies; } });
Object.defineProperty(exports, "fontSizes", { enumerable: true, get: function () { return typography_1.fontSizes; } });
Object.defineProperty(exports, "fontWeights", { enumerable: true, get: function () { return typography_1.fontWeights; } });
Object.defineProperty(exports, "lineHeights", { enumerable: true, get: function () { return typography_1.lineHeights; } });
Object.defineProperty(exports, "transitions", { enumerable: true, get: function () { return typography_1.transitions; } });
var zIndeces_1 = require("./zIndeces");
Object.defineProperty(exports, "zIndeces", { enumerable: true, get: function () { return zIndeces_1.zIndeces; } });
var theme = {
    elevation: elevation_1.elevation,
    filter: filter_1.filter,
    colors: colors_1.colors,
    space: spaces_1.spaces,
    fontSizes: typography_1.fontSizes,
    fonts: typography_1.fontFamilies,
    fontWeights: typography_1.fontWeights,
    lineHeights: typography_1.lineHeights,
    zIndeces: zIndeces_1.zIndeces,
    breakpoints: breakpoints_1.breakpoints,
    transitions: typography_1.transitions,
    iconSizes: iconSizes_1.iconSizes,
};
exports.theme = theme;
__exportStar(require("./typography"), exports);
