import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

// bootstrap datadog logger
// by default it forwards errors
// custom log events can be sent:
// datadogLogs.logger.info('testing dd browser logs', { name: 'test', id: 123 });
// https://docs.datadoghq.com/logs/log_collection/javascript/?tab=npm
if (process.env.REACT_APP_ENV && process.env.REACT_APP_DD_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    env: process.env.REACT_APP_ENV,
    service: 'tgportalservice-client',
    version: process.env.REACT_APP_VERSION,
    useSecureSessionCookie: true,
  });
}

// bootstrap datadog real user monitoring
// https://docs.datadoghq.com/real_user_monitoring/browser/
if (process.env.REACT_APP_ENV && process.env.REACT_APP_DD_RUM_APP_ID && process.env.REACT_APP_DD_CLIENT_TOKEN) {
  let allowedTracingOrigins = [];
  if (process.env.REACT_APP_BFF_URL) {
    // take the origin part of the URL (e.g. https://api.example.com)
    allowedTracingOrigins.push(new URL(process.env.REACT_APP_BFF_URL).origin);
  }

  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_RUM_APP_ID,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'tgportalservice-client',
    env: process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_VERSION,
    sampleRate: 100,
    // setting to false since this overlaps with fullstory
    // also we want to avoid having to exclude sensitive data in multiple places
    trackInteractions: false,
    // allowedTracingOrigins enables distributed tracing
    allowedTracingOrigins,
  });
}
