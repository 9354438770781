"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROLE_NAMES = void 0;
var ROLE_NAMES;
(function (ROLE_NAMES) {
    ROLE_NAMES["BORDEREAU"] = "bordereau";
    ROLE_NAMES["CLAIMS"] = "claims";
    ROLE_NAMES["LEASING"] = "leasing";
    ROLE_NAMES["RENEWAL"] = "renewal";
    ROLE_NAMES["FINANCING"] = "financing";
    ROLE_NAMES["OVERRIDE"] = "override";
    ROLE_NAMES["MANAGEMENT"] = "management";
    ROLE_NAMES["UNDERWRITER"] = "underwriter";
    ROLE_NAMES["SUPPORT"] = "support";
    ROLE_NAMES["OPERATIONS"] = "operations";
    ROLE_NAMES["ADMINISTRATOR"] = "administrator";
    ROLE_NAMES["TENANT"] = "tenant";
    ROLE_NAMES["ROOMMATE"] = "roommate";
    ROLE_NAMES["GUARANTOR"] = "guarantor";
    ROLE_NAMES["RESPONSIBLE_PARTY"] = "responsible-party";
    ROLE_NAMES["BROKER"] = "broker";
    ROLE_NAMES["RECOVERY"] = "recovery";
})(ROLE_NAMES = exports.ROLE_NAMES || (exports.ROLE_NAMES = {}));
