"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputComponent = void 0;
var react_1 = __importStar(require("react"));
var styles_1 = require("../styles");
var Input_iconSection_1 = require("./Input.iconSection");
var Input_styles_1 = require("./Input.styles");
var useInputBase_hook_1 = require("./useInputBase.hook");
var InputComponentC = function (props) {
    var _a = (0, useInputBase_hook_1.useInputBase)(props), isDefault = _a.isDefault, handleMouseOver = _a.handleMouseOver, handleMouseOut = _a.handleMouseOut, labelAndPlaceholderPresented = _a.labelAndPlaceholderPresented, placeholder = _a.placeholder, handleOnFocus = _a.handleOnFocus, handleOnBlur = _a.handleOnBlur, isSimple = _a.isSimple, active = _a.active, input = _a.input, cls = _a.cls, handleOnLabelClick = _a.handleOnLabelClick, label = _a.label, invalid = _a.invalid, icon = _a.icon, isIconVisible = _a.isIconVisible;
    var extraContainerProps = (0, react_1.useMemo)(function () { return (isDefault ? { onMouseOver: handleMouseOver, onMouseOut: handleMouseOut } : {}); }, [isDefault, handleMouseOver, handleMouseOut]);
    var extraInputProps = (0, react_1.useMemo)(function () {
        return isDefault
            ? {
                placeholder: labelAndPlaceholderPresented ? placeholder : undefined,
                onFocus: handleOnFocus,
                onBlur: handleOnBlur,
            }
            : {};
    }, [isDefault, labelAndPlaceholderPresented, placeholder, handleOnFocus, handleOnBlur]);
    return (react_1.default.createElement(Input_styles_1.Container, __assign({ isActive: isSimple || active, disabled: props.disabled, readonly: props.readOnly }, extraContainerProps),
        react_1.default.createElement(Input_styles_1.Input, __assign({}, props, { className: cls, id: props.name, ref: input }, extraInputProps)),
        isDefault && (react_1.default.createElement(styles_1.Label, { onClick: handleOnLabelClick, htmlFor: props.name },
            label || placeholder,
            " ",
            props.required ? react_1.default.createElement(styles_1.RequiredSymbol, null, "*") : null)),
        isIconVisible && (react_1.default.createElement(Input_iconSection_1.InputIconSection, { readOnly: props.readOnly && isDefault, disabled: props.disabled && isDefault, invalid: invalid, icon: icon, action: handleOnLabelClick }))));
};
exports.InputComponent = (0, react_1.memo)(InputComponentC);
