import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, makeVar } from '@apollo/client';
import { globalErrorLink } from '../../api/apollo/links/global-error-link';
import { createBrowserHistory } from 'history';
import { typeDefs } from '../type-defs';

export const history = createBrowserHistory();

export const sharedAnalyticsDetailsVar = makeVar<{ [key: string]: any }>(
  localStorage.getItem('sharedAnalyticsVar') ? JSON.parse(localStorage.getItem('sharedAnalyticsVar') ?? '') : {},
);

export const cache = new InMemoryCache();

export const createClient = (functionalLinks: ApolloLink[] = []) =>
  new ApolloClient({
    link: ApolloLink.from([...functionalLinks, globalErrorLink, new HttpLink({ uri: process.env.REACT_APP_BFF_URL })]),
    cache,
    connectToDevTools: process.env.NODE_ENV === 'development',
    typeDefs,
  });
