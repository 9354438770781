"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TablePagination = void 0;
var react_1 = __importStar(require("react"));
var icons_1 = require("../../icons");
var Table_styles_1 = require("./Table.styles");
function TablePagination(_a) {
    var gotoPage = _a.gotoPage, canPreviousPage = _a.canPreviousPage, nextPage = _a.nextPage, previousPage = _a.previousPage, canNextPage = _a.canNextPage, pageCount = _a.pageCount, pageIndex = _a.pageIndex, pageSize = _a.pageSize, itemsCount = _a.itemsCount, _b = _a.isShowCounter, isShowCounter = _b === void 0 ? true : _b;
    var noon = function () { };
    var currentPage = (0, react_1.useMemo)(function () { return pageIndex + 1; }, [pageIndex]);
    var canPreviousPlusOnePage = (0, react_1.useMemo)(function () { return currentPage > 2; }, [currentPage]);
    var canNextPlusOnePage = (0, react_1.useMemo)(function () { return currentPage + 1 < pageCount; }, [pageCount, currentPage]);
    var goToFirstPage = (0, react_1.useCallback)(function () { return (canPreviousPage ? gotoPage(0) : noon()); }, [canPreviousPage, gotoPage]);
    var goToPreviousPage = (0, react_1.useCallback)(function () { return (canPreviousPage ? previousPage() : noon()); }, [previousPage, canPreviousPage]);
    var goToPreviousPlusOnePage = (0, react_1.useCallback)(function () { return (canPreviousPlusOnePage ? gotoPage(currentPage - 3) : noon()); }, [canPreviousPlusOnePage, currentPage, gotoPage]);
    var goToNextPage = (0, react_1.useCallback)(function () { return (canNextPage ? nextPage() : noon()); }, [nextPage, canNextPage]);
    var goToNextPlusOnePage = (0, react_1.useCallback)(function () { return (canNextPlusOnePage ? gotoPage(currentPage + 1) : noon()); }, [canNextPlusOnePage, gotoPage, currentPage]);
    var goToLastPage = (0, react_1.useCallback)(function () { return (canNextPage ? gotoPage(pageCount - 1) : noon()); }, [canNextPage, gotoPage, pageCount]);
    var previousPlusOnePageBlock = (0, react_1.useMemo)(function () { return (currentPage <= 2 ? null : react_1.default.createElement(Table_styles_1.Page, { onClick: goToPreviousPlusOnePage }, currentPage - 2)); }, [goToPreviousPlusOnePage, currentPage]);
    var previousPageBlock = (0, react_1.useMemo)(function () { return (currentPage === 1 ? null : react_1.default.createElement(Table_styles_1.Page, { onClick: goToPreviousPage }, pageIndex)); }, [goToPreviousPage, pageIndex, currentPage]);
    var currentPageBlock = (0, react_1.useMemo)(function () { return react_1.default.createElement(Table_styles_1.Page, { active: true }, currentPage); }, [currentPage]);
    var nextPageBlock = (0, react_1.useMemo)(function () { return (currentPage === pageCount ? null : react_1.default.createElement(Table_styles_1.Page, { onClick: goToNextPage }, currentPage + 1)); }, [goToNextPage, currentPage, pageCount]);
    var nextPlusOnePageBlock = (0, react_1.useMemo)(function () { return (canNextPlusOnePage ? react_1.default.createElement(Table_styles_1.Page, { onClick: goToNextPlusOnePage }, currentPage + 2) : null); }, [canNextPlusOnePage, goToNextPlusOnePage, currentPage]);
    var countFrom = (0, react_1.useMemo)(function () { return (pageIndex === 0 ? 1 : pageSize * pageIndex + 1); }, [pageSize, pageIndex]);
    var countTo = (0, react_1.useMemo)(function () { return pageSize * currentPage; }, [currentPage, pageSize]);
    return (react_1.default.createElement(Table_styles_1.PaginationContainer, null,
        isShowCounter && (react_1.default.createElement(Table_styles_1.Counter, null,
            countFrom,
            "-",
            countTo > itemsCount ? itemsCount : countTo,
            " of ",
            itemsCount)),
        react_1.default.createElement(Table_styles_1.FirstPage, { onClick: goToFirstPage, disabled: !canPreviousPage },
            react_1.default.createElement(icons_1.Icon, __assign({ name: "arrowFirstPage" }, (0, Table_styles_1.iconProps)(!canPreviousPage)))),
        react_1.default.createElement(Table_styles_1.PreviousPage, { onClick: goToPreviousPage, disabled: !canPreviousPage },
            react_1.default.createElement(icons_1.Icon, __assign({ name: "arrowBackIos" }, (0, Table_styles_1.iconProps)(!canPreviousPage)))),
        react_1.default.createElement(Table_styles_1.Pages, null,
            previousPlusOnePageBlock,
            previousPageBlock,
            currentPageBlock,
            nextPageBlock,
            nextPlusOnePageBlock),
        react_1.default.createElement(Table_styles_1.NextPage, { onClick: goToNextPage, disabled: !canNextPage },
            react_1.default.createElement(icons_1.Icon, __assign({ name: "arrowForwardIos" }, (0, Table_styles_1.iconProps)(!canNextPage)))),
        react_1.default.createElement(Table_styles_1.LastPage, { onClick: goToLastPage, disabled: !canNextPage },
            react_1.default.createElement(icons_1.Icon, __assign({ name: "arrowLastPage" }, (0, Table_styles_1.iconProps)(!canNextPage))))));
}
exports.TablePagination = TablePagination;
