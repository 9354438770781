"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCREENING_STATUSES = exports.SDR_COVERAGE_TYPES = exports.PRODUCT = exports.REFERENCE_TYPES = exports.CARRIERS = void 0;
var CARRIERS;
(function (CARRIERS) {
    CARRIERS["ARCH"] = "arch";
    CARRIERS["HANOVER"] = "hanover";
    CARRIERS["EVERSPAN"] = "everspan";
    CARRIERS["TRISURA"] = "trisura";
    CARRIERS["EIIC"] = "eiic";
})(CARRIERS = exports.CARRIERS || (exports.CARRIERS = {}));
var REFERENCE_TYPES;
(function (REFERENCE_TYPES) {
    REFERENCE_TYPES["APPLICATION"] = "Application";
    REFERENCE_TYPES["POLICY"] = "Policy";
    REFERENCE_TYPES["LG_APPLICATION"] = "LGApplication";
})(REFERENCE_TYPES = exports.REFERENCE_TYPES || (exports.REFERENCE_TYPES = {}));
var PRODUCT;
(function (PRODUCT) {
    PRODUCT["DSDR"] = "DSDR";
    PRODUCT["DLG"] = "DLG";
    PRODUCT["LG"] = "LG";
    PRODUCT["SDR"] = "SDR";
})(PRODUCT = exports.PRODUCT || (exports.PRODUCT = {}));
var SDR_COVERAGE_TYPES;
(function (SDR_COVERAGE_TYPES) {
    SDR_COVERAGE_TYPES["MONTHS"] = "Months";
    SDR_COVERAGE_TYPES["MONTH"] = "Month";
    SDR_COVERAGE_TYPES["DOLLARS"] = "Dollars";
    SDR_COVERAGE_TYPES["DOLLAR"] = "Dollar";
})(SDR_COVERAGE_TYPES = exports.SDR_COVERAGE_TYPES || (exports.SDR_COVERAGE_TYPES = {}));
var SCREENING_STATUSES;
(function (SCREENING_STATUSES) {
    SCREENING_STATUSES["UNKNOWN"] = "Unknown";
    SCREENING_STATUSES["APPROVED"] = "Approved";
    SCREENING_STATUSES["CONDITIONAL"] = "Conditional";
    SCREENING_STATUSES["DENIED"] = "Denied";
})(SCREENING_STATUSES = exports.SCREENING_STATUSES || (exports.SCREENING_STATUSES = {}));
