import { ApolloError, MutationFunction, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

interface ApplicationCancelDealVariables {
  applicationId: number;
}

interface UsePolicyAmendResult {
  data: any | null | undefined;
  loading: boolean;
  error?: ApolloError;
  cancelDeal: MutationFunction<any, ApplicationCancelDealVariables>;
}

export const ApplicationCancelDealMutation = gql`
  mutation applicationCancelDeal($applicationId: Int!) {
    applicationCancelDeal(applicationId: $applicationId) {
      status
    }
  }
`;

export const useApplicationCancelDeal = (): UsePolicyAmendResult => {
  const [cancelDeal, { data, loading, error }] = useMutation<any, ApplicationCancelDealVariables>(
    ApplicationCancelDealMutation,
  );

  return { data, loading, error, cancelDeal };
};
