import gql from 'graphql-tag';

const PaymentTypeDef = gql`
  type PaymentEntity {
    id: Int!
    amountPaid: Float
    amountRefunded: Float
    status: String!
    paymentMethod: PaymentMethodEntity
    refunds: [RefundEntity!]!
    createdAt: String!
  }

  type RefundEntity {
    id: Int!
    amountInCents: Float!
    status: String!
    createdAt: String!
  }

  type PaymentMethodEntity {
    id: Int!
    isActive: Boolean!
    canReuse: Boolean!
    externalId: String
    paymentProcessorType: PaymentProcessorTypeEntity
  }

  type PaymentProcessorTypeEntity {
    id: Int!
    paymentMethodType: String
  }
`;

export default [PaymentTypeDef];
