"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectionCard = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("..");
var design_system_1 = require("../../design-system");
var hooks_1 = require("../../hooks");
var Card_styles_1 = require("./Card.styles");
var HeadingRibbon_1 = require("./HeadingRibbon");
exports.SelectionCard = (0, react_1.memo)(function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? "default" : _b, _isChecked = _a.isChecked, amount = _a.amount, message = _a.message, props = __rest(_a, ["variant", "isChecked", "amount", "message"]);
    var breakpointState = (0, hooks_1.useBreakpointState)();
    var isMobile = breakpointState === "mobile";
    var _c = (0, react_1.useState)(_isChecked), isChecked = _c[0], setIsChecked = _c[1];
    return (react_1.default.createElement(Card_styles_1.SelectionCardContainer, { isMobile: isMobile, "data-testid": props["data-testid"], filter: 1 },
        react_1.default.createElement(HeadingRibbon_1.HeadingRibbon, { variant: variant }),
        react_1.default.createElement(design_system_1.Box, { display: "flex", flexDirection: isMobile ? "row" : "column", justifyContent: "center", mt: isMobile ? "md" : "initial" },
            react_1.default.createElement(components_1.RadioButton, { name: "", checked: false, onChange: function () { return setIsChecked(!isChecked); } }),
            react_1.default.createElement(design_system_1.Box, { display: "flex", flexDirection: isMobile ? "row" : "column", mt: isMobile ? "initial" : "md" },
                react_1.default.createElement(design_system_1.Box, { display: "flex", flexDirection: "column", mr: isMobile ? "md" : "initial" },
                    react_1.default.createElement(design_system_1.MicroText, null, message),
                    react_1.default.createElement(design_system_1.Header3, null, isChecked ? "Selected" : "Unselected")),
                react_1.default.createElement(design_system_1.Header, { fontSize: "xxxl", color: "primary.main", mt: isMobile ? "initial" : "sm" }, amount)))));
});
