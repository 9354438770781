import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Icon } from '@theguarantors/ui-kit-v3/icons';
import { gql } from '@apollo/client';

export const generateESDocument = gql`
  query ESDocument($policyId: Int!, $documentType: String!, $fileName: String) {
    generateESDocument(policyId: $policyId, documentType: $documentType, fileName: $fileName)
  }
`;

enum DocumentTypes {
  'Bond Declaration' = 'BOND_DECLARATION_PAGE',
  'SL-1' = 'SL1',
  'SL-2' = 'SL2',
}

export interface GenerationESDocumentComponentProps {
  readonly policyId?: number;
  readonly title: string;
  readonly dataTestId: string;
  readonly fileName?: string;
}

export const GenerationESDocumentComponent: React.FC<GenerationESDocumentComponentProps> = ({
  policyId,
  title,
  dataTestId,
  fileName,
  ...props
}) => {
  const [generateDocument, { loading }] = useLazyQuery(generateESDocument, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.generateESDocument) {
        window.open(data.generateESDocument, '_blank');
      }
    },
  });

  return loading ? (
    <Icon size="xs" name="loading" color="primary.main" />
  ) : (
    <a
      data-testid={dataTestId}
      className="bond-declaration__link"
      onClick={() => {
        void generateDocument({
          variables: {
            policyId: Number(policyId),
            documentType: DocumentTypes[title as keyof typeof DocumentTypes],
            fileName,
          },
        });
      }}
      {...props}
    >
      {`Generate ${title}`}
    </a>
  );
};
