"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stepper = void 0;
var react_1 = __importStar(require("react"));
var Stepper_style_1 = require("./Stepper.style");
var Stepper = function (_a) {
    var steps = _a.steps, activeStep = _a.activeStep, onClickOnStep = _a.onClickOnStep, _b = _a.direction, direction = _b === void 0 ? "vertical" : _b, props = __rest(_a, ["steps", "activeStep", "onClickOnStep", "direction"]);
    var stepsArray = (0, react_1.useMemo)(function () { return Array.from(Array(steps).keys()); }, [steps]);
    var onIconClick = (0, react_1.useCallback)(function (index) { return function () { return onClickOnStep(index); }; }, [onClickOnStep]);
    return (react_1.default.createElement(Stepper_style_1.StepperWrapper, { "data-testid": props["data-testid"], direction: direction }, stepsArray.map(function (index) { return (react_1.default.createElement(Stepper_style_1.StyledIcon, { key: "step_".concat(index), onClick: onIconClick(index), name: "circleFill", height: "0.75rem", width: "0.75rem", color: index + 1 <= activeStep ? "primary.main" : "text.disabled" })); })));
};
exports.Stepper = Stepper;
