import { SummaryApplicationEntity } from '../../api/api-types';
import { leaseStartDateSort } from './deal-list.utils';

interface Object {
  [key: string]: any;
}
export interface ApplicationData extends Object {
  id: number;
  status: string;
  applicant: string;
  monthlyRent: number | string;
  leaseStartDate: string;
  buildingAndUnit: string;
  email: string;
}

export const useDealsData = (applications?: SummaryApplicationEntity[]): ApplicationData[] => {
  if (applications) {
    return [...applications]
      .sort((a1, a2) => leaseStartDateSort(a2.leaseStartDate, a1.leaseStartDate))
      .map((application) => {
        return {
          id: application.id,
          status: application.status,
          applicant: `${application.firstName} ${application.lastName}`,
          email: application.applicantEmail,
          monthlyRent: application.monthlyRent,
          leaseStartDate: application.leaseStartDate,
          buildingAndUnit: `${application?.displayName ?? ''} - ${application?.addressUnit ?? ''}`,
        };
      });
  }

  return [];
};
