import { ApplicationEntity, ContactEntity } from '../../api/api-types';

export enum CONTACT_TYPE {
  LEASING_AGENT = 'leasing-agent',
  APPLICANT = 'applicant',
}

export const ContactTypeTextToKey: { [key: string]: CONTACT_TYPE | undefined } = {
  Applicant: CONTACT_TYPE.APPLICANT,
  'Leasing Agent': CONTACT_TYPE.LEASING_AGENT,
};

export type NormalizedContactDetailsFields = 'contactType' | 'fullName' | 'email' | 'phoneNumber';

export type NormalizedContactDetailsResult = {
  [key in NormalizedContactDetailsFields]: string;
};

const normalizeLeasingAgentDetailData = (contact: ContactEntity | undefined) => {
  return contact
    ? {
        contactType: 'Leasing Agent',
        fullName: `${contact.firstName} ${contact.lastName}`,
        email: contact.email,
        phoneNumber: contact.phoneNumber,
      }
    : undefined;
};

const normalizeApplicantDetailData = (application: ApplicationEntity | undefined) => {
  return application
    ? {
        contactType: 'Applicant',
        fullName: `${application.firstName} ${application.lastName}`,
        email: application.applicantEmail,
        phoneNumber: application.phoneNumber,
      }
    : undefined;
};

const contactTypeToHandler = {
  [CONTACT_TYPE.LEASING_AGENT]: normalizeLeasingAgentDetailData,
  [CONTACT_TYPE.APPLICANT]: normalizeApplicantDetailData,
};

export const normalizeContactDetailData = <E>(
  contactType: CONTACT_TYPE,
  entity: E,
): NormalizedContactDetailsResult | undefined => {
  const handler = contactTypeToHandler[contactType];
  if (handler) {
    return handler(entity as any);
  }
};
