import { useAuth0 } from '@auth0/auth0-react';
import { setContext } from '@apollo/client/link/context';
import { createClient } from './api/apollo/client';
import { ApolloProvider } from '@apollo/client';
import React from 'react';
import useFeatureFlag from './hooks/use-feature-flag/use-feature-flag';
import { FeatureFlag } from './hooks/use-feature-flag/feature-flag';
import { Loading } from './components/loading';
import { onError } from '@apollo/client/link/error';
import configuration from './config/configuration';

export const authErrorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  const networkStatusCodeError = networkError && 'statusCode' in networkError && networkError?.statusCode === 401;

  const graphQLAuthError = Boolean(graphQLErrors?.some((error) => error.extensions?.code === 'UNAUTHENTICATED'));
  if (networkStatusCodeError || graphQLAuthError) {
    localStorage.removeItem('tgAccessToken');
    return window.location.replace(`${configuration().tgAuthUrls.loginUrl}/okta/msa`);
  }

  return forward(operation);
});

const Auth0 = ({ children }: { readonly children: React.ReactNode }) => {
  const { getAccessTokenSilently } = useAuth0();

  const authLink = setContext(async () => {
    const token = await getAccessTokenSilently();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const client = createClient([authLink]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

const TGAuth = ({ children }: { readonly children: React.ReactNode }) => {
  const authLink = setContext(() => {
    const token = localStorage.getItem('tgAccessToken');
    return {
      headers: {
        Authorization: `Bearer ${String(token)}`,
      },
    };
  });

  const client = createClient([authLink, authErrorLink]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const AuthorizedApolloProvider: React.FC = ({ children }) => {
  const [, { isReady: isFlagReady, isEnabled: isNewTgAuthMsa }] = useFeatureFlag(FeatureFlag.NEW_TG_AUTH_MSA);

  if (!isFlagReady) {
    return <Loading />;
  }

  if (isNewTgAuthMsa) {
    return <TGAuth>{children}</TGAuth>;
  }

  return <Auth0>{children}</Auth0>;
};
