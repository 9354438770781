"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONTACT_GROUPS = void 0;
var CONTACT_GROUPS;
(function (CONTACT_GROUPS) {
    CONTACT_GROUPS["LANDLORD"] = "landlord";
    CONTACT_GROUPS["RENTER"] = "renter";
    CONTACT_GROUPS["CARRIER"] = "carrier";
    CONTACT_GROUPS["BROKER"] = "broker";
    CONTACT_GROUPS["OPERATOR"] = "operator";
})(CONTACT_GROUPS = exports.CONTACT_GROUPS || (exports.CONTACT_GROUPS = {}));
