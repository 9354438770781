"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollbarProgress = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("..");
var const_1 = require("../../const");
var utils_1 = require("../../utils");
var ScrollbarProgress_styles_1 = require("./ScrollbarProgress.styles");
var scrollFunc = function (event, setActiveIndex) {
    var parent = event.target;
    var children = parent.children;
    Array.from(children).every(function (element, index) {
        if (parent.scrollTop < element.offsetTop) {
            setActiveIndex(index);
            return false;
        }
        return true;
    });
};
var ScrollbarProgress = function (_a) {
    var children = _a.children, maxHeight = _a.maxHeight, preventScrollToNextElements = _a.preventScrollToNextElements, props = __rest(_a, ["children", "maxHeight", "preventScrollToNextElements"]);
    var _b = (0, react_1.useState)(0), activeIndex = _b[0], setActiveIndex = _b[1];
    var stepperRefs = (0, react_1.useRef)([]);
    var scrollTo = (0, react_1.useCallback)(function (index) {
        var _a;
        if (preventScrollToNextElements && activeIndex < index)
            return;
        setActiveIndex(index);
        var target = (_a = stepperRefs === null || stepperRefs === void 0 ? void 0 : stepperRefs.current) === null || _a === void 0 ? void 0 : _a[index];
        if (target) {
            target.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [setActiveIndex, activeIndex, preventScrollToNextElements]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var scrolling = (0, react_1.useCallback)((0, utils_1.debounce)(function (event) { return scrollFunc(event, setActiveIndex); }, const_1.STEPPER_DEBOUNCE_TIMEOUT), []);
    var getCurrentRef = (0, react_1.useCallback)(function (blockIndex) { return function (element) { return (stepperRefs.current[blockIndex] = element); }; }, [stepperRefs]);
    return (react_1.default.createElement(ScrollbarProgress_styles_1.Wrapper, { "data-testid": props["data-testid"], maxHeight: maxHeight },
        react_1.default.createElement(components_1.Stepper, { steps: react_1.default.Children.count(children), activeStep: activeIndex + 1, onClickOnStep: scrollTo }),
        react_1.default.createElement(ScrollbarProgress_styles_1.ContentWrapper, { onScroll: scrolling }, react_1.default.Children.map(children, function (block, blockIndex) { return (react_1.default.createElement(ScrollbarProgress_styles_1.ContentBlock, { ref: getCurrentRef(blockIndex), "data-testid": "contentBlock-".concat(props["data-testid"]) }, block)); }))));
};
exports.ScrollbarProgress = ScrollbarProgress;
