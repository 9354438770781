import styled from 'styled-components';
import { Content, Title } from '@theguarantors/ui-kit-v3/components/Modal/styles/Modal.v1.styles';

export const CancelPolicyModalStyled = styled.div`
  ${Content} {
    margin-top: 0.5rem;
    overflow: visible;
  }
  ${Title} {
    margin-top: 1.5rem;
  }
`;
