"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAYMENT_EVENT_TYPES = exports.PAYMENT_METHOD_TYPES = exports.REFUNDED_STATUSES = exports.PAYMENT_STATUSES = void 0;
var PAYMENT_STATUSES;
(function (PAYMENT_STATUSES) {
    PAYMENT_STATUSES["CREATED"] = "Created";
    PAYMENT_STATUSES["PENDING"] = "Pending";
    PAYMENT_STATUSES["ACCEPTED"] = "Accepted";
    PAYMENT_STATUSES["COMPLETED"] = "Completed";
    PAYMENT_STATUSES["FAILED"] = "Failed";
    PAYMENT_STATUSES["CANCELLED"] = "Cancelled";
    PAYMENT_STATUSES["REFUND_PENDING"] = "RefundPending";
    PAYMENT_STATUSES["REFUND_ACCEPTED"] = "RefundAccepted";
    PAYMENT_STATUSES["PARTIALLY_REFUNDED"] = "PartiallyRefunded";
    PAYMENT_STATUSES["REFUNDED"] = "Refunded";
})(PAYMENT_STATUSES = exports.PAYMENT_STATUSES || (exports.PAYMENT_STATUSES = {}));
var REFUNDED_STATUSES;
(function (REFUNDED_STATUSES) {
    REFUNDED_STATUSES["PENDING"] = "Pending";
    REFUNDED_STATUSES["ACCEPTED"] = "Accepted";
    REFUNDED_STATUSES["REFUNDED"] = "Refunded";
    REFUNDED_STATUSES["CANCELED"] = "Canceled";
    REFUNDED_STATUSES["FAILED"] = "Failed";
})(REFUNDED_STATUSES = exports.REFUNDED_STATUSES || (exports.REFUNDED_STATUSES = {}));
var PAYMENT_METHOD_TYPES;
(function (PAYMENT_METHOD_TYPES) {
    PAYMENT_METHOD_TYPES["BANK_ACCOUNT"] = "BankAccount";
    PAYMENT_METHOD_TYPES["CREDIT_CARD"] = "CreditCard";
})(PAYMENT_METHOD_TYPES = exports.PAYMENT_METHOD_TYPES || (exports.PAYMENT_METHOD_TYPES = {}));
var PAYMENT_EVENT_TYPES;
(function (PAYMENT_EVENT_TYPES) {
    PAYMENT_EVENT_TYPES["CREATED_WITH_NEW_CARD"] = "CreatedWithNewCard";
    PAYMENT_EVENT_TYPES["CREATED_WITH_PAYMENT_METHOD"] = "CreatedWithPaymentMethod";
    PAYMENT_EVENT_TYPES["EXECUTED_PAYMENT"] = "ExecutedPayment";
    PAYMENT_EVENT_TYPES["RETRIEVED_PAYMENT_STATUS"] = "RetrievedPaymentStatus";
    PAYMENT_EVENT_TYPES["RECEIVED_PAYMENT_STATUS"] = "ReceivedPaymentStatus";
    PAYMENT_EVENT_TYPES["RECEIVED_PAYMENT_PENDING"] = "ReceivedPaymentPending";
    PAYMENT_EVENT_TYPES["RECEIVED_PAYMENT_ACCEPTED"] = "ReceivedPaymentAccepted";
    PAYMENT_EVENT_TYPES["RECEIVED_PAYMENT_COMPLETE"] = "ReceivedPaymentComplete";
    PAYMENT_EVENT_TYPES["RECEIVED_PAYMENT_FAILED"] = "ReceivedPaymentFailed";
    PAYMENT_EVENT_TYPES["RECEIVED_PAYMENT_CANCELLED"] = "ReceivedPaymentCancelled";
    PAYMENT_EVENT_TYPES["CREATED_REFUND"] = "CreatedRefund";
    PAYMENT_EVENT_TYPES["RECEIVED_REFUND_PENDING"] = "ReceivedRefundPending";
    PAYMENT_EVENT_TYPES["RECEIVED_REFUND_ACCEPTED"] = "ReceivedRefundAccepted";
    PAYMENT_EVENT_TYPES["RECEIVED_REFUND_COMPLETE"] = "ReceivedRefundComplete";
    PAYMENT_EVENT_TYPES["RECEIVED_REFUND_FAILED"] = "ReceivedRefundFailed";
    PAYMENT_EVENT_TYPES["RECEIVED_REFUND_CANCELLED"] = "ReceivedRefundCancelled";
    PAYMENT_EVENT_TYPES["PAYMENT_FULLY_REFUNDED"] = "PaymentFullyRefunded";
})(PAYMENT_EVENT_TYPES = exports.PAYMENT_EVENT_TYPES || (exports.PAYMENT_EVENT_TYPES = {}));
