"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLoadGoogleScript = void 0;
var hooks_1 = require("./");
var useLoadGoogleScript = function (apiKey) {
    // load google maps library asynchronously
    // React hooks can't be called conditionally, so if we don't have a key
    // we replace it with a dummy value and let the script request fail (& log a warning)
    if (apiKey === void 0) { apiKey = "BAD_VALUE"; }
    return (0, hooks_1.useScript)("https://maps.googleapis.com/maps/api/js?key=".concat(apiKey, "&libraries=places"));
};
exports.useLoadGoogleScript = useLoadGoogleScript;
