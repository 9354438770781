import { LazyQueryResult, OperationVariables, QueryLazyOptions, gql, useLazyQuery } from '@apollo/client';
import { SummaryApplicationEntity } from '../api/api-types';

interface Result {
  applications: SummaryApplicationEntity[] | undefined;
  loading: boolean;
  called: boolean;
  fetchApplications: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => Promise<LazyQueryResult<ApplicationResponse, OperationVariables>>;
}

interface ApplicationResponse {
  summaryApplications: SummaryApplicationEntity[];
}

export const Applications = gql`
  query Applications {
    summaryApplications {
      id
      firstName
      lastName
      status
      applicantEmail
      monthlyRent
      leaseStartDate
      addressUnit
      displayName
    }
  }
`;

export function useApplications(): Result {
  const [fetchApplications, { data, loading, called }] = useLazyQuery<ApplicationResponse>(Applications);
  return {
    applications: data?.summaryApplications,
    loading,
    called,
    fetchApplications,
  };
}
