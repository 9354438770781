import React from 'react';
import { FileLinkComponentProps } from '../../details.types';
import { getFileLInkById } from './file-link.gql';
import { useLazyQuery } from '@apollo/client';
import { Icon } from '@theguarantors/ui-kit-v3/icons';

export const FileLinkComponent: React.FC<FileLinkComponentProps> = ({
  title,
  documentId,
  fileId,
  fileName,
  dataTestId,
  ...props
}) => {
  const [getUrl, { loading }] = useLazyQuery(getFileLInkById, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.documentUrl) {
        window.open(data.documentUrl, '_blank');
      }
    },
  });

  return loading ? (
    <Icon size="xs" name="loading" color="primary.main" />
  ) : (
    <a
      data-testid={dataTestId}
      className="external-link"
      onClick={() => {
        if (!documentId || !fileId) {
          return;
        }

        void getUrl({
          variables: {
            documentId: Number(documentId),
            fileId: Number(fileId),
            fileName,
          },
        });
      }}
      {...props}
    >
      {title}
    </a>
  );
};
