"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScriptStatus = void 0;
var ScriptStatus;
(function (ScriptStatus) {
    ScriptStatus["LOADING"] = "loading";
    ScriptStatus["IDLE"] = "idle";
    ScriptStatus["READY"] = "ready";
    ScriptStatus["ERROR"] = "error";
})(ScriptStatus = exports.ScriptStatus || (exports.ScriptStatus = {}));
