import React, { useState, useEffect, useCallback } from 'react';
import { Box, TextInput } from '@theguarantors/ui-kit-v3';
import { Icon } from '@theguarantors/ui-kit-v3/icons';
import { withMessage } from '@theguarantors/ui-kit-v3/decorators/withMessage';
import { EditableFieldProps } from './editable-field.types';
import { editableFieldTypeIcons } from './editable-field.constants';
import { ReadableAnswers } from '../../pages/details/details.constants';
import './editable-field.scss';

export const EditableField: React.FC<EditableFieldProps> = ({
  value,
  customEditComponent,
  editing = false,
  bold = false,
  error = null,
  resetStates = false,
  icon,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(editing);
  const [hovering, setHovering] = useState<boolean>(false);

  const toggleHovering = useCallback(() => setHovering(!hovering), [hovering]);

  useEffect(() => {
    if (resetStates) {
      setIsEditing(false);
      setHovering(false);
    }
  }, [resetStates]);

  const inputOrText =
    value || isEditing ? (
      <TextInput required invalid={Boolean(error)} value={value} {...props} />
    ) : (
      ReadableAnswers.NULL
    );
  const valueString = value ?? ReadableAnswers.NULL;

  return isEditing || editing ? (
    <Box data-testid={props['editMode-testid']} mt="sm" mb="sm">
      {withMessage(customEditComponent ?? inputOrText, {
        type: error ? 'error' : 'message',
        message: error,
        name: 'message',
      })}
    </Box>
  ) : (
    <Box
      data-testid={props['data-testid']}
      display="flex"
      alignItems="center"
      className="editable-field"
      height="1rem"
      flexGrow="1"
      onClick={() => setIsEditing(true)}
      onMouseEnter={toggleHovering}
      onMouseLeave={toggleHovering}
    >
      {bold ? <b>{valueString}</b> : valueString}
      {icon && !hovering && (
        <Icon name={editableFieldTypeIcons[icon]} color={`${icon}.main`} width="1rem" height="1rem" ml="xs" />
      )}
      {hovering && <Icon name="edit" color="neutral.main" width="1.5rem" height="1rem" ml="xs" />}
    </Box>
  );
};
