"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ORGANIZATION_LEVELS = exports.ORGANIZATION_TYPES = void 0;
var ORGANIZATION_TYPES;
(function (ORGANIZATION_TYPES) {
    ORGANIZATION_TYPES["LANDLORD"] = "landlord";
    ORGANIZATION_TYPES["BROKER"] = "broker";
})(ORGANIZATION_TYPES = exports.ORGANIZATION_TYPES || (exports.ORGANIZATION_TYPES = {}));
var ORGANIZATION_LEVELS;
(function (ORGANIZATION_LEVELS) {
    ORGANIZATION_LEVELS["COMPANY"] = "company";
    ORGANIZATION_LEVELS["LEASING"] = "leasing";
    ORGANIZATION_LEVELS["REGIONAL"] = "regional";
    ORGANIZATION_LEVELS["OFFICE"] = "office";
})(ORGANIZATION_LEVELS = exports.ORGANIZATION_LEVELS || (exports.ORGANIZATION_LEVELS = {}));
