"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
var neutralMain = "#161657";
var secondary = "#1FDDB3";
var disabled = "#A6A6BF";
var outlineBorder = "#D0D0DD";
var tableSelected = "#F0EFFD";
exports.colors = {
    text: {
        header: neutralMain,
        highlightlight: secondary,
        body: "#0F0F3C",
        emphasisLow: "#73739A",
        disabled: disabled,
    },
    decorative: {
        green: secondary,
        yellow: "#F3BE55",
        darkBlue: neutralMain,
        blue: "#0010b8",
        lightGreen: "#E9F8F8",
        lightYellow: "#FFFAF0",
        lightRed: "#FAEEEE",
        gray: "#c4c4c4",
        darkPurple: "#4C4AA8",
    },
    social: {
        google: "#4285F4",
        googleHover: "#4285F4",
        googleFocus: "#3367D6",
        facebook: "#1877F2",
        facebookHover: "#365899",
        facebookFocus: "#29487D",
    },
    neutral: {
        main: neutralMain,
        light: "#A1A1BB",
        dark: "#3F3F63",
        hoverBg: "#0F0F3D",
        bg: "#ECECF2",
    },
    primary: {
        main: "#3F3CEC",
        light: "#9F9DF5",
        dark: "#6563F0",
        hoverBg: "#2C2AA5",
        bg: tableSelected,
    },
    primaryV2: {
        main: "#080A2D",
    },
    error: {
        main: "#F52314",
        light: "#FA9189",
        dark: "#F74F43",
        hoverBg: "#AC190E",
        content: "#890E06",
        bg: "#FEEDEC",
    },
    info: {
        main: "#9190F4",
        light: "#C8C7F9",
        dark: "#A7A6F6",
        hoverBg: "#6D6AF0",
        content: "#1512BA",
        bg: "#F6F6FE",
    },
    warning: {
        main: "#F08D32",
        light: "#F8C699",
        dark: "#F3A45B",
        hoverBg: "#A86323",
        content: "#AB5904",
        bg: "#FEF6EF",
    },
    success: {
        main: "#19B391",
        light: "#8CD9C8",
        dark: "#47C2A7",
        hoverBg: "#127D66",
        content: "#0A473A",
        bg: "#EDF9F6",
    },
    table: {
        active: "#4D4D6A",
        hover: tableSelected,
        selected: tableSelected,
        disabledBackground: "#EDEDF2",
        focus: neutralMain,
    },
    other: {
        divider: "#EDEDF2",
        outlineBorder: outlineBorder,
        outlineBorderHover: disabled,
        outlineBorderActive: neutralMain,
        backdropOverlay: neutralMain,
        white: "#FFFFFF",
        yellow: "#fcbd39",
        iconInactive: "rgba(0, 0, 0, 0.54)",
        activeDropdown: "#DADAFB",
    },
    logo: {
        lightGreen: "#2AF598",
        green: secondary,
        teal: "#13C6CF",
        blue: "#08AEEA",
    },
};
