"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyConfirmationCardV2 = void 0;
var react_1 = __importStar(require("react"));
var design_system_1 = require("../../design-system");
var hooks_1 = require("../../hooks");
var Divider_1 = require("../Divider");
var Card_styles_1 = require("./Card.styles");
var HeadingRibbon_1 = require("./HeadingRibbon");
exports.PolicyConfirmationCardV2 = (0, react_1.memo)(function (_a) {
    var _b;
    var _c = _a.variant, variant = _c === void 0 ? "primary" : _c, message = _a.message, heading = _a.heading, amount = _a.amount, address = _a.address, policyDate = _a.policyDate, buildingName = _a.buildingName, fullName = _a.fullName, monthlyDetails = _a.monthlyDetails, props = __rest(_a, ["variant", "message", "heading", "amount", "address", "policyDate", "buildingName", "fullName", "monthlyDetails"]);
    var breakpointState = (0, hooks_1.useBreakpointState)();
    var isMobile = breakpointState === "mobile";
    var monthlyDetailsUrl = (_b = monthlyDetails === null || monthlyDetails === void 0 ? void 0 : monthlyDetails.url) !== null && _b !== void 0 ? _b : "theguarantors.chargebeeportal.com";
    var formattedPolicyDate = new Date(policyDate).toLocaleDateString("en-us", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
    return (react_1.default.createElement(Card_styles_1.PolicyConfirmationCardContainerV2, { isMobile: isMobile, "data-testid": props["data-testid"], filter: 1 },
        react_1.default.createElement(HeadingRibbon_1.HeadingRibbon, { variant: variant }),
        react_1.default.createElement(design_system_1.Box, null,
            react_1.default.createElement(design_system_1.Text, { fontWeight: "bold" }, formattedPolicyDate)),
        react_1.default.createElement(design_system_1.Box, { textAlign: "left", mt: isMobile ? "sm" : "md" },
            heading && (react_1.default.createElement(Card_styles_1.CardHeader2, { mb: isMobile ? "xs" : "sm", variant: variant }, heading)),
            react_1.default.createElement(design_system_1.Box, null,
                react_1.default.createElement("b", null, buildingName),
                react_1.default.createElement(design_system_1.Text, null, "\u00A0at\u00A0"),
                react_1.default.createElement("b", null, address === null || address === void 0 ? void 0 :
                    address.addressLine1,
                    ", ", address === null || address === void 0 ? void 0 :
                    address.city,
                    ", ", address === null || address === void 0 ? void 0 :
                    address.state,
                    " ", address === null || address === void 0 ? void 0 :
                    address.zipCode,
                    ", ", address === null || address === void 0 ? void 0 :
                    address.country,
                    ",",
                    " ",
                    (address === null || address === void 0 ? void 0 : address.unit) && react_1.default.createElement(react_1.default.Fragment, null,
                        "Unit ", address === null || address === void 0 ? void 0 :
                        address.unit,
                        "."))),
            react_1.default.createElement(Card_styles_1.PaymentDetailsContainer, { isMobile: isMobile },
                react_1.default.createElement(Card_styles_1.AmountContainerV2, { isMobile: isMobile },
                    amount && (react_1.default.createElement(Card_styles_1.AmountLabel, { isMobile: isMobile, variant: variant },
                        "$",
                        monthlyDetails ? monthlyDetails.amount : amount)),
                    monthlyDetails && react_1.default.createElement(design_system_1.MicroText, null, "/ Paid Monthly")),
                monthlyDetails && (react_1.default.createElement(design_system_1.Box, null,
                    react_1.default.createElement(design_system_1.Text, null,
                        "$", monthlyDetails === null || monthlyDetails === void 0 ? void 0 :
                        monthlyDetails.lastPaymentAmount),
                    react_1.default.createElement(design_system_1.MicroText, null,
                        "\u00A0Paid on ", monthlyDetails === null || monthlyDetails === void 0 ? void 0 :
                        monthlyDetails.lastPaymentDate)))),
            react_1.default.createElement(Divider_1.Divider, { direction: "horizontal" }),
            react_1.default.createElement(Card_styles_1.DetailsContainer, { isMobile: isMobile },
                react_1.default.createElement(Card_styles_1.GaiContainer, null,
                    react_1.default.createElement(design_system_1.Text, null, "GAI Signed as"),
                    react_1.default.createElement(design_system_1.Text, { fontWeight: "bold" }, fullName)),
                monthlyDetails && (react_1.default.createElement(Card_styles_1.GaiContainer, null,
                    react_1.default.createElement(design_system_1.Text, null, "View your payment plan here"),
                    react_1.default.createElement("a", { href: "https://".concat(monthlyDetailsUrl), target: "_blank", rel: "noreferrer" },
                        react_1.default.createElement(design_system_1.Text, { fontWeight: "bold" }, monthlyDetailsUrl))))))));
});
