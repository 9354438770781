import React from 'react';
import { Box } from '@theguarantors/ui-kit-v3';
import { Icon } from '@theguarantors/ui-kit-v3/icons';
import { StatusInfoProps } from './StatusInfo.types';
import { statusInfoTypeIcons, STATUS_INFO_EXPIRATION_DELAY } from './StatusInfo.constants';
import { useExpiration } from '../../hooks/use-expiration';

export const StatusInfo: React.FC<StatusInfoProps> = ({
  type = 'success',
  delay = STATUS_INFO_EXPIRATION_DELAY,
  children,
  ...props
}) => {
  const shouldRender = useExpiration(delay);

  if (!shouldRender) {
    return null;
  }

  const backgroundColor = () => {
    switch (type) {
      case 'success':
        return 'lightGreen';
      case 'warning':
        return 'lightYellow';
      case 'error':
        return 'lightRed';
      default:
        return 'lightGreen';
    }
  };

  const borderStyle = `1px solid var(--color-${type}-main)`;
  return (
    <Box
      display="flex"
      alignItems="center"
      padding="sm"
      backgroundColor={`var(--color-decorative-${backgroundColor()})`}
      borderRadius="5px"
      border={borderStyle}
      {...props}
    >
      <Icon mr="md" name={statusInfoTypeIcons[type]} color={`var(--color-${type}-main)`} />
      {children}
    </Box>
  );
};
