/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons';
import clsx from 'clsx';
import './headerV2.scss';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from '../../../../assets/img/v3/logo-full.svg';

import { HeaderProps, MenuProps } from '../../Shell.types';
import { Box } from '@theguarantors/ui-kit-v3';
import { FeatureFlag, useFeatureFlag } from '../../../../hooks/use-feature-flag';
import { useIsUserHasPermission } from '../../../../hooks/use-is-user-has-permission';
import { BaseRoutes } from '../../../router/router.constants';
import { useTgAuth } from '../../../../hooks/use-tg-auth';

export const MobileMenu: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const { isTGAuthAuthenticated } = useTgAuth();
  const [isOpen, setIsOpen] = useState(false);

  const openMenu = () => setIsOpen(true);
  const closeMenu = () => setIsOpen(false);

  if (!isOpen) {
    return isAuthenticated || isTGAuthAuthenticated ? <MenuIcon onClick={openMenu} /> : null;
  }

  return (
    <div className="mobileMenu--containerV2">
      <Menu isMobile closeMobileMenu={closeMenu} />
    </div>
  );
};

export const Menu: React.FC<MenuProps> = ({ isMobile, closeMobileMenu }) => {
  const { isAuthenticated, logout } = useAuth0();
  const { isTGAuthAuthenticated, tgLogout } = useTgAuth();
  const [propertiesFlag] = useFeatureFlag(FeatureFlag.PROPERTIES_FLAG);
  const isPropertiesFlagEnabled = propertiesFlag === 'on';
  const showProperties = useIsUserHasPermission('properties');

  const logUserOut = () => {
    if (isTGAuthAuthenticated) {
      return tgLogout();
    }

    return logout({ returnTo: window.location.origin + BaseRoutes.LOGOUT });
  };

  return (
    <nav className="shell__headerV2__nav">
      {isMobile && (
        <Box className="shell__headerV2__topMobileHeader">
          <img src={Logo} alt="TheGuarantors Logo" className="shell__header__logo--mobile" />
          <a href="#" className="shell__headerV2__close" onClick={closeMobileMenu}>
            <CloseIcon fontSize="small" />
          </a>
        </Box>
      )}
      {(isAuthenticated || isTGAuthAuthenticated) && (
        <>
          {isPropertiesFlagEnabled && showProperties && (
            <a className="shell__headerV2__link" href="/properties">
              Properties
            </a>
          )}
          <a className="shell__headerV2__link" href="/deals">
            Deals
          </a>
          <a href="#" className="shell__headerV2__link" onClick={logUserOut}>
            Logout
          </a>
        </>
      )}
    </nav>
  );
};

export const HeaderV2: React.FC<HeaderProps> = ({ isDesktop }) => {
  const MenuComponent = isDesktop ? Menu : MobileMenu;

  return (
    <header
      className={clsx({
        shell__headerV2: true,
        shell__headerV2__mobile: !isDesktop,
      })}
    >
      <Box display="flex" margin={!isDesktop && 'auto'}>
        <img
          src={Logo}
          alt="TheGuarantors Logo"
          className={clsx({
            'shell__headerV2__logo--desktop': isDesktop,
            'shell__headerV2__logo--mobile': !isDesktop,
          })}
        />
      </Box>
      <MenuComponent />
    </header>
  );
};
