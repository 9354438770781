import React, { FC, useCallback } from 'react';
import { InfoContainer, TextAreaInput } from '@theguarantors/ui-kit-v3';
import { CommentsSectionProps, DetailsChangesKey } from '../../details.types';
import { cloneDeep } from '../../../../utils';
import { HelperText } from '@theguarantors/ui-kit-v3/design-system';

export const CommentsSection: FC<CommentsSectionProps> = ({ changes: c = {}, setChanges, commentData, ...rest }) => {
  const changes = cloneDeep(c);

  const handleCommentChange = useCallback(
    ({ target: { value } }) => {
      // "commentData" can be "str", "" or null, thus we need to handle appropriately type casts
      const shouldChangeComment = commentData ? value !== commentData : Boolean(value) !== Boolean(commentData);
      if (shouldChangeComment) {
        setChanges(DetailsChangesKey.APPLICATION_SETTINGS, { comment: { value, error: undefined } });
      } else {
        delete changes.comment;
        setChanges(DetailsChangesKey.APPLICATION_SETTINGS, changes);
      }
    },
    [setChanges, changes, commentData],
  );

  return (
    <InfoContainer color="neutral.main" title="Comments" {...rest}>
      <TextAreaInput
        className="comments__field fs-exclude"
        controlled={false}
        value={changes?.comment?.value ?? commentData ?? ''}
        label="Comments"
        placeholder="Comments"
        onChange={handleCommentChange}
      />
      <HelperText color="text.emphasisLow" ml="sm">
        Add here any comment related to this deal and save the changes.
      </HelperText>
    </InfoContainer>
  );
};
