"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataTestIdWithPostfix = exports.noonEmptyObj = exports.noon = exports.debounce = exports.classnames = void 0;
var classnames_1 = require("./classnames");
Object.defineProperty(exports, "classnames", { enumerable: true, get: function () { return classnames_1.classnames; } });
var debounce_1 = require("./debounce");
Object.defineProperty(exports, "debounce", { enumerable: true, get: function () { return debounce_1.debounce; } });
var noon = function () { };
exports.noon = noon;
var noonEmptyObj = function () { return ({}); };
exports.noonEmptyObj = noonEmptyObj;
var dataTestIdWithPostfix = function (testid, postfix) {
    return testid ? "".concat(testid).concat(postfix ? "-".concat(postfix) : "") : "";
};
exports.dataTestIdWithPostfix = dataTestIdWithPostfix;
