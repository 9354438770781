import React, { useCallback, useMemo, useState } from 'react';
import { Button, Box, InfoModal } from '@theguarantors/ui-kit-v3';
import { Icon } from '@theguarantors/ui-kit-v3/icons';
import { usePolicyUpdate } from '../../hooks/use-policy-update';
import { PolicyInfoModalProps } from '../../details.types';

export const PolicyInfoModal: React.FC<PolicyInfoModalProps> = ({
  isOpen,
  title,
  description,
  refetch,
  applicationId,
  setIsOpen,
  actionType,
  id,
}) => {
  const [isSkipPaymentsButtonClick, setIsSkipPaymentsButtonClick] = useState(false);
  const [isApplyPaymentsButtonClick, setIsApplyPaymentsButtonClick] = useState(false);
  const { policyUpdate, loading } = usePolicyUpdate();
  const loadingApplyButton: boolean = useMemo(() => {
    return loading && isApplyPaymentsButtonClick;
  }, [loading, isApplyPaymentsButtonClick]);
  const loadingSkipButton: boolean = useMemo(() => {
    return loading && isSkipPaymentsButtonClick;
  }, [loading, isSkipPaymentsButtonClick]);

  const onClickPolicyUpdateBtn = useCallback(
    async (isSkipPayment?: boolean): Promise<void> => {
      if (isSkipPayment) {
        setIsSkipPaymentsButtonClick(true);
        setIsApplyPaymentsButtonClick(false);
      } else {
        setIsSkipPaymentsButtonClick(false);
        setIsApplyPaymentsButtonClick(true);
      }

      await policyUpdate({
        variables: {
          id,
          applicationId,
          isSkipPayment,
          actionType,
        },
      });
      setIsOpen(false);
      refetch();
    },
    [id, applicationId, actionType, policyUpdate, setIsOpen, refetch],
  );

  return (
    <InfoModal
      isOpen={isOpen}
      title={title}
      iconKey="assignment"
      description={
        <Box textAlign="center" style={{ 'text-transform': 'initial' }}>
          {description}
        </Box>
      }
      height="27rem"
      width="35rem"
      data-testid="policy_info_modal"
      actionSection={
        <Box display="flex" justifyContent="space-between" width="100%" mt={15}>
          <Box>
            <Button bType="primary" bVariant="outlined" disabled={loading} onClick={async () => setIsOpen(false)}>
              Go Back
            </Button>
          </Box>
          <Box display="flex" style={{ gridGap: '1rem' }}>
            <Button bType="primary" disabled={loading} onClick={async () => onClickPolicyUpdateBtn(false)}>
              {loadingApplyButton ? <Icon name="loading" /> : 'No, Apply Payments'}
            </Button>
            <Button bType="primary" disabled={loading} onClick={async () => onClickPolicyUpdateBtn(true)}>
              {loadingSkipButton ? <Icon name="loading" /> : 'Yes, Skip'}
            </Button>
          </Box>
        </Box>
      }
      onClose={() => setIsOpen(false)}
    />
  );
};
