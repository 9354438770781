import { useState, useLayoutEffect } from 'react';
import { fakeData } from './use-properties-fake-data';

export interface PropertiesData {
  id: number;
  name: string;
  leasingOffice: string;
  company: string;
}

interface Result {
  properties: PropertiesData[];
  loading: boolean;
}

let timeoutId: NodeJS.Timeout;

export function useProperties(): Result {
  const [loading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    timeoutId = setTimeout(() => setIsLoading(false), 1500);
    return () => clearTimeout(timeoutId);
  }, []);

  return { properties: fakeData, loading };
}
