"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Slider = void 0;
var react_1 = __importStar(require("react"));
var design_system_1 = require("../../design-system");
var icons_1 = require("../../icons");
var Slider_styles_1 = require("./Slider.styles");
var SliderTooltip_1 = require("./SliderTooltip");
require("rc-slider/assets/index.css");
exports.Slider = (0, react_1.memo)(function (_a) {
    var _b = _a.color, color = _b === void 0 ? "primary" : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, rangeLabel = _a.rangeLabel, iconKey = _a.iconKey, _d = _a.max, max = _d === void 0 ? 100 : _d, _e = _a.min, min = _e === void 0 ? 0 : _e, _f = _a.step, step = _f === void 0 ? 10 : _f, text = _a.text, _g = _a.tooltip, tooltip = _g === void 0 ? false : _g, _h = _a.variant, variant = _h === void 0 ? "continuous" : _h, _j = _a.defaultValue, defaultValue = _j === void 0 ? variant === "range" ? [0, 0] : 0 : _j, _k = _a.width, width = _k === void 0 ? "20rem" : _k, _l = _a.tipFormatter, tipFormatter = _l === void 0 ? function (val) { return val.toString(); } : _l, props = __rest(_a, ["color", "disabled", "rangeLabel", "iconKey", "max", "min", "step", "text", "tooltip", "variant", "defaultValue", "width", "tipFormatter"]);
    var sliderRef = (0, react_1.useRef)(null);
    var _m = (0, react_1.useState)(defaultValue), value = _m[0], setValue = _m[1];
    var onSliderChange = (0, react_1.useCallback)(function (val) {
        setValue(val);
    }, []);
    var handleTooltipRender = (0, react_1.useCallback)(function (node, props) { return (0, SliderTooltip_1.handleRender)(node, props, tipFormatter); }, [tipFormatter]);
    return (react_1.default.createElement(Slider_styles_1.Container, { width: width, "data-testid": props["data-testid"], ref: sliderRef, "data-testelement": "slider" },
        react_1.default.createElement(Slider_styles_1.TextSection, null,
            iconKey && react_1.default.createElement(icons_1.Icon, { name: iconKey, color: disabled ? "text.disabled" : "emphasis.high" }),
            react_1.default.createElement(design_system_1.Text, { color: disabled ? "text.disabled" : "emphasis.high" }, text)),
        react_1.default.createElement(Slider_styles_1.StyledSlider, { color: color, disabled: disabled, dots: variant === "discrete", handleRender: tooltip ? handleTooltipRender : undefined, marks: rangeLabel ? { 0: rangeLabel[0], 100: rangeLabel[1] } : undefined, max: max, min: min, onChange: onSliderChange, range: variant === "range", step: variant === "discrete" ? step : undefined, value: value })));
});
