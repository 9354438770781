import { IconKey } from '@theguarantors/ui-kit-v3/icons';
import { StatusInfoTypes } from './StatusInfo.types';

export const statusInfoTypeIcons: Record<StatusInfoTypes, IconKey> = {
  success: 'checkCircle',
  warning: 'warning',
  error: 'denied',
};

export const STATUS_INFO_EXPIRATION_DELAY = 5000;
