"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmallLink = exports.Link = exports.Header4 = exports.Header3 = exports.Header2 = exports.Subheader = exports.Header = exports.Display = exports.MicroText = exports.HelperText = exports.Text = exports.defaultProps = void 0;
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var design_system_1 = require("..");
var createTypographyPrimitive = function (defaultProps, Component) {
    if (Component === void 0) { Component = "span"; }
    return (0, react_1.memo)(function (props) {
        var color = props.color || defaultProps.color;
        var fontSize = props.fontSize || defaultProps.fontSize;
        var fontFamily = props.fontFamily || defaultProps.fontFamily;
        var lineHeight = props.lineHeight || defaultProps.lineHeight;
        return (react_1.default.createElement(design_system_1.Box, __assign({ as: Component, to: "", display: "inline-flex" }, defaultProps, props, { fontSize: fontSize, color: color, fontFamily: fontFamily, alignitems: "center", lineheight: lineHeight })));
    });
};
exports.defaultProps = {
    display: {
        fontSize: ["xxl", "xxxl"],
        fontFamily: "heading",
        lineHeight: "heading",
    },
    header: {
        fontSize: ["xl", "xxl"],
        fontFamily: "heading",
        lineHeight: "heading",
    },
    subheader: {
        fontSize: ["lg", "xl"],
        fontFamily: "heading",
        lineHeight: "heading",
        color: "text.emphasisLow",
    },
    header2: {
        fontSize: ["lg", "xl"],
        fontFamily: "heading",
        lineHeight: "heading",
    },
    header3: {
        fontSize: ["md", "lg"],
        fontFamily: "default",
        lineHeight: "heading",
        fontWeight: "bold",
    },
    header4: {
        fontSize: "sm",
        fontFamily: "default",
        lineHeight: "heading",
        fontWeight: "bold",
    },
    body: {
        fontSize: "sm",
        lineHeight: "body",
    },
    helper: {
        color: "text.emphasisLow",
        fontSize: "xs",
    },
    microText: {
        fontSize: "xs",
    },
    link: {
        color: "primary.main",
        fontSize: "sm",
    },
    smallLink: {
        color: "primary.main",
        fontSize: "xs",
    },
};
exports.Text = createTypographyPrimitive(exports.defaultProps.body);
exports.HelperText = createTypographyPrimitive(exports.defaultProps.helper);
exports.MicroText = createTypographyPrimitive(exports.defaultProps.microText);
exports.Display = createTypographyPrimitive(exports.defaultProps.display);
exports.Header = createTypographyPrimitive(exports.defaultProps.header);
exports.Subheader = createTypographyPrimitive(exports.defaultProps.subheader);
exports.Header2 = createTypographyPrimitive(exports.defaultProps.header2);
exports.Header3 = createTypographyPrimitive(exports.defaultProps.header3);
exports.Header4 = createTypographyPrimitive(exports.defaultProps.header4);
exports.Link = createTypographyPrimitive(exports.defaultProps.link, react_router_dom_1.Link);
exports.SmallLink = createTypographyPrimitive(exports.defaultProps.smallLink, react_router_dom_1.Link);
