"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zIndeces = void 0;
exports.zIndeces = {
    couper: 1,
    content: 10,
    popup: 20,
    header: 100,
    menu: 300,
    modal: 1000,
};
