"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LastPage = exports.NextPage = exports.PreviousPage = exports.FirstPage = exports.IconContainer = exports.iconProps = exports.Page = exports.Pages = exports.Counter = exports.PaginationContainer = exports.SortingIconContainer = exports.TableCell = exports.TableRow = exports.ThContainer = exports.Th = exports.Tr = exports.THead = exports.TopBarContainer = exports.TableContainer = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var design_system_1 = require("../../design-system");
exports.TableContainer = (0, styled_components_1.default)(design_system_1.Box).attrs({
    as: "table",
    width: "100%",
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-spacing: 0;\n"], ["\n  border-spacing: 0;\n"])));
exports.TopBarContainer = (0, styled_components_1.default)(design_system_1.Box).attrs({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: "md",
    flexDirection: ["column", "row"],
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  row-gap: var(--space-xs);\n"], ["\n  row-gap: var(--space-xs);\n"])));
exports.THead = (0, styled_components_1.default)(design_system_1.Box).attrs({
    as: "thead",
    backgroundColor: "table.disabledBackground",
})(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
exports.Tr = (0, styled_components_1.default)(design_system_1.Box).attrs({
    as: "tr",
})(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
exports.Th = (0, styled_components_1.default)(design_system_1.Box).attrs(function (_a) {
    var isSorted = _a.isSorted;
    return ({
        as: "th",
        px: "0",
        py: "xs",
        border: 0,
        textAlign: "left",
        fontSize: "xs",
        color: isSorted ? "primary.main" : "neutral.main",
    });
})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  &:first-child {\n    padding-left: var(--space-sm);\n    border-top-left-radius: var(--space-xs);\n  }\n\n  &:last-child {\n    padding-right: var(--space-sm);\n    border-top-right-radius: var(--space-xs);\n  }\n"], ["\n  &:first-child {\n    padding-left: var(--space-sm);\n    border-top-left-radius: var(--space-xs);\n  }\n\n  &:last-child {\n    padding-right: var(--space-sm);\n    border-top-right-radius: var(--space-xs);\n  }\n"])));
exports.ThContainer = (0, styled_components_1.default)(design_system_1.Box).attrs({
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
})(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
exports.TableRow = (0, styled_components_1.default)(design_system_1.Box).attrs({
    as: "tr",
})(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
exports.TableCell = (0, styled_components_1.default)(design_system_1.Box).attrs({
    as: "td",
    py: "sm",
})(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-bottom: 1px solid var(--color-table-disabledBackground);\n\n  &:first-child {\n    padding-left: var(--space-sm);\n    border-left: 1px solid var(--color-table-disabledBackground);\n  }\n\n  &:last-child {\n    padding-right: var(--space-sm);\n    border-right: 1px solid var(--color-table-disabledBackground);\n  }\n"], ["\n  border-bottom: 1px solid var(--color-table-disabledBackground);\n\n  &:first-child {\n    padding-left: var(--space-sm);\n    border-left: 1px solid var(--color-table-disabledBackground);\n  }\n\n  &:last-child {\n    padding-right: var(--space-sm);\n    border-right: 1px solid var(--color-table-disabledBackground);\n  }\n"])));
exports.SortingIconContainer = (0, styled_components_1.default)(design_system_1.Box).attrs({
    display: "inline-flex",
    flexDirection: "column",
    marginLeft: "xs",
    width: "sm",
})(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
exports.PaginationContainer = (0, styled_components_1.default)(design_system_1.Box).attrs({
    paddingTop: "md",
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
})(templateObject_10 || (templateObject_10 = __makeTemplateObject([""], [""
    /*
    Pagination
    */
])));
/*
Pagination
*/
exports.Counter = (0, styled_components_1.default)(design_system_1.Box).attrs({
    paddingRight: "sm",
    fontSize: "sm",
})(templateObject_11 || (templateObject_11 = __makeTemplateObject([""], [""])));
exports.Pages = (0, styled_components_1.default)(design_system_1.Box).attrs({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    px: "xxs",
})(templateObject_12 || (templateObject_12 = __makeTemplateObject([""], [""])));
exports.Page = (0, styled_components_1.default)(design_system_1.Box).attrs(function (props) { return ({
    backgroundColor: props.active ? "table.disabledBackground" : "transparent",
    px: "xxs",
    mx: "xxs",
    height: "md",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    fontSize: "sm",
    color: "neutral.main",
}); })(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var active = _a.active;
    return !active && "cursor: pointer;";
});
var iconProps = function (disabled) {
    if (disabled === void 0) { disabled = false; }
    return ({
        width: "sm",
        height: "sm",
        color: disabled ? "text.disabled" : "neutral.main",
    });
};
exports.iconProps = iconProps;
exports.IconContainer = (0, styled_components_1.default)(design_system_1.Box).attrs({
    width: "sm",
    height: "sm",
    mx: "xxs",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
})(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (_a) {
    var disabled = _a.disabled;
    return !disabled && (0, styled_components_1.css)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      cursor: pointer;\n    "], ["\n      cursor: pointer;\n    "])));
});
exports.FirstPage = (0, styled_components_1.default)(exports.IconContainer)(templateObject_16 || (templateObject_16 = __makeTemplateObject([""], [""])));
exports.PreviousPage = (0, styled_components_1.default)(exports.IconContainer)(templateObject_17 || (templateObject_17 = __makeTemplateObject([""], [""])));
exports.NextPage = (0, styled_components_1.default)(exports.IconContainer)(templateObject_18 || (templateObject_18 = __makeTemplateObject([""], [""])));
exports.LastPage = (0, styled_components_1.default)(exports.IconContainer)(templateObject_19 || (templateObject_19 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19;
