import React from 'react';
import { Loading } from '../../components/loading';
import { BaseRoutes } from '../../components/router/router.constants';
import { useHistory } from 'react-router-dom';

export const LogoutPage: React.FC = () => {
  const history = useHistory();
  history.replace(BaseRoutes.LOGIN);

  return <Loading />;
};
