"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotesContainer = exports.PropertyBox = exports.PaymentOptionBox = exports.ConfirmationBox = exports.AmendmentBox = void 0;
var AmendmentBox_components_1 = require("./AmendmentBox.components");
Object.defineProperty(exports, "AmendmentBox", { enumerable: true, get: function () { return AmendmentBox_components_1.AmendmentBox; } });
var ConfirmationBox_components_1 = require("./ConfirmationBox.components");
Object.defineProperty(exports, "ConfirmationBox", { enumerable: true, get: function () { return ConfirmationBox_components_1.ConfirmationBox; } });
var PaymentOption_components_1 = require("./PaymentOption.components");
Object.defineProperty(exports, "PaymentOptionBox", { enumerable: true, get: function () { return PaymentOption_components_1.PaymentOptionBox; } });
var PropertyBox_components_1 = require("./PropertyBox.components");
Object.defineProperty(exports, "PropertyBox", { enumerable: true, get: function () { return PropertyBox_components_1.PropertyBox; } });
var QuotesContainer_components_1 = require("./QuotesContainer.components");
Object.defineProperty(exports, "QuotesContainer", { enumerable: true, get: function () { return QuotesContainer_components_1.QuotesContainer; } });
