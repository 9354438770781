"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringToAutocompleteItem = exports.modernizeSimpleList = exports.defaultValueRender = exports.defaultSearch = void 0;
var defaultSearch = function (suggestions, val, isStatic) {
    if (isStatic === void 0) { isStatic = false; }
    if (isStatic) {
        return suggestions;
    }
    var preparedValue = val.toLowerCase();
    return suggestions.filter(function (item) { return item.option.toLowerCase().includes(preparedValue) || item.value.toLowerCase().includes(preparedValue); });
};
exports.defaultSearch = defaultSearch;
var defaultValueRender = function (item) {
    return item.value;
};
exports.defaultValueRender = defaultValueRender;
var modernizeSimpleList = function (items) {
    return items.map(function (item) { return ({ value: item, option: item }); });
};
exports.modernizeSimpleList = modernizeSimpleList;
var stringToAutocompleteItem = function (value, suggestions) {
    return suggestions.find(function (item) { return item.option === value; });
};
exports.stringToAutocompleteItem = stringToAutocompleteItem;
