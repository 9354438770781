"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSvgColor = exports.getResponsiveAttrValue = void 0;
var styled_components_1 = require("styled-components");
var normalizeResponsiveArray_1 = require("../utils/normalizeResponsiveArray");
function getResponsiveAttrValue(attr, breakpoint) {
    var normalizedAttrs = (0, normalizeResponsiveArray_1.normalizeResponsiveArray)(attr);
    if (!Array.isArray(normalizedAttrs)) {
        return normalizedAttrs;
    }
    var mobile = normalizedAttrs[0], tablet = normalizedAttrs[1], desktop = normalizedAttrs[2], qhd = normalizedAttrs[3], uhd = normalizedAttrs[4];
    switch (breakpoint) {
        case "mobile":
            return mobile;
        case "tablet":
            return tablet;
        case "desktop":
            return desktop;
        case "qhd":
            return qhd;
        case "4k":
            return uhd;
    }
}
exports.getResponsiveAttrValue = getResponsiveAttrValue;
var setSvgColor = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  svg {\n    &,\n    * {\n      ", ";\n    }\n  }\n"], ["\n  svg {\n    &,\n    * {\n      ", ";\n    }\n  }\n"])), (0, styled_components_1.css)(args));
};
exports.setSvgColor = setSvgColor;
var templateObject_1;
