import React, { useState, useMemo, useCallback } from 'react';
import {
  Button,
  Modal,
  MaskedNumberInput,
  Input,
  RadioButton,
  Select,
  FeedbackMessage,
} from '@theguarantors/ui-kit-v3';
import { Box, Text } from '@theguarantors/ui-kit-v3/design-system';
import { Icon } from '@theguarantors/ui-kit-v3/icons';
import { withMessageDecorator } from '../../../../utils/withMessage-decorator';
import { Controller, useForm } from 'react-hook-form';
import { usePolicyUpdate } from '../../hooks/use-policy-update';
import { FeatureFlag, useFeatureFlag } from '../../../../hooks/use-feature-flag';
import { validateUserInput, normalizePremiumCancellationFee } from '../../validations';
import { CancelPolicyModalProps, SelectRefundOption } from '../../details.types';
import { convertToCents, getAmountString } from '../../details.utils';
import * as styles from '../../styles/CancelPolicyModal.styles';
import '../../details.scss';

export const CancelPolicyModal: React.FC<CancelPolicyModalProps> = ({
  isOpen,
  refetch,
  applicationId,
  setIsOpen,
  actionType,
  id,
  premiumCancellationFee,
  premiumAmount,
  isVouchedPremium,
  isMonthlyPayment,
  amountPaid,
  premiumAmountBeforeTax,
}) => {
  const [isManualReturnSelected, setIsManualReturnSelected] = useState(false);
  const [isManualRefundSelected, setIsManualRefundSelected] = useState(false);
  const [isApplyPaymentsButtonClick, setIsApplyPaymentsButtonClick] = useState(false);
  const [, { isEnabled: isManageRefundForNotOriginalPaymentEnabled }] = useFeatureFlag(
    FeatureFlag.MANAGE_REFUND_NO_ORIGINAL_PAYMENT,
  );
  const [, { isEnabled: isDisallowRefundLargerThanPaymentEnabled }] = useFeatureFlag(
    FeatureFlag.DISALLOW_REFUND_LARGER_THAN_PAYMENT,
  );
  const [, { isEnabled: isChangeDefaultReturnedPremiumForCancellationEnabled }] = useFeatureFlag(
    FeatureFlag.CHANGE_DEFAULT_RETURNED_PREMIUM_FOR_CANCELLATION,
  );

  const { policyUpdate, loading } = usePolicyUpdate();
  const { control, handleSubmit, reset } = useForm();

  const { CancelPolicyModalStyled } = styles;

  const isPremiumRefundDisallowed =
    isManageRefundForNotOriginalPaymentEnabled && (isVouchedPremium || isMonthlyPayment);

  const loadingApplyButton: boolean = useMemo(() => {
    return loading && isApplyPaymentsButtonClick;
  }, [loading, isApplyPaymentsButtonClick]);

  const premiumWithoutCancellationFee: number = useMemo(() => {
    return Number((premiumAmount - normalizePremiumCancellationFee(premiumCancellationFee)).toFixed(2));
  }, [premiumAmount, premiumCancellationFee]);

  const options: SelectRefundOption[] = useMemo(() => {
    const title = `Total ${isChangeDefaultReturnedPremiumForCancellationEnabled ? 'amount' : 'premium'}`;
    return [
      { option: title, value: `${premiumAmount}` },
      {
        option: `${title} `,
        value: `${premiumWithoutCancellationFee}`,
      },
      { option: 'No refund', value: '0' },
      { option: 'Manually enter amount', value: '' },
    ];
  }, [premiumAmount, premiumWithoutCancellationFee, isChangeDefaultReturnedPremiumForCancellationEnabled]);

  const renderSelectOption = useMemo(
    () =>
      ({ option, value }: SelectRefundOption) =>
        (
          <div className="select-option">
            <div className="option-label">
              <div>{option}</div>
              {String(premiumWithoutCancellationFee) === value && <div>(minus cancelation fee)</div>}
            </div>
            <div>{value ? `$${value}` : value}</div>
          </div>
        ),
    [premiumWithoutCancellationFee],
  );

  const onClickPolicyCancelBtn = useCallback(
    async (values): Promise<void> => {
      setIsApplyPaymentsButtonClick(true);

      const { refundPremium, reportRefundToCarrier } = values;

      const validatedRefundPremium = validateUserInput(refundPremium, options);

      const refundInCents = validatedRefundPremium === null ? null : convertToCents(validatedRefundPremium);
      const returnedPremiumInCents = convertToCents(reportRefundToCarrier);

      await policyUpdate({
        variables: {
          id,
          applicationId,
          isSkipPayment: false,
          actionType,
          refundInCents,
          returnedPremiumInCents,
        },
      });
      setIsOpen(false);
      refetch();
    },
    [id, applicationId, actionType, options, policyUpdate, setIsOpen, refetch],
  );

  const validateRefundAmountInput = useCallback(
    (value: string) => {
      const numericValue = Number(value);
      const refundAmontLimit = amountPaid ?? premiumAmount;

      if (!isNaN(numericValue) && numericValue > refundAmontLimit && isDisallowRefundLargerThanPaymentEnabled) {
        return 'The amount entered is more than the total paid';
      }

      return true;
    },
    [premiumAmount, amountPaid, isDisallowRefundLargerThanPaymentEnabled],
  );

  const handleSelectChange = useCallback((value: string) => {
    setIsManualRefundSelected(value === 'Manually enter amount');
  }, []);

  const totalText = `Total ${
    isChangeDefaultReturnedPremiumForCancellationEnabled ? 'amount' : 'premium'
  } paid by policy holder: ${getAmountString(premiumAmount)}`;

  let fullPremiumText = 'Full premium';
  if (!isChangeDefaultReturnedPremiumForCancellationEnabled) {
    fullPremiumText += `: ${getAmountString(premiumWithoutCancellationFee)}`;
  }

  const fullReturnedAmount = useMemo(() => {
    return String(
      isChangeDefaultReturnedPremiumForCancellationEnabled && premiumAmountBeforeTax
        ? premiumAmountBeforeTax
        : premiumAmount,
    );
  }, [isChangeDefaultReturnedPremiumForCancellationEnabled, premiumAmountBeforeTax, premiumAmount]);

  return (
    <CancelPolicyModalStyled>
      <Modal
        data-testid="cancel_policy_modal"
        headingContent={<Box width="100%" height="1rem" display="flex" backgroundColor="primary.main" />}
        isOpen={isOpen}
        height="auto"
        width="735px"
        title="Cancel Policy"
        onClose={() => {
          setIsManualRefundSelected(false);
          setIsManualReturnSelected(false);
          setIsOpen(false);
          reset();
        }}
      >
        <form onSubmit={handleSubmit(onClickPolicyCancelBtn)}>
          <Text style={{ textTransform: 'none' }}>{totalText}</Text>
          <Box height="2rem" width="100%" />
          <Box width="50%">
            <Controller
              name="refundPremium"
              control={control}
              rules={{
                validate: {
                  value: validateRefundAmountInput,
                },
              }}
              render={({ field, fieldState }) =>
                isManualRefundSelected || isPremiumRefundDisallowed ? (
                  withMessageDecorator(
                    <MaskedNumberInput
                      invalid={Boolean(fieldState?.error?.message)}
                      required={!isPremiumRefundDisallowed}
                      disabled={isPremiumRefundDisallowed}
                      className={isPremiumRefundDisallowed ? 'input-refund-premium' : ''}
                      label="Refund amount"
                      {...field}
                      value={field.value === 'Manually enter amount' ? '' : field.value}
                    />,
                    {
                      message: fieldState?.error?.message ?? '',
                      type: fieldState?.error?.message ? 'error' : 'message',
                    },
                  )
                ) : (
                  <Select
                    {...field}
                    required
                    placeholder="Refund amount"
                    options={options}
                    optionRender={renderSelectOption}
                    onChange={(e) => {
                      field.onChange(e);
                      handleSelectChange(e);
                    }}
                  />
                )
              }
            />
          </Box>
          {isPremiumRefundDisallowed && (
            <Box
              style={{
                textTransform: 'none',
                marginTop: '0.5rem',
                fontWeight: 'bold',
                width: '55%',
              }}
            >
              <FeedbackMessage type="info">The refund must be processed manually</FeedbackMessage>
            </Box>
          )}
          <Box height="2rem" width="100%" />
          <Box style={{ textTransform: 'none', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
            <Text>Amount reported to carrier</Text>
            <Icon name="errorOutline" variation="stand-alone" style={{ marginLeft: '0.5rem' }} size={20} />
          </Box>
          <Controller
            name="reportRefundToCarrier"
            control={control}
            rules={{ required: 'Field is required' }}
            render={({ field }) => (
              <Box style={{ textTransform: 'none' }}>
                <Box height="2.5rem" style={{ marginLeft: -8, marginTop: -5 }}>
                  <RadioButton
                    {...field}
                    buttonValue={fullReturnedAmount}
                    onChange={(e) => {
                      field.onChange(e);
                      setIsManualReturnSelected(false);
                    }}
                  >
                    {fullPremiumText}
                  </RadioButton>
                </Box>
                <Box style={{ marginLeft: -8 }}>
                  <RadioButton
                    {...field}
                    buttonValue=""
                    checked={isManualReturnSelected}
                    onChange={(e) => {
                      field.onChange(e);
                      setIsManualReturnSelected(true);
                    }}
                  >
                    Specific amount
                  </RadioButton>
                </Box>
                {isManualReturnSelected && (
                  <Box width="50%">
                    <Input required type="number" label="Report amount" placeholder="$ 0.00" {...field} />
                  </Box>
                )}
              </Box>
            )}
          />
          <Box height="2rem" width="100%" />
          <Box display="flex" justifyContent="flex-end">
            <Button disabled={loading} type="submit" bType="error" style={{ padding: '8px 16px' }}>
              {loadingApplyButton ? <Icon name="loading" /> : 'Cancel Policy'}
            </Button>
          </Box>
        </form>
      </Modal>
    </CancelPolicyModalStyled>
  );
};
