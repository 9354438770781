"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoModalComponent = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("..");
var Modal_v1_styles_1 = require("../Modal/styles/Modal.v1.styles");
var design_system_1 = require("../../design-system");
var icons_1 = require("../../icons");
var InfoModal_styles_1 = require("./InfoModal.styles");
exports.InfoModalComponent = (0, react_1.memo)(function (_a) {
    var title = _a.title, isOpen = _a.isOpen, _b = _a.iconKey, iconKey = _b === void 0 ? "meetingRoom" : _b, secondaryIconKey = _a.secondaryIconKey, _c = _a.iconColor, iconColor = _c === void 0 ? "primary.main" : _c, _d = _a.secondaryIconColor, secondaryIconColor = _d === void 0 ? "error.main" : _d, onClose = _a.onClose, onAction = _a.onAction, onCancel = _a.onCancel, _e = _a.height, height = _e === void 0 ? "22.5rem" : _e, _f = _a.width, width = _f === void 0 ? "30.5rem" : _f, _g = _a.borderRadius, borderRadius = _g === void 0 ? "0.5rem" : _g, description = _a.description, actionButtonLabel = _a.actionButtonLabel, actionSection = _a.actionSection, _h = _a.cancelLabel, cancelLabel = _h === void 0 ? "Cancel" : _h, headingVariant = _a.headingVariant, _j = _a.cancelButtonType, cancelButtonType = _j === void 0 ? "info" : _j, _k = _a.actionButtonType, actionButtonType = _k === void 0 ? "primary" : _k, _l = _a.actionButtonVariant, actionButtonVariant = _l === void 0 ? "contained" : _l, _m = _a.cancelButtonVariant, cancelButtonVariant = _m === void 0 ? "contained" : _m, _o = _a.buttonSize, buttonSize = _o === void 0 ? "s" : _o, _p = _a.actionButtonIsLoading, actionButtonIsLoading = _p === void 0 ? false : _p, props = __rest(_a, ["title", "isOpen", "iconKey", "secondaryIconKey", "iconColor", "secondaryIconColor", "onClose", "onAction", "onCancel", "height", "width", "borderRadius", "description", "actionButtonLabel", "actionSection", "cancelLabel", "headingVariant", "cancelButtonType", "actionButtonType", "actionButtonVariant", "cancelButtonVariant", "buttonSize", "actionButtonIsLoading"]);
    return (react_1.default.createElement(design_system_1.Box, { "data-testid": props["data-testid"], "data-testelement": "infoModal" },
        react_1.default.createElement(components_1.Modal, { title: "InfoModal", isOpen: isOpen, onClose: onClose, height: height, width: width, borderRadius: borderRadius, headingContent: react_1.default.createElement(design_system_1.Box, { width: "100%", height: "1rem", display: "flex", backgroundColor: "".concat(headingVariant, ".main") }) },
            react_1.default.createElement(InfoModal_styles_1.IconSectionMeeting, null,
                react_1.default.createElement(icons_1.Icon, { name: iconKey, color: iconColor, size: "lg" }),
                secondaryIconKey && (react_1.default.createElement(InfoModal_styles_1.IconSectionSecondary, null,
                    react_1.default.createElement(icons_1.Icon, { name: secondaryIconKey, color: secondaryIconColor, size: "md" })))),
            react_1.default.createElement(Modal_v1_styles_1.Title, null, title),
            react_1.default.createElement(Modal_v1_styles_1.Content, { scrollable: true }, description),
            actionSection || (react_1.default.createElement(InfoModal_styles_1.ButtonSection, null,
                react_1.default.createElement(components_1.Button, { size: buttonSize, bType: cancelButtonType, bVariant: cancelButtonVariant, onClick: onCancel }, cancelLabel),
                react_1.default.createElement(components_1.Button, { loading: actionButtonIsLoading, size: buttonSize, bType: actionButtonType, bVariant: actionButtonVariant, onClick: onAction }, actionButtonLabel))))));
});
