"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleMap = void 0;
var react_1 = __importStar(require("react"));
var markerwithlabel_1 = require("@googlemaps/markerwithlabel");
var design_system_1 = require("../../design-system");
var hooks_1 = require("../../hooks");
var GoogleMap_styles_1 = require("./GoogleMap.styles");
var GoogleMap_types_1 = require("./GoogleMap.types");
var GoogleMap = function (_a) {
    var _b = _a.coordinates, lat = _b.lat, lng = _b.lng, _c = _a.name, name = _c === void 0 ? "" : _c, googleKey = _a.googleKey, props = __rest(_a, ["coordinates", "name", "googleKey"]);
    var ref = (0, react_1.useRef)(null);
    var scriptStatus = (0, hooks_1.useLoadGoogleScript)(googleKey);
    var _d = (0, react_1.useState)(""), error = _d[0], setError = _d[1];
    (0, react_1.useEffect)(function () {
        // check if coordinates are defined, in other case it causes error
        if (!lat && !lng) {
            setError("Coordinates is not defied");
        }
        if (scriptStatus === GoogleMap_types_1.ScriptStatus.ERROR) {
            setError("Error while script loading");
        }
        if (lat && lng && scriptStatus !== GoogleMap_types_1.ScriptStatus.ERROR) {
            setError("");
        }
    }, [lat, lng, scriptStatus]);
    (0, react_1.useEffect)(function () {
        if (scriptStatus === GoogleMap_types_1.ScriptStatus.READY && (window === null || window === void 0 ? void 0 : window.google) && !error) {
            try {
                var google_1 = window === null || window === void 0 ? void 0 : window.google;
                var map_1 = new google_1.maps.Map(ref.current, {
                    center: new google_1.maps.LatLng(lat, lng),
                    zoom: 14,
                    mapTypeControl: false,
                });
                var request = {
                    query: name,
                    fields: ["name"],
                    locationBias: new google_1.maps.LatLng(lat, lng),
                };
                var placesService = new google_1.maps.places.PlacesService(map_1);
                placesService.findPlaceFromQuery(request, function (results) {
                    new markerwithlabel_1.MarkerWithLabel({
                        position: new google_1.maps.LatLng(lat, lng),
                        draggable: false,
                        clickable: false,
                        map: map_1,
                        labelContent: (results === null || results === void 0 ? void 0 : results[0].name) || "",
                        labelAnchor: new google_1.maps.Point(15, -40),
                    });
                });
            }
            catch (e) {
                console.log(e);
                setError("Something wrong with placing marker");
            }
        }
    }, [scriptStatus, lat, lng, name, error]);
    return (react_1.default.createElement(design_system_1.Box, { height: "100%", width: "100%", "data-testid": "container-".concat(props["data-testid"]), "data-testelement": "googleMap" }, error ? (react_1.default.createElement(GoogleMap_styles_1.GoogleMapDiv, __assign({}, props),
        react_1.default.createElement(design_system_1.Header4, { display: "flex", alignItems: "center" }, "Something wrong with placing marker"))) : (react_1.default.createElement(GoogleMap_styles_1.GoogleMapDiv, __assign({ ref: ref }, props)))));
};
exports.GoogleMap = GoogleMap;
