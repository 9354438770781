export enum BaseRoutes {
  PROPERTIES = '/properties',
  APPLICATIONS = '/applications',
  DEALS = '/deals',
  TECHNICAL_DIFFICULTIES = '/technical-difficulties',
  LOGIN = '/login',
  AUTH_CALLBACK = '/callback',
  TG_AUTH_CALLBACK = '/tg-auth-callback',
  LOGOUT = '/logout',
}
