"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeResponsiveArray = void 0;
function normalizeResponsiveArray(value) {
    if (!Array.isArray(value)) {
        return value;
    }
    var mobileValue = value[0], tabletValue = value[1], desktopValue = value[2], qhdValue = value[3], uhd4kValue = value[4];
    if (value.length === 1) {
        return mobileValue;
    }
    if (value.length === 2) {
        return [mobileValue, tabletValue, tabletValue, tabletValue, tabletValue];
    }
    if (value.length === 3) {
        return [mobileValue, tabletValue, desktopValue, desktopValue, desktopValue];
    }
    if (value.length === 4) {
        return [mobileValue, tabletValue, desktopValue, qhdValue, qhdValue];
    }
    if (value.length === 5) {
        return [mobileValue, tabletValue, desktopValue, qhdValue, uhd4kValue];
    }
    return value;
}
exports.normalizeResponsiveArray = normalizeResponsiveArray;
