"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioButton = void 0;
var react_1 = __importStar(require("react"));
var typography_1 = require("../../design-system/typography");
var icons_1 = require("../../icons");
var Checkbox_styles_1 = require("../Checkbox/Checkbox.styles");
exports.RadioButton = (0, react_1.memo)(function (_a) {
    var name = _a.name, bordered = _a.bordered, checked = _a.checked, _b = _a.colorType, colorType = _b === void 0 ? "neutral" : _b, children = _a.children, buttonValue = _a.buttonValue, value = _a.value, _c = _a.onChange, onChange = _c === void 0 ? function () { } : _c, props = __rest(_a, ["name", "bordered", "checked", "colorType", "children", "buttonValue", "value", "onChange"]);
    var id = (0, react_1.useMemo)(function () { return "radiobutton-".concat(name, "-").concat(buttonValue); }, [name, buttonValue]);
    var isChecked = (0, react_1.useMemo)(function () { return checked || buttonValue === value; }, [checked, buttonValue, value]);
    var iconName = (0, react_1.useMemo)(function () { return (isChecked ? "radioButtonChecked" : "radioButtonUnchecked"); }, [isChecked]);
    var _onChange = (0, react_1.useCallback)(function (e) {
        if (props.disabled)
            return;
        onChange(e);
    }, [onChange, props.disabled]);
    var selectedIconColor = (0, react_1.useMemo)(function () { return (isChecked ? "".concat(colorType, ".main") : "neutral.main"); }, [isChecked, colorType]);
    return (react_1.default.createElement(Checkbox_styles_1.Label, { htmlFor: id, bordered: bordered, isChecked: isChecked, disabled: props.disabled, colorType: colorType },
        react_1.default.createElement(Checkbox_styles_1.Input, __assign({ type: "radio", name: name, id: id }, props, { onChange: _onChange, checked: isChecked, value: buttonValue })),
        react_1.default.createElement(Checkbox_styles_1.Indicator, { disabled: props.disabled },
            react_1.default.createElement(icons_1.Icon, { name: iconName, color: props.disabled ? "text.disabled" : props.radioIconColor || selectedIconColor })),
        props.iconKey && react_1.default.createElement(icons_1.Icon, { mr: "0.5rem", color: props.iconColor, name: props.iconKey }),
        react_1.default.createElement(typography_1.Text, { fontWeight: isChecked ? "bold" : "default" }, children)));
});
