"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.for4kDisplay = exports.forQHDDisplay = exports.forDesktop = exports.forTabletsAndDesktop = exports.forTablets = exports.forMobilesAndTablets = exports.forMobiles = exports.breakpoints = void 0;
var styled_components_1 = require("styled-components");
// why em is better for media queries: https://zellwk.com/blog/media-query-units/
exports.breakpoints = ["37.5em", "62em", "96em", "162.5em"]; // 600px, 992px, 1536px, 2600px
var forMobiles = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @media (max-width: ", ") {\n    ", ";\n  }\n"], ["\n  @media (max-width: ", ") {\n    ", ";\n  }\n"])), exports.breakpoints[0], (0, styled_components_1.css)(args));
};
exports.forMobiles = forMobiles;
var forMobilesAndTablets = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  @media (max-width: ", ") {\n    ", ";\n  }\n"], ["\n  @media (max-width: ", ") {\n    ", ";\n  }\n"])), exports.breakpoints[1], (0, styled_components_1.css)(args));
};
exports.forMobilesAndTablets = forMobilesAndTablets;
var forTablets = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return (0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  @media (min-width: ", ") and (max-width: ", ") {\n    ", ";\n  }\n"], ["\n  @media (min-width: ", ") and (max-width: ", ") {\n    ", ";\n  }\n"])), exports.breakpoints[0], exports.breakpoints[1], (0, styled_components_1.css)(args));
};
exports.forTablets = forTablets;
var forTabletsAndDesktop = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  @media (min-width: ", ") and (max-width: ", ") {\n    ", ";\n  }\n"], ["\n  @media (min-width: ", ") and (max-width: ", ") {\n    ", ";\n  }\n"])), exports.breakpoints[0], exports.breakpoints[2], (0, styled_components_1.css)(args));
};
exports.forTabletsAndDesktop = forTabletsAndDesktop;
var forDesktop = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return (0, styled_components_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  @media (min-width: ", ") {\n    ", ";\n  }\n"], ["\n  @media (min-width: ", ") {\n    ", ";\n  }\n"])), exports.breakpoints[1], (0, styled_components_1.css)(args));
};
exports.forDesktop = forDesktop;
var forQHDDisplay = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return (0, styled_components_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  @media (min-width: ", ") {\n    ", ";\n  }\n"], ["\n  @media (min-width: ", ") {\n    ", ";\n  }\n"])), exports.breakpoints[2], (0, styled_components_1.css)(args));
};
exports.forQHDDisplay = forQHDDisplay;
var for4kDisplay = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return (0, styled_components_1.css)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  @media (min-width: ", ") {\n    ", ";\n  }\n"], ["\n  @media (min-width: ", ") {\n    ", ";\n  }\n"])), exports.breakpoints[3], (0, styled_components_1.css)(args));
};
exports.for4kDisplay = for4kDisplay;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
