import gql from 'graphql-tag';

const ApplicationPaymentTypeDef = gql`
  type ApplicationPaymentEntity {
    amountPaid: Float
    amountRefunded: Float
    outstandingAmount: Float
    history: [PaymentHistory]
    premiumAmount: Float
  }

  type PaymentHistory {
    id: String!
    status: String!
    paymentMethod: String!
    amount: String!
    date: String!
  }
`;

export default [ApplicationPaymentTypeDef];
