"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwitchInput = exports.SwitchContainer = exports.ToggleElement = exports.SwitchLabel = exports.SwitchWrapper = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var design_system_1 = require("../../design-system");
exports.SwitchWrapper = (0, styled_components_1.default)(design_system_1.Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
exports.SwitchLabel = (0, styled_components_1.default)(design_system_1.Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: var(--space-xs);\n  min-height: var(--space-md);\n  ", ";\n"], ["\n  margin-left: var(--space-xs);\n  min-height: var(--space-md);\n  ", ";\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled && "color: var(--color-text-disabled)";
});
exports.ToggleElement = styled_components_1.default.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: ", ";\n  opacity: ", ";\n  width: 100%;\n  height: 100%;\n  background: var(--color-decorative-gray);\n  border-radius: var(--space-lg);\n\n  &,\n  &:before {\n    position: absolute;\n    transition: var(--transition-default);\n    left: 0;\n  }\n\n  &:before {\n    content: \"\";\n    height: var(--space-md);\n    width: var(--space-md);\n    left: var(--space-clear);\n    bottom: calc(-1 * var(--space-xxs));\n    background: var(--color-other-white);\n    border-radius: 50%;\n    box-shadow: var(--elevation-1dp);\n  }\n"], ["\n  cursor: ", ";\n  opacity: ", ";\n  width: 100%;\n  height: 100%;\n  background: var(--color-decorative-gray);\n  border-radius: var(--space-lg);\n\n  &,\n  &:before {\n    position: absolute;\n    transition: var(--transition-default);\n    left: 0;\n  }\n\n  &:before {\n    content: \"\";\n    height: var(--space-md);\n    width: var(--space-md);\n    left: var(--space-clear);\n    bottom: calc(-1 * var(--space-xxs));\n    background: var(--color-other-white);\n    border-radius: 50%;\n    box-shadow: var(--elevation-1dp);\n  }\n"])), function (_a) {
    var disabled = _a.disabled;
    return (disabled ? "not-allowed" : "pointer");
}, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 0.37 : 1);
});
var activeToggleElementStyles = function (_a) {
    var value = _a.value, color = _a.color;
    return value && (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    ", " {\n      background: ", ";\n\n      &:before {\n        transform: translateX(calc(100% - var(--space-xs)));\n      }\n    }\n  "], ["\n    ", " {\n      background: ", ";\n\n      &:before {\n        transform: translateX(calc(100% - var(--space-xs)));\n      }\n    }\n  "])), exports.ToggleElement, color);
};
exports.SwitchContainer = (0, styled_components_1.default)(design_system_1.Box).attrs({
    as: "label",
})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  width: var(--space-xl);\n  height: var(--space-sm);\n\n  ", ";\n"], ["\n  position: relative;\n  width: var(--space-xl);\n  height: var(--space-sm);\n\n  ", ";\n"])), activeToggleElementStyles);
exports.SwitchInput = styled_components_1.default.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: var(--space-clear);\n  height: var(--space-clear);\n"], ["\n  width: var(--space-clear);\n  height: var(--space-clear);\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
