import { useMutation, gql, MutationFunction } from '@apollo/client';
import { PolicyEntity } from '../../../api/api-types';

interface VouchPremiumResponse {
  vouchPremium: PolicyEntity | null;
}

interface VouchPremiumVars {
  applicationId: number | string;
  isVouchedPremium: boolean;
}

interface APIResponse {
  vouchPremium: MutationFunction<VouchPremiumResponse, VouchPremiumVars>;
  policy: PolicyEntity | null;
  loading: boolean;
}

export function useVouchPremium(): APIResponse {
  const [vouchPremium, { data, loading }] = useMutation<VouchPremiumResponse, VouchPremiumVars>(
    gql`
      mutation VouchPremium($applicationId: Int!, $isVouchedPremium: Boolean!) {
        vouchPremium(applicationId: $applicationId, isVouchedPremium: $isVouchedPremium) {
          id
        }
      }
    `,
  );

  return {
    vouchPremium,
    policy: data?.vouchPremium ?? null,
    loading,
  };
}
