import gql from 'graphql-tag';

export const ReplyTypeDef = gql`
  extend type Query {
    reply(applicationId: Int!): ReplyEntity
  }

  type ReplyEntityAnswer {
    fieldId: Int!
    value: String!
  }

  type ReplyEntity {
    completed: Boolean!
    answers: [ReplyEntityAnswer!]!
  }
`;
