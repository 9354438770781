import React, { useCallback, useMemo, useState } from 'react';
import { Button, InfoContainer, InfoModal, Table, ToggleSwitch } from '@theguarantors/ui-kit-v3';
import { Text, Box } from '@theguarantors/ui-kit-v3/design-system';
import { InfoContainerData } from '@theguarantors/ui-kit-v3/components/InfoContainer/InfoContainer.types';
import { Modal, ModalVersion } from '@theguarantors/ui-kit-v3/components/Modal';
import { TableColumn, TableData } from '@theguarantors/ui-kit-v3/components/Table/Table.types';
import { Icon } from '@theguarantors/ui-kit-v3/icons';
import { PaymentHistory } from '../../../../api/api-types';
import { getAmountString, getInfoContainerDataRow } from '../../details.utils';
import { ReadableAnswers } from '../../details.constants';
import { FeatureFlag, useFeatureFlag } from '../../../../hooks/use-feature-flag';
import { useVouchPremium } from '../../hooks/use-vouch-premium';
import { MonthlyPayment } from '../../details.types';
import clsx from 'clsx';

interface PaymentInfoProps {
  readonly data: {
    isVouchedPremium: boolean;
    applicationId: number | undefined;
    amountPaid: number | undefined | null;
    amountRefunded: number | undefined | null;
    outstandingAmount: number | null | undefined | null;
    premiumAmount: number | null | undefined;
    premiumAmountWithoutUpfrontDiscount: number | null | undefined;
    history: PaymentHistory[];
  };
  readonly refetch: () => void;
}

type PaymentHistoryFields = 'amount' | 'id' | 'status' | 'paymentMethod' | 'date';

const getAmountInfo = (number: number | undefined | null) =>
  number || number === 0 ? getAmountString(number) : ReadableAnswers.NULL;

export const PaymentInfo: React.FC<PaymentInfoProps & MonthlyPayment> = ({
  data,
  isMonthlyPayment,
  refetch,
  ...rest
}): React.ReactElement => {
  const { vouchPremium, loading } = useVouchPremium();
  const [vouchPremiumPerDealTreatment] = useFeatureFlag(FeatureFlag.VOUCH_PREMIUM_PER_DEAL);

  const [isPaymentHistoryModalOpen, setPaymentHistoryModalOpen] = useState<boolean>(false);
  const [isVouchPremiumModalOpen, setVouchPremiumModalOpen] = useState<boolean>(false);
  const [isVouchedPremium, setVouchedPremium] = useState<boolean>(data.isVouchedPremium);

  const openTransactionsModal = useCallback(() => {
    setPaymentHistoryModalOpen(true);
  }, [setPaymentHistoryModalOpen]);

  const closeTransactionsModal = useCallback(() => {
    setPaymentHistoryModalOpen(false);
  }, [setPaymentHistoryModalOpen]);

  const changeVouchedPremium = useCallback(
    (isVouchedPremium: boolean) => {
      if (!data.applicationId) return;

      void vouchPremium({
        variables: { applicationId: data.applicationId, isVouchedPremium },
        onCompleted: () => {
          refetch();
        },
      });
    },
    [data.applicationId, vouchPremium, refetch],
  );

  const confirmVouchingPremium = useCallback(() => {
    changeVouchedPremium(true);
    setVouchPremiumModalOpen(false);
  }, [changeVouchedPremium, setVouchPremiumModalOpen]);

  const vouchPremiumChangeHandler = useCallback(() => {
    if (!isVouchedPremium) {
      setVouchedPremium(true);
      setVouchPremiumModalOpen(true);
      return;
    }

    setVouchedPremium(false);
    changeVouchedPremium(false);
  }, [isVouchedPremium, setVouchPremiumModalOpen, changeVouchedPremium]);

  const cancelVouchPremiumChange = useCallback(() => {
    setVouchedPremium(false);
    setVouchPremiumModalOpen(false);
  }, []);

  const premium = useMemo(() => {
    return isMonthlyPayment ? data.premiumAmountWithoutUpfrontDiscount : data.premiumAmount;
  }, [isMonthlyPayment, data]);

  const firstRows = useMemo(
    () =>
      vouchPremiumPerDealTreatment === 'on'
        ? [
            getInfoContainerDataRow(
              <Box bold mb=".4rem">
                <b>Vouch Premium</b>
              </Box>,
              <Box mb=".4rem" style={{ textTransform: 'none', textAlign: 'center' }}>
                <ToggleSwitch
                  uncontrolled
                  color="success.main"
                  data-testid="auto_payment_vouched_premium"
                  value={isVouchedPremium}
                  disabled={loading}
                  onChange={vouchPremiumChangeHandler}
                />
                <InfoModal
                  iconKey="assignment"
                  secondaryIconKey="dangerTriangle"
                  secondaryIconColor="warning.main"
                  cancelLabel="Nevermind"
                  actionButtonLabel="Yes, Continue"
                  title="Vouch Premium?"
                  description="If you continue, the renter will be able to buy the policy without having to make a payment. Are you sure?"
                  data-testid="auto_payment_vouch_modal"
                  isOpen={isVouchPremiumModalOpen}
                  onAction={confirmVouchingPremium}
                  onClose={cancelVouchPremiumChange}
                  onCancel={cancelVouchPremiumChange}
                />
              </Box>,
            ),
            getInfoContainerDataRow(<b>Quote</b>, <b data-testid="auto_payment_quote">{getAmountInfo(premium)}</b>),
          ]
        : [getInfoContainerDataRow(<b>Quote</b>, <b data-testid="auto_payment_quote">{getAmountInfo(premium)}</b>)],
    [
      premium,
      loading,
      isVouchedPremium,
      isVouchPremiumModalOpen,
      vouchPremiumPerDealTreatment,
      cancelVouchPremiumChange,
      vouchPremiumChangeHandler,
      confirmVouchingPremium,
    ],
  );

  const paymentData = useMemo(() => {
    const result: InfoContainerData[] = [
      ...firstRows,
      getInfoContainerDataRow(
        'Total Paid',
        <Box data-testid="auto_payment_paid">{getAmountInfo(data.amountPaid)}</Box>,
      ),
      getInfoContainerDataRow(
        'Total Refunded',
        <Box data-testid="auto_payment_refunded">{getAmountInfo(data.amountRefunded)}</Box>,
      ),
    ];

    result.push({
      label: (
        <Button
          data-testid="auto_view_all_transactions"
          size="s"
          bType="primary"
          bVariant="ghost"
          color="primary.main"
          style={{ paddingLeft: 0 }}
          onClick={openTransactionsModal}
        >
          <Icon width={16} height={16} name="arrowForward" color="primary.main" />
          View All Transactions
        </Button>
      ),
    });

    return result;
  }, [data, firstRows, openTransactionsModal]);

  const paymentHistoryColumns: Array<TableColumn<PaymentHistoryFields>> = [
    { Header: 'Payment ID', accessor: 'id' },
    { Header: 'Payment Status', accessor: 'status' },
    { Header: 'Payment Method', accessor: 'paymentMethod' },
    { Header: 'Amount', accessor: 'amount' },
    { Header: 'Date', accessor: 'date' },
  ];

  const paymentHistoryData = data?.history
    ? data.history.map((historyPayment, index) => ({
        id: {
          value: historyPayment.id,
          customRender: (value: string) => {
            return <Box data-testid={`auto_payment_id_${index + 1}`}>{value}</Box>;
          },
        },
        status: {
          value: historyPayment.status,
          customRender: (value: string) => {
            return <Box data-testid={`auto_status_id_${index + 1}`}>{value}</Box>;
          },
        },
        paymentMethod: {
          value: historyPayment.paymentMethod,
          customRender: (value: string) => {
            return <Box data-testid={`auto_payment_method_id_${index + 1}`}>{value}</Box>;
          },
        },
        amount: {
          value: historyPayment.amount,
          customRender: (value: string) => {
            return (
              <Text data-testid={`auto_amount_${index + 1}`} color={value.includes('-') ? 'error.main' : ''}>
                {value}
              </Text>
            );
          },
        },
        date: {
          value: historyPayment.date,
          customRender: (value: string) => {
            return <Box data-testid={`auto_date_${index + 1}`}>{value}</Box>;
          },
        },
      }))
    : [];

  const getCellProps = useCallback(() => ({ color: 'neutral.main' }), []);

  const getColumnProps = useCallback((props) => {
    const { id } = props;

    return {
      className: clsx({
        'fs-exclude': ['id', 'paymentMethod', 'amount', 'date'].includes(id),
      }),
    };
  }, []);

  return (
    <>
      <InfoContainer
        color="neutral.main"
        title="Payment"
        data={paymentData}
        dataBlockColProps={{ style: { textTransform: 'capitalize' } }}
        extra={{
          icon: 'lock',
          tooltipTitle: 'This information can`t be edited',
          tooltipId: 'dLargeButton',
        }}
        {...rest}
      />
      <Modal
        scrollable
        data-testid="deal_all_transactions_modal"
        isOpen={isPaymentHistoryModalOpen}
        title={`Deal #${data.applicationId ?? '...'} - All Transactions`}
        version={ModalVersion.V2}
        onClose={closeTransactionsModal}
      >
        <Table
          columns={paymentHistoryColumns}
          data={paymentHistoryData as Array<TableData<PaymentHistoryFields>>}
          getCellProps={getCellProps}
          getColumnProps={getColumnProps}
        />
      </Modal>
    </>
  );
};
