"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeedbackMessage = void 0;
var react_1 = __importStar(require("react"));
var design_system_1 = require("../../design-system");
var icons_1 = require("../../icons");
var FeedbackMessage_styles_1 = require("./FeedbackMessage.styles");
var FeedbackMessageIcon = function (_a) {
    var type = _a.type;
    var iconMap = (0, react_1.useMemo)(function () { return ({
        success: "checkCircle",
        warning: "warning",
        error: "info",
        info: "info",
    }); }, []);
    var colorMap = (0, react_1.useMemo)(function () { return ({
        success: "success.main",
        warning: "warning.main",
        error: "error.main",
        info: "info.main",
    }); }, []);
    return react_1.default.createElement(icons_1.Icon, { name: iconMap[type], color: colorMap[type] });
};
var FeedbackMessage = function (_a) {
    var title = _a.title, type = _a.type, children = _a.children, onClose = _a.onClose, props = __rest(_a, ["title", "type", "children", "onClose"]);
    return (react_1.default.createElement(FeedbackMessage_styles_1.Container, { type: type, "data-testid": props["data-testid"], "data-testelement": "feedbackMessage" },
        react_1.default.createElement(design_system_1.Box, { display: "flex" },
            react_1.default.createElement(FeedbackMessageIcon, { type: type })),
        react_1.default.createElement(design_system_1.Box, { display: "flex", flexDirection: "column", "data-testid": "content-".concat(props["data-testid"]) },
            typeof title === "string" ? react_1.default.createElement(design_system_1.Header4, null, title) : title,
            children),
        onClose && (react_1.default.createElement(design_system_1.Box, { ml: "auto", onClick: onClose, cursor: "pointer" },
            react_1.default.createElement(icons_1.Icon, { "data-testid": "close-".concat(props["data-testid"]), size: "xs", name: "close", color: "text.body" })))));
};
exports.FeedbackMessage = FeedbackMessage;
