"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBadgeVariant = void 0;
var getBadgeVariant = function (variant) {
    var variants = {
        primary: {
            backgroundColor: "var(--color-neutral-main)",
            hover: "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), var(--color-primary-main)",
        },
        secondary: {
            backgroundColor: "var(--color-primary-main)",
            hover: "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), var(--color-primary-main)",
        },
        info: {
            backgroundColor: "var(--color-info-main)",
            hover: "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), var(--color-info-main)",
        },
        success: {
            backgroundColor: "var(--color-success-main)",
            hover: "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), var(--color-success-main)",
        },
        disabled: {
            backgroundColor: "var(--color-table-disabledBackground)",
            hover: "var(--color-table-disabledBackground)",
        },
    };
    return variant ? variants[variant] : variants.disabled;
};
exports.getBadgeVariant = getBadgeVariant;
