"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryCard = void 0;
var react_1 = __importStar(require("react"));
var design_system_1 = require("../../design-system");
var icons_1 = require("../../icons");
var SummaryCard_styles_1 = require("./SummaryCard.styles");
exports.SummaryCard = (0, react_1.memo)(function (_a) {
    var data = _a.data, placeholder = _a.placeholder, placeholderIcon = _a.placeholderIcon, onEdit = _a.onEdit, onAdd = _a.onAdd, _b = _a.editable, editable = _b === void 0 ? true : _b, _c = _a.isMapInput, isMapInput = _c === void 0 ? false : _c, props = __rest(_a, ["data", "placeholder", "placeholderIcon", "onEdit", "onAdd", "editable", "isMapInput"]);
    return (react_1.default.createElement(SummaryCard_styles_1.Container, { isMapInput: isMapInput, "data-testid": props["data-testid"], "data-testelement": "summary-card" },
        react_1.default.createElement(SummaryCard_styles_1.List, null, data.length ? (react_1.default.createElement(react_1.default.Fragment, null,
            editable && (react_1.default.createElement(SummaryCard_styles_1.EditSection, null,
                react_1.default.createElement(icons_1.Icon, { name: "edit", onClick: onEdit }))),
            data.map(function (item) { return (react_1.default.createElement(SummaryCard_styles_1.Item, { key: item.dataKey },
                react_1.default.createElement(SummaryCard_styles_1.Label, null, item.label),
                react_1.default.createElement(SummaryCard_styles_1.Content, null, item.content))); }))) : (react_1.default.createElement(SummaryCard_styles_1.PlaceholderSection, { onClick: onAdd },
            !isMapInput && (react_1.default.createElement(SummaryCard_styles_1.IconSection, null,
                react_1.default.createElement(icons_1.Icon, { name: placeholderIcon }))),
            react_1.default.createElement(design_system_1.Header4, null, placeholder))))));
});
