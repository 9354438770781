"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderSection = exports.Content = exports.Label = exports.EditSection = exports.IconSection = exports.Item = exports.List = exports.SummaryGroupV2 = exports.SummaryGroupV1 = exports.Container = exports.tabletsAndDesktopContainer = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var design_system_1 = require("../../design-system");
var utils_1 = require("../../design-system/utils");
exports.tabletsAndDesktopContainer = (0, design_system_1.forTabletsAndDesktop)((0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: var(--space-xs);\n"], ["\n  border-radius: var(--space-xs);\n"]))));
exports.Container = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  background: ", ";\n  overflow: hidden;\n  padding: var(--space-clear) var(--space-md);\n\n  ", ";\n"], ["\n  position: relative;\n  background: ", ";\n  overflow: hidden;\n  padding: var(--space-clear) var(--space-md);\n\n  ", ";\n"])), function (_a) {
    var isMapInput = _a.isMapInput;
    return isMapInput ? "var(--color-other-white)" : "var(--color-table-disabledBackground)";
}, exports.tabletsAndDesktopContainer);
exports.SummaryGroupV1 = styled_components_1.default.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: var(--space-lg) var(--space-clear);\n\n  ", " {\n    border-radius: var(--space-clear);\n\n    &:first-child {\n      border-top-left-radius: var(--space-xs);\n      border-top-right-radius: var(--space-xs);\n    }\n\n    &:last-child {\n      border-bottom-left-radius: var(--space-xs);\n      border-bottom-right-radius: var(--space-xs);\n    }\n  }\n"], ["\n  margin: var(--space-lg) var(--space-clear);\n\n  ", " {\n    border-radius: var(--space-clear);\n\n    &:first-child {\n      border-top-left-radius: var(--space-xs);\n      border-top-right-radius: var(--space-xs);\n    }\n\n    &:last-child {\n      border-bottom-left-radius: var(--space-xs);\n      border-bottom-right-radius: var(--space-xs);\n    }\n  }\n"])), exports.Container);
exports.SummaryGroupV2 = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-top: 1.125rem\n    ", ";\n  border-radius: var(--space-xs) var(--space-xs) var(--space-clear) var(--space-clear);\n  margin: var(--space-lg) var(--space-clear);\n  filter: var(--filter-1dp);\n  ", " {\n    border-radius: var(--space-clear);\n    background-color: white;\n    &:first-child {\n      ", "\n    }\n\n    &:last-child {\n      border-bottom-left-radius: var(--space-xs);\n      border-bottom-right-radius: var(--space-xs);\n    }\n  }\n"], ["\n  border-top: 1.125rem\n    ", ";\n  border-radius: var(--space-xs) var(--space-xs) var(--space-clear) var(--space-clear);\n  margin: var(--space-lg) var(--space-clear);\n  filter: var(--filter-1dp);\n  ", " {\n    border-radius: var(--space-clear);\n    background-color: white;\n    &:first-child {\n      ", "\n    }\n\n    &:last-child {\n      border-bottom-left-radius: var(--space-xs);\n      border-bottom-right-radius: var(--space-xs);\n    }\n  }\n"])), function (_a) {
    var variant = _a.variant;
    return variant !== undefined
        ? variant === "primary"
            ? " solid var(--color-primary-main)"
            : " solid var(--color-text-highlightlight)"
        : "none";
}, exports.Container, function (_a) {
    var variant = _a.variant;
    return variant === undefined
        ? (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n              border-top-left-radius: var(--space-xs);\n              border-top-right-radius: var(--space-xs);\n            "], ["\n              border-top-left-radius: var(--space-xs);\n              border-top-right-radius: var(--space-xs);\n            "]))) : "none";
});
exports.List = styled_components_1.default.ul(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  list-style: none;\n  padding: var(--space-md) var(--space-clear) var(--space-md);\n  margin: var(--space-clear);\n  color: var(--color-neutral-emphasis-high);\n\n  ", " div:not(:last-child) & {\n    border-bottom: 1px solid;\n  }\n  ", " div:not(:last-child) & {\n    border-bottom: 1px solid #ededf2;\n  }\n"], ["\n  list-style: none;\n  padding: var(--space-md) var(--space-clear) var(--space-md);\n  margin: var(--space-clear);\n  color: var(--color-neutral-emphasis-high);\n\n  ", " div:not(:last-child) & {\n    border-bottom: 1px solid;\n  }\n  ", " div:not(:last-child) & {\n    border-bottom: 1px solid #ededf2;\n  }\n"])), exports.SummaryGroupV1, exports.SummaryGroupV2);
exports.Item = styled_components_1.default.li(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  &:not(:last-child) {\n    padding-bottom: var(--space-md);\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  &:not(:last-child) {\n    padding-bottom: var(--space-md);\n  }\n"])));
var mobileIconSection = (0, design_system_1.forMobiles)((0, styled_components_1.css)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  left: var(--space-lg);\n  top: calc(2.75rem - 9px);\n\n  &,\n  &:hover,\n  &:active {\n    ", ";\n  }\n"], ["\n  left: var(--space-lg);\n  top: calc(2.75rem - 9px);\n\n  &,\n  &:hover,\n  &:active {\n    ", ";\n  }\n"])), (0, utils_1.setSvgColor)((0, styled_components_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      fill: var(--color-primary-main);\n    "], ["\n      fill: var(--color-primary-main);\n    "]))))));
exports.IconSection = styled_components_1.default.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  height: var(--space-lg);\n  width: var(--space-lg);\n  border: 1px solid var(--color-primary-main);\n  border-radius: 50%;\n  background: var(--color-other-white);\n  margin-right: var(--space-sm);\n\n  &:hover {\n    ", "\n  }\n\n  &:active {\n    ", "\n  }\n\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  height: var(--space-lg);\n  width: var(--space-lg);\n  border: 1px solid var(--color-primary-main);\n  border-radius: 50%;\n  background: var(--color-other-white);\n  margin-right: var(--space-sm);\n\n  &:hover {\n    ", "\n  }\n\n  &:active {\n    ", "\n  }\n\n  ", "\n"])), (0, utils_1.setSvgColor)((0, styled_components_1.css)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      fill: var(--color-primary-main);\n    "], ["\n      fill: var(--color-primary-main);\n    "])))), (0, utils_1.setSvgColor)((0, styled_components_1.css)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      fill: var(--color-primary-dark);\n    "], ["\n      fill: var(--color-primary-dark);\n    "])))), mobileIconSection);
exports.EditSection = (0, styled_components_1.default)(exports.IconSection)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  float: right;\n"], ["\n  float: right;\n"])));
exports.Label = (0, styled_components_1.default)(design_system_1.MicroText)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  font-weight: var(--text-weight-bold);\n  padding-bottom: var(--space-xs);\n"], ["\n  font-weight: var(--text-weight-bold);\n  padding-bottom: var(--space-xs);\n"])));
exports.Content = (0, styled_components_1.default)(design_system_1.Text)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  font-size: var(--space-sm);\n  line-height: var(--space-md);\n"], ["\n  font-size: var(--space-sm);\n  line-height: var(--space-md);\n"])));
exports.PlaceholderSection = styled_components_1.default.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
