import { useEffect, useState } from 'react';

/**
 * Expiration logic to use for different UI render purposes
 *
 * @param delayInMs {number | unknown} - Delay in ms or any non-numeric for permanent logic
 * @returns {boolean}
 */
export const useExpiration = (delayInMs: number | unknown): boolean => {
  const [shouldRender, setShouldRender] = useState<boolean>(true);

  useEffect(() => {
    if (typeof delayInMs === 'number') {
      const timeoutId = setTimeout(() => {
        setShouldRender(false);
      }, delayInMs);

      return () => clearTimeout(timeoutId);
    }
  }, [delayInMs]);

  return shouldRender;
};
