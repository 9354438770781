/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Close as CloseIcon, Menu as MenuIcon } from '@material-ui/icons';
import clsx from 'clsx';

import Logo from '../../../assets/img/v3/logo-full.svg';

import { HeaderProps, MenuProps } from '../Shell.types';
import { useAuth0 } from '@auth0/auth0-react';
import { BaseRoutes } from '../../router/router.constants';
import { Link } from '@theguarantors/ui-kit-v3/design-system';
import { FeatureFlag, useFeatureFlag } from '../../../hooks/use-feature-flag';
import { useIsUserHasPermission } from '../../../hooks/use-is-user-has-permission';
import { Box } from '@theguarantors/ui-kit-v3';
import { useTgAuth } from '../../../hooks/use-tg-auth';

export const MobileMenu: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const { isTGAuthAuthenticated } = useTgAuth();
  const [isOpen, setIsOpen] = useState(false);

  const openMenu = () => setIsOpen(true);
  const closeMenu = () => setIsOpen(false);

  if (!isOpen) {
    return isAuthenticated || isTGAuthAuthenticated ? <MenuIcon onClick={openMenu} /> : null;
  }

  return (
    <div className="mobileMenu--container">
      <Menu isMobile closeMobileMenu={closeMenu} />
    </div>
  );
};

export const Menu: React.FC<MenuProps> = ({ isMobile, closeMobileMenu }) => {
  const { isAuthenticated, logout } = useAuth0();
  const { isTGAuthAuthenticated, tgLogout } = useTgAuth();
  const [propertiesFlag] = useFeatureFlag(FeatureFlag.PROPERTIES_FLAG);
  const isPropertiesFlagEnabled = propertiesFlag === 'on';
  const showProperties = useIsUserHasPermission('properties');

  const logUserOut = () => {
    if (isTGAuthAuthenticated) {
      return tgLogout();
    }

    return logout({ returnTo: window.location.origin + BaseRoutes.LOGOUT });
  };

  return (
    <nav className="shell__header__nav">
      {isMobile && (
        <a href="#" className="shell__header__close" onClick={closeMobileMenu}>
          <CloseIcon htmlColor="other.white" fontSize="large" />
        </a>
      )}
      {(isAuthenticated || isTGAuthAuthenticated) && (
        <>
          {isPropertiesFlagEnabled && showProperties && (
            <Link className="shell__header__link" to="/properties">
              Properties
            </Link>
          )}
          <Link className="shell__header__link" to="/deals">
            Deals
          </Link>
          <a href="#" className="shell__header__link" onClick={logUserOut}>
            Logout
          </a>
        </>
      )}
    </nav>
  );
};

export const Header: React.FC<HeaderProps> = ({ isDesktop }) => {
  const MenuComponent = isDesktop ? Menu : MobileMenu;

  return (
    <header
      className={clsx({
        shell__header: true,
        'shell__header--desktop': isDesktop,
      })}
    >
      <Box display="flex" margin={!isDesktop && 'auto'}>
        <img
          src={Logo}
          alt="TheGuarantors Logo"
          className={clsx({
            'shell__header__logo--desktop': isDesktop,
            'shell__header__logo--mobile': !isDesktop,
          })}
        />
      </Box>
      <MenuComponent />
    </header>
  );
};
