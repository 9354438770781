import React from 'react';
import { Message } from '@theguarantors/ui-kit-v3/decorators/withMessage/withMessage.styles';
import { withMessageOptions } from '@theguarantors/ui-kit-v3/decorators/withMessage/withMessage.types';

export const withMessageDecorator = (component: React.ReactNode, options: withMessageOptions) => {
  return (
    <>
      {component}
      <div style={{ textTransform: 'none' }}>
        <Message data-testid={options.name && `message-${options.name}`} type={options.type}>
          {options.message}
        </Message>
      </div>
    </>
  );
};
