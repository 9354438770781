"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VariantEnum = void 0;
var VariantEnum;
(function (VariantEnum) {
    VariantEnum["primary"] = "primary";
    VariantEnum["primaryV2"] = "primaryV2";
    VariantEnum["secondary"] = "secondary";
    VariantEnum["default"] = "default";
    VariantEnum["disabled"] = "disabled";
})(VariantEnum = exports.VariantEnum || (exports.VariantEnum = {}));
