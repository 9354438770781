"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spaces = void 0;
exports.spaces = {
    clear: "0",
    xxs: "0.25rem",
    xs: "0.5rem",
    sm: "1rem",
    md: "1.5rem",
    lg: "2rem",
    xl: "2.5rem",
    xxl: "3rem",
    xxxl: "4.5rem",
};
