"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloseSection = exports.Content = exports.Title = exports.Heading = exports.Container = exports.InnerContainer = exports.mobileContainer = exports.Substrate = exports.LayerContainer = void 0;
var framer_motion_1 = require("framer-motion");
var styled_components_1 = __importStar(require("styled-components"));
var design_system_1 = require("../../../design-system");
var utils_1 = require("../../../design-system/utils");
exports.LayerContainer = (0, styled_components_1.default)(framer_motion_1.motion.div).attrs({
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  background: rgb(0, 0, 0, 0.45);\n  width: 100vw;\n  height: 100vh;\n  z-index: var(--z-index-modal);\n  left: 0;\n  top: 0;\n"], ["\n  position: fixed;\n  background: rgb(0, 0, 0, 0.45);\n  width: 100vw;\n  height: 100vh;\n  z-index: var(--z-index-modal);\n  left: 0;\n  top: 0;\n"])));
exports.Substrate = (0, styled_components_1.default)(design_system_1.Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  position: relative;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
exports.mobileContainer = (0, design_system_1.forMobiles)((0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  border-radius: 0;\n  padding: 0;\n"], ["\n  width: 100%;\n  height: 100%;\n  border-radius: 0;\n  padding: 0;\n"]))));
exports.InnerContainer = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  padding: var(--space-lg);\n  width: 100%;\n  height: 100%;\n"], ["\n  position: relative;\n  padding: var(--space-lg);\n  width: 100%;\n  height: 100%;\n"])));
exports.Container = (0, styled_components_1.default)(framer_motion_1.motion.div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  width: ", ";\n  height: ", ";\n  border-radius: ", ";\n  background: white;\n  overflow: hidden;\n  display: ", ";\n  flex-direction: column;\n  align-items: center;\n\n  ", ";\n"], ["\n  position: absolute;\n  width: ", ";\n  height: ", ";\n  border-radius: ", ";\n  background: white;\n  overflow: hidden;\n  display: ", ";\n  flex-direction: column;\n  align-items: center;\n\n  ", ";\n"])), function (_a) {
    var containerWidth = _a.containerWidth;
    return (containerWidth ? containerWidth : "60%");
}, function (_a) {
    var containerHeight = _a.containerHeight;
    return containerHeight;
}, function (_a) {
    var containerBorderRadius = _a.containerBorderRadius;
    return containerBorderRadius;
}, function (_a) {
    var isInfoModal = _a.isInfoModal;
    return (isInfoModal ? "flex" : "flow-root");
}, exports.mobileContainer);
var mobileHeading = (0, design_system_1.forMobiles)((0, styled_components_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: var(--color-primary-main);\n  color: var(--color-other-white);\n  padding-left: 72px;\n  padding-right: 24px;\n  height: 5.5rem;\n  align-items: center;\n  display: flex;\n"], ["\n  background: var(--color-primary-main);\n  color: var(--color-other-white);\n  padding-left: 72px;\n  padding-right: 24px;\n  height: 5.5rem;\n  align-items: center;\n  display: flex;\n"]))));
exports.Heading = (0, styled_components_1.default)(design_system_1.Box)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), mobileHeading);
exports.Title = (0, styled_components_1.default)(design_system_1.Header3)(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
var mobileContent = (0, design_system_1.forMobiles)((0, styled_components_1.css)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding-left: var(--space-md);\n  padding-right: var(--space-md);\n  height: calc(100% - var(--space-md) - 5.5rem); // 100% - marginTop - titleHeight\n"], ["\n  padding-left: var(--space-md);\n  padding-right: var(--space-md);\n  height: calc(100% - var(--space-md) - 5.5rem); // 100% - marginTop - titleHeight\n"]))));
exports.Content = (0, styled_components_1.default)(design_system_1.Box)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-top: var(--space-md);\n  height: calc(100% - var(--space-md) - var(--space-md)); // 100% - marginTop - titleHeight\n  overflow: ", ";\n  display: ", ";\n  flex-direction: column;\n  align-items: ", ";\n\n  ", ";\n"], ["\n  margin-top: var(--space-md);\n  height: calc(100% - var(--space-md) - var(--space-md)); // 100% - marginTop - titleHeight\n  overflow: ", ";\n  display: ", ";\n  flex-direction: column;\n  align-items: ", ";\n\n  ", ";\n"])), function (_a) {
    var scrollable = _a.scrollable;
    return (scrollable ? "auto" : "hidden");
}, function (_a) {
    var isInfoModal = _a.isInfoModal;
    return (isInfoModal ? "flex" : "flow-root");
}, function (_a) {
    var isInfoModal = _a.isInfoModal;
    return (isInfoModal ? "center" : "flex-start");
}, mobileContent);
var mobileCloseSection = (0, design_system_1.forMobiles)((0, styled_components_1.css)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  left: 32px;\n  top: calc(2.75rem - 9px);\n\n  &,\n  &:hover,\n  &:active {\n    ", ";\n  }\n"], ["\n  left: 32px;\n  top: calc(2.75rem - 9px);\n\n  &,\n  &:hover,\n  &:active {\n    ", ";\n  }\n"])), (0, utils_1.setSvgColor)((0, styled_components_1.css)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      fill: var(--color-other-white);\n    "], ["\n      fill: var(--color-other-white);\n    "]))))));
exports.CloseSection = (0, styled_components_1.default)(design_system_1.Box)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: absolute;\n  top: var(--space-md);\n  right: var(--space-md);\n  cursor: pointer;\n  width: 1rem;\n  height: 1rem;\n\n  &:hover {\n    ", "\n  }\n\n  &:active {\n    ", "\n  }\n\n  ", "\n"], ["\n  position: absolute;\n  top: var(--space-md);\n  right: var(--space-md);\n  cursor: pointer;\n  width: 1rem;\n  height: 1rem;\n\n  &:hover {\n    ", "\n  }\n\n  &:active {\n    ", "\n  }\n\n  ", "\n"])), (0, utils_1.setSvgColor)((0, styled_components_1.css)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      fill: var(--color-primary-main);\n    "], ["\n      fill: var(--color-primary-main);\n    "])))), (0, utils_1.setSvgColor)((0, styled_components_1.css)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      fill: var(--color-decorative-blue);\n    "], ["\n      fill: var(--color-decorative-blue);\n    "])))), function (_a) {
    var isInfoModal = _a.isInfoModal;
    return !isInfoModal && mobileCloseSection;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
