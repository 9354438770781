"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonGroup = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var Button_styles_1 = require("../Button/Button.styles");
var borderColor = function () { return "white"; };
exports.ButtonGroup = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n\n  ", " {\n    border-radius: 0;\n    box-shadow: none;\n    border-left-width: 0.5px;\n    border-right-width: 0.5px;\n    ", ";\n\n    svg {\n      margin-right: var(--space-clear);\n      border: var(--space-clear);\n      border-radius: var(--space-clear);\n      width: 0.75rem;\n    }\n\n    &:first-child {\n      border-top-left-radius: var(--space-xxs);\n      border-bottom-left-radius: var(--space-xxs);\n      ", ";\n    }\n\n    &:last-child {\n      border-top-right-radius: var(--space-xxs);\n      border-bottom-right-radius: var(--space-xxs);\n      ", "\n    }\n  ;\n  }\n}\n"], ["\n  display: flex;\n  position: relative;\n\n  ", " {\n    border-radius: 0;\n    box-shadow: none;\n    border-left-width: 0.5px;\n    border-right-width: 0.5px;\n    ", ";\n\n    svg {\n      margin-right: var(--space-clear);\n      border: var(--space-clear);\n      border-radius: var(--space-clear);\n      width: 0.75rem;\n    }\n\n    &:first-child {\n      border-top-left-radius: var(--space-xxs);\n      border-bottom-left-radius: var(--space-xxs);\n      ", ";\n    }\n\n    &:last-child {\n      border-top-right-radius: var(--space-xxs);\n      border-bottom-right-radius: var(--space-xxs);\n      ", "\n    }\n  ;\n  }\n}\n"])), Button_styles_1.ButtonContainer, function (_a) {
    var _b = _a.bVariant, bVariant = _b === void 0 ? "contained" : _b;
    return bVariant === "contained" && (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        border-color: ", ";\n        border-style: solid;\n      "], ["\n        border-color: ", ";\n        border-style: solid;\n      "])), borderColor());
}, function (_a) {
    var _b = _a.bVariant, bVariant = _b === void 0 ? "contained" : _b;
    return (bVariant !== "outlined" ? "border-left: none;" : "border-left-width: 1px");
}, function (_a) {
    var _b = _a.bVariant, bVariant = _b === void 0 ? "contained" : _b;
    return (bVariant !== "outlined" ? "border-right: none;" : "border-right-width: 1px");
});
var templateObject_1, templateObject_2;
