import gql from 'graphql-tag';

const EvaluateApplicationTypeDef = gql`
  extend type Mutation {
    evaluateApplication(applicationId: Int!): EvaluateApplicationResponse
  }

  type EvaluateApplicationResponse {
    applicationId: Int
    reportStatus: String
    approvalStatus: String
    error: EvaluationError
  }

  type EvaluationError {
    message: String
  }
`;

export default [EvaluateApplicationTypeDef];
