"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOCUMENT_TYPES = void 0;
var DOCUMENT_TYPES;
(function (DOCUMENT_TYPES) {
    DOCUMENT_TYPES["GAI"] = "GAI";
    DOCUMENT_TYPES["TC"] = "T&C";
    DOCUMENT_TYPES["GOVERNMENT_ID"] = "GOVERNMENT_ID";
    DOCUMENT_TYPES["CREDITCHECK"] = "CREDITCHECK";
    DOCUMENT_TYPES["BOND"] = "BOND";
})(DOCUMENT_TYPES = exports.DOCUMENT_TYPES || (exports.DOCUMENT_TYPES = {}));
