"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfflineBoltIcon = void 0;
var react_1 = __importStar(require("react"));
var design_system_1 = require("../../design-system");
var SvgComponent = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "var(--color-primary-main)" : _b, title = _a.title, _c = _a.titleId, titleId = _c === void 0 ? "OfflineBolt" : _c, props = __rest(_a, ["color", "title", "titleId"]);
    return (react_1.default.createElement(design_system_1.Box, __assign({ as: "svg", viewBox: "0 0 20 20" }, props),
        react_1.default.createElement("title", { id: titleId }, title || titleId),
        react_1.default.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.0200195 9.99953C0.0200195 4.48953 4.49002 0.0195312 10 0.0195312C15.51 0.0195312 19.98 4.48953 19.98 9.99953C19.98 15.5095 15.51 19.9795 10 19.9795C4.49002 19.9795 0.0200195 15.5095 0.0200195 9.99953ZM9.48002 11.7395V17.9995L14.35 8.25953H11V1.99953L6.00002 11.7395H9.48002Z", fill: color })));
};
exports.OfflineBoltIcon = (0, react_1.memo)(SvgComponent);
exports.OfflineBoltIcon.displayName = "OfflineBoltIcon";
