import React from 'react';
import { DealsList } from '../../components';
import { Header } from '@theguarantors/ui-kit-v3/design-system';

export const Deals: React.FC = () => {
  return (
    <>
      <Header align="left" pb="md" color="neutral.main">
        Deals
      </Header>
      <DealsList />
    </>
  );
};
