import { onError } from '@apollo/client/link/error';
import { BffErrorCodes } from '../../bff-error-codes';
import { history } from '../client';
import { BaseRoutes } from '../../../components/router/router.constants';

/**
 * This Apollo Link allows you to react to specific BFF errors without
 * having to repeat the same error handling logic for every query/mutation
 *
 * e.g. Internal Server Errors -> always go to the technical-difficulties page
 */
export const globalErrorLink = onError(({ operation, graphQLErrors, networkError, forward }) => {
  const { FORBIDDEN, INTERNAL_SERVER_ERROR } = BffErrorCodes;

  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      const errorCode: BffErrorCodes = extensions?.code;

      switch (errorCode) {
        case FORBIDDEN:
          // TODO: When Forbidden (403) page design is completed, redirect there instead
          history.replace(BaseRoutes.DEALS);
          break;

        case INTERNAL_SERVER_ERROR:
          history.push(BaseRoutes.TECHNICAL_DIFFICULTIES);
          break;

        default:
          break;
      }
    });
  }

  if (networkError) {
    // TODO: this is where we would add a retry mechanism if we don't use apollo/link/retry
    console.error('[Network error]: ', networkError);
    forward(operation);
  }
});
