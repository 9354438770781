import gql from 'graphql-tag';

const ContactTypDef = gql`
  type ContactEntity {
    firstName: String!
    lastName: String!
    email: String!
    phoneNumber: String
  }
`;

export default [ContactTypDef];
