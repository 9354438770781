import { ColorKey } from '@theguarantors/ui-kit-v3/design-system/colors';
import { IconKey } from '@theguarantors/ui-kit-v3/icons';
import { APPLICATION_STATUSES } from '@theguarantors/tg-modern-business';

interface Result {
  name: IconKey;
  color?: ColorKey | string;
}

const mapper: { [key in APPLICATION_STATUSES]: Result } = {
  [APPLICATION_STATUSES.ABORTED]: {
    name: 'block',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.CANCELED]: {
    name: 'block',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.AMENDED]: {
    name: 'applicationSigned',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.COMPLETED]: {
    name: 'checkCircle',
    color: 'decorative.green',
  },
  [APPLICATION_STATUSES.CREATED]: {
    name: 'today',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.DOCUMENTS_SIGNED]: {
    name: 'signed',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.INVITATION_OPENED]: {
    name: 'applicationSigned',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.INVITATION_SENT]: {
    name: 'email',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.PAID]: {
    name: 'checkCircle',
    color: 'decorative.green',
  },
  [APPLICATION_STATUSES.PROCESSING_PAYMENT]: {
    name: 'monetizationOn',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.QUALIFIED]: {
    name: 'star',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.QUESTIONNAIRE_STARTED]: {
    name: 'applicationSigned',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.QUESTIONNAIRE_COMPLETED]: {
    name: 'applicationSigned',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.PENDING_LEASE_DETAILS]: {
    name: 'watchLater',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.APPROVED]: {
    name: 'check',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.QUOTE_READY]: {
    name: 'playlistAddCheck',
    color: 'currentColor',
  },
  [APPLICATION_STATUSES.CLOSED_LOST]: {
    name: 'denied',
    color: 'currentColor',
  },
};

export const mapAppStatusToIconProps = (status: APPLICATION_STATUSES): Result => {
  return mapper[status] ?? { name: 'error', color: 'currentColor' };
};
