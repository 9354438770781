"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponsiveRender = void 0;
var react_1 = require("react");
var utils_1 = require("../../design-system/utils");
var hooks_1 = require("../../hooks");
var ResponsiveRender = function (_a) {
    var component = _a.component, props = __rest(_a, ["component"]);
    var breakpointState = (0, hooks_1.useBreakpointState)();
    return (0, react_1.cloneElement)((0, utils_1.getResponsiveAttrValue)(component, breakpointState), props);
};
exports.ResponsiveRender = ResponsiveRender;
