"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentOptionContainer = exports.CustomText = exports.CustomHeader3 = exports.StrikeStyle = exports.CustomDownloadIcon = exports.CustomIcon = exports.BackArrowIcon = exports.BoxBorder = exports.tabletsAndDesktopContainer = exports.mobileContainer = exports.StyledHeroDecoration = exports.Hero = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var HeroDecoration_1 = require("../../assets/backgrounds/HeroDecoration");
var design_system_1 = require("../../design-system");
var icons_1 = require("../../icons");
var commonAddressStyle = "\n  white-space: pre-line;\n  text-overflow: ellipsis;\n";
exports.Hero = (0, styled_components_1.default)(design_system_1.Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  background-color: var(--color-neutral-main);\n  border-radius: var(--space-xs);\n"], ["\n  position: relative;\n  background-color: var(--color-neutral-main);\n  border-radius: var(--space-xs);\n"])));
exports.StyledHeroDecoration = (0, styled_components_1.default)(HeroDecoration_1.HeroDecoration)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  top: 0;\n  right: 0;\n  position: absolute;\n  border-radius: var(--space-xxs);\n"], ["\n  top: 0;\n  right: 0;\n  position: absolute;\n  border-radius: var(--space-xxs);\n"])));
exports.mobileContainer = (0, design_system_1.forMobilesAndTablets)((0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 1px dashed var(--color-table-disabledBackground);\n"], ["\n  border-bottom: 1px dashed var(--color-table-disabledBackground);\n"]))));
exports.tabletsAndDesktopContainer = (0, design_system_1.forTabletsAndDesktop)((0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-right: 1px dashed var(--color-table-disabledBackground);\n"], ["\n  border-right: 1px dashed var(--color-table-disabledBackground);\n"]))));
exports.BoxBorder = (0, styled_components_1.default)(design_system_1.Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: var(--space-lg);\n  ", "\n  ", "\n"], ["\n  padding: var(--space-lg);\n  ", "\n  ", "\n"])), exports.mobileContainer, exports.tabletsAndDesktopContainer);
exports.BackArrowIcon = (0, styled_components_1.default)(icons_1.Icon)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  left: -3.75rem;\n  top: -0.75rem;\n"], ["\n  position: absolute;\n  left: -3.75rem;\n  top: -0.75rem;\n"])));
exports.CustomIcon = (0, styled_components_1.default)(icons_1.Icon)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background-color: var(--color-other-white);\n  border-radius: 100%;\n  border: 1px solid var(--color-primary-main);\n  padding: var(--space-xs);\n  cursor: pointer;\n"], ["\n  background-color: var(--color-other-white);\n  border-radius: 100%;\n  border: 1px solid var(--color-primary-main);\n  padding: var(--space-xs);\n  cursor: pointer;\n"])));
exports.CustomDownloadIcon = (0, styled_components_1.default)(icons_1.Icon)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background-color: var(--color-primary-main);\n  border-radius: 100%;\n  border: 1px solid var(--color-primary-main);\n  padding: var(--space-xs);\n"], ["\n  background-color: var(--color-primary-main);\n  border-radius: 100%;\n  border: 1px solid var(--color-primary-main);\n  padding: var(--space-xs);\n"])));
exports.StrikeStyle = (0, styled_components_1.default)(design_system_1.Text)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  &:before {\n    position: absolute;\n    content: \" \";\n    left: 0;\n    top: 40%;\n    right: 0;\n    width: 100%;\n    border-top: 1px solid var(--color-decorative-green);\n    transform: rotate(315deg);\n  }\n"], ["\n  position: relative;\n  &:before {\n    position: absolute;\n    content: \" \";\n    left: 0;\n    top: 40%;\n    right: 0;\n    width: 100%;\n    border-top: 1px solid var(--color-decorative-green);\n    transform: rotate(315deg);\n  }\n"])));
exports.CustomHeader3 = (0, styled_components_1.default)(design_system_1.Header3)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), commonAddressStyle);
exports.CustomText = (0, styled_components_1.default)(design_system_1.Text)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), commonAddressStyle);
exports.PaymentOptionContainer = (0, styled_components_1.default)(exports.Hero)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  padding: var(--space-lg);\n  cursor: pointer;\n"], ["\n  padding: var(--space-lg);\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
