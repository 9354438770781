import { ApplicationEntity } from '../../../api/api-types';
import { useMemo } from 'react';
import { capitalize } from '../utils/capitalize';

interface BannerDataItem {
  key: string;
  value: string | number;
}

export const useBannerDetails = (application: ApplicationEntity | undefined): BannerDataItem[] => {
  return useMemo(
    () =>
      application
        ? [
            {
              key: 'Deal ID',
              value: application?.deal?.id,
            },
            {
              key: 'App ID',
              value: application?.id,
            },
            {
              key: 'Source',
              value: application?.invitation?.type ? `Invite (${capitalize(application.invitation.type)})` : 'Manual',
            },
          ]
        : [],
    [application],
  );
};
