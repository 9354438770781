"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScript = exports.ScriptStatus = void 0;
var react_1 = require("react");
// Allows us to easily load external libraries only when they are needed.
// Adapted from: https://usehooks.com/useScript/ (not provided as a library)
// Modified slightly for TS
var ScriptStatus;
(function (ScriptStatus) {
    ScriptStatus["LOADING"] = "loading";
    ScriptStatus["IDLE"] = "idle";
    ScriptStatus["READY"] = "ready";
    ScriptStatus["ERROR"] = "error";
})(ScriptStatus = exports.ScriptStatus || (exports.ScriptStatus = {}));
var useScript = function (src) {
    // Keep track of script status ("idle", "loading", "ready", "error")
    var _a = (0, react_1.useState)(src ? ScriptStatus.LOADING : ScriptStatus.IDLE), status = _a[0], setStatus = _a[1];
    (0, react_1.useEffect)(function () {
        // Allow falsy src value if waiting on other data needed for
        // constructing the script URL passed to this hook.
        if (!src || !document || !window) {
            setStatus(ScriptStatus.IDLE);
            return;
        }
        // Fetch existing script element by src
        // It may have been added by another instance of this hook
        var script = getScript(src, setStatus);
        // Script event handler to update status in state
        // Note: Even if the script already exists we still need to add
        // event handlers to update the state for *this* hook instance.
        var setStateFromEvent = function (event) {
            setStatus(event.type === "load" ? ScriptStatus.READY : ScriptStatus.ERROR);
        };
        // Add event listeners
        script.addEventListener("load", setStateFromEvent);
        script.addEventListener("error", setStateFromEvent);
        // Remove event listeners on cleanup
        return function () {
            if (script) {
                script.removeEventListener("load", setStateFromEvent);
                script.removeEventListener("error", setStateFromEvent);
            }
        };
    }, [src] // Only re-run effect if script src changes
    );
    return status;
};
exports.useScript = useScript;
var getScript = function (src, setStatus) {
    var _a;
    // Fetch existing script element by src
    // It may have been added by another instance of this hook
    var existingScript = document === null || document === void 0 ? void 0 : document.querySelector("script[src=\"".concat(src, "\"]"));
    if (existingScript) {
        // Grab existing script status from attribute and set to state.
        var status_1 = existingScript.getAttribute("data-status");
        setStatus(status_1 && Object.values(ScriptStatus).includes(status_1) ? status_1 : ScriptStatus.LOADING);
        return existingScript;
    }
    // Create script
    var createdScript = document === null || document === void 0 ? void 0 : document.createElement("script");
    createdScript.src = src;
    createdScript.async = true;
    createdScript.setAttribute("data-status", ScriptStatus.LOADING);
    // Add script to document body
    (_a = document === null || document === void 0 ? void 0 : document.body) === null || _a === void 0 ? void 0 : _a.appendChild(createdScript);
    // Store status in attribute on script
    // This can be read by other instances of this hook
    var setAttributeFromEvent = function (event) {
        createdScript.setAttribute("data-status", event.type === "load" ? ScriptStatus.READY : ScriptStatus.ERROR);
    };
    createdScript.addEventListener("load", setAttributeFromEvent);
    createdScript.addEventListener("error", setAttributeFromEvent);
    return createdScript;
};
