import gql from 'graphql-tag';
import Applications from './Applications';
import Questionnaire from './Questionnaire';
import Report from './Report';
import PolicyTypeDef from './Policy';
import PaymentTypeDef from './Payment';
import Document from './Documents';
import Lease from './Lease';
import ApplicationSettings from './ApplicationSettings';
import Deal from './Deal';

export const typeDefs = [
  gql`
    type Query
    type Mutation
  `,
  ...Applications,
  ...Questionnaire,
  ...PolicyTypeDef,
  ...Report,
  ...PaymentTypeDef,
  ...Document,
  ...Lease,
  ...ApplicationSettings,
  ...Deal,
];
