"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInputBase = void 0;
var react_1 = __importStar(require("react"));
var utils_1 = require("../../utils");
var useInputBase = function (_a) {
    var label = _a.label, placeholder = _a.placeholder, setInputRef = _a.setInputRef, onFocus = _a.onFocus, onBlur = _a.onBlur, onChange = _a.onChange, className = _a.className, _b = _a.variant, variant = _b === void 0 ? "default" : _b, icon = _a.icon, invalid = _a.invalid, value = _a.value, _touched = _a.touched, dirty = _a.dirty, disabled = _a.disabled, readOnly = _a.readOnly, _handleOnIconClick = _a.handleOnIconClick, isIconVisible = _a.isIconVisible, name = _a.name, required = _a.required;
    var labelAndPlaceholderPresented = !!(label && placeholder);
    var _c = (0, react_1.useState)(Boolean(value) || labelAndPlaceholderPresented), touched = _c[0], setTouched = _c[1];
    var _d = (0, react_1.useState)(false), active = _d[0], setActive = _d[1];
    var input = (0, react_1.useRef)(null);
    var hoverTimeout = (0, react_1.useRef)();
    var touchedState = (0, react_1.useMemo)(function () { return !!value || (_touched && dirty) || (touched && !!value) || labelAndPlaceholderPresented; }, [_touched, dirty, labelAndPlaceholderPresented, touched, value]);
    (0, react_1.useLayoutEffect)(function () {
        setInputRef && setInputRef(input.current);
    }, [setInputRef]);
    /*
    Handlers
    */
    var handleOnFocus = (0, react_1.useCallback)(function (e) {
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
        setTouched(true);
        setActive(true);
    }, [onFocus]);
    var handleOnBlur = (0, react_1.useCallback)(function (e) {
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
        setActive(false);
    }, [onBlur]);
    var handleOnChange = (0, react_1.useCallback)(function (e) {
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
        setTouched(true);
    }, [onChange]);
    var handleOnIconClick = (0, react_1.useCallback)(function () {
        _handleOnIconClick === null || _handleOnIconClick === void 0 ? void 0 : _handleOnIconClick();
    }, [_handleOnIconClick]);
    var handleOnLabelClick = (0, react_1.useCallback)(function () {
        var _a;
        (_a = input.current) === null || _a === void 0 ? void 0 : _a.focus();
        handleOnIconClick === null || handleOnIconClick === void 0 ? void 0 : handleOnIconClick();
    }, [handleOnIconClick]);
    var handleMouseOver = (0, react_1.useCallback)(function () {
        clearTimeout(hoverTimeout.current);
        setActive(true);
    }, []);
    var handleMouseOut = (0, react_1.useCallback)(function () {
        clearTimeout(hoverTimeout.current);
        hoverTimeout.current = window === null || window === void 0 ? void 0 : window.setTimeout(function () {
            setActive(false);
        }, 500);
    }, []);
    /*
    Styles
    */
    var isDefault = react_1.default.useMemo(function () { return variant === "default"; }, [variant]);
    var isSimple = react_1.default.useMemo(function () { return variant === "simple"; }, [variant]);
    var cls = react_1.default.useMemo(function () {
        return (0, utils_1.classnames)(className, {
            touched: touchedState && isDefault,
            invalid: !!invalid && isDefault,
            noStyles: Boolean(disabled || readOnly) || isSimple,
            hasValue: Boolean(value),
            hasIcon: Boolean(icon),
        });
    }, [className, touchedState, isDefault, isSimple, disabled, readOnly, invalid, value, icon]);
    return (0, react_1.useMemo)(function () { return ({
        cls: cls,
        isSimple: isSimple,
        isDefault: isDefault,
        handleOnLabelClick: handleOnLabelClick,
        handleOnFocus: handleOnFocus,
        handleOnBlur: handleOnBlur,
        handleMouseOut: handleMouseOut,
        handleMouseOver: handleMouseOver,
        active: active,
        touched: touchedState,
        input: input,
        invalid: !!invalid,
        handleOnIconClick: handleOnIconClick,
        isIconVisible: !!isIconVisible,
        labelAndPlaceholderPresented: labelAndPlaceholderPresented,
        handleOnChange: handleOnChange,
        icon: icon,
        label: label,
        placeholder: placeholder,
        value: value,
        name: name,
        disabled: !!disabled,
        required: !!required,
        readOnly: !!readOnly,
    }); }, [
        active,
        cls,
        disabled,
        handleMouseOut,
        handleMouseOver,
        handleOnBlur,
        handleOnChange,
        handleOnFocus,
        handleOnIconClick,
        handleOnLabelClick,
        icon,
        invalid,
        isDefault,
        isIconVisible,
        isSimple,
        label,
        labelAndPlaceholderPresented,
        name,
        placeholder,
        readOnly,
        required,
        touchedState,
        value,
    ]);
};
exports.useInputBase = useInputBase;
