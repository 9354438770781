import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import { useContext } from 'react';
import { FeatureFlag } from './feature-flag';

export default function useFeatureFlag(
  flagName: FeatureFlag,
  attributes?: SplitIO.Attributes,
): [string, { isReady: boolean; isEnabled: boolean }] {
  const { isReady } = useContext(SplitContext);
  // when new feature flags added, remove type conversion
  const treatments = useTreatments([flagName], attributes);
  const featureFlag = treatments[flagName].treatment;
  const isEnabled = featureFlag === 'on';

  return [featureFlag, { isReady, isEnabled }];
}
