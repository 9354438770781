import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BaseRoutes } from '../components/router/router.constants';
import { Loading } from '../components/loading';
import { useAuth0 } from '@auth0/auth0-react';

const ERROR_DESCRIPTION = 'error_description';

export const Auth0CallbackHandler = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has('error') && searchParams.has(ERROR_DESCRIPTION)) {
      history.replace(BaseRoutes.LOGIN, { errorDescription: searchParams.get(ERROR_DESCRIPTION) });
    }
  }, [search, history]);

  useEffect(() => {
    if (isAuthenticated && user) {
      // @ts-expect-error
      window.analytics.identify(user.sub, {
        email: user.email,
        name: user.name,
      });
    }
  }, [isAuthenticated, user]);

  if (isAuthenticated) {
    history.replace(BaseRoutes.DEALS);
  }

  return <Loading />;
};
