import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { PersonPinRounded } from '@material-ui/icons';
import { OneColumnLayout } from '../../components';
import './technical-difficulties.scss';

interface TechnicalDifficultiesPageProps {
  readonly leaseError?: boolean;
}

export const TechnicalDifficultiesPage: React.FC<TechnicalDifficultiesPageProps> = ({ leaseError }) => {
  return (
    <OneColumnLayout>
      <Grid container justifyContent="center" alignItems="center" spacing={4} className="technical-difficulties">
        <Grid item>
          <Typography variant="h3" className="technical-difficulties__title">
            Hi There
            <PersonPinRounded className="technical-difficulties__title__icon" />
          </Typography>
          {leaseError ? (
            <Typography variant="h2">Thank you for your interest in replacing your security deposit</Typography>
          ) : (
            <Typography variant="h2">
              We are encountering some difficulties and unable to proceed at this time
            </Typography>
          )}
          {leaseError ? (
            <Typography variant="body1" className="technical-difficulties__body">
              Our records indicate that your lease has already started. If you think this is not correct, please contact
              us at <a href="mailto:success@theguarantors.com">success@theguarantors.com</a>
            </Typography>
          ) : (
            <Typography variant="body1" className="technical-difficulties__body">
              We're sorry! Due to technical difficulties, we're unable to proceed because we are having issues verifying
              your credit history. Please retry in a couple of hours. If the problem persists, please contact our{' '}
              <a href="mailto:success@theguarantors.com">support team.</a>
            </Typography>
          )}
        </Grid>
      </Grid>
    </OneColumnLayout>
  );
};
