"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonWithDetails = void 0;
var react_1 = __importStar(require("react"));
var Button_utils_1 = require("../Button/Button.utils");
var icons_1 = require("../../icons");
var Button_1 = require("../Button");
var ButtonWithDetails_styles_1 = require("./ButtonWithDetails.styles");
var ButtonWithDetails = function (_a) {
    var children = _a.children, size = _a.size, details = _a.details, iconName = _a.iconName, _b = _a.bType, bType = _b === void 0 ? "primary" : _b, _c = _a.bVariant, bVariant = _c === void 0 ? "contained" : _c, props = __rest(_a, ["children", "size", "details", "iconName", "bType", "bVariant"]);
    var selectedColor = (0, react_1.useMemo)(function () { return (0, Button_utils_1.getColorByVariant)(bVariant, bType); }, [bVariant, bType]);
    return (react_1.default.createElement(Button_1.Button, { size: size, bType: bType, bVariant: bVariant, "data-testid": props["data-testid"] },
        react_1.default.createElement(ButtonWithDetails_styles_1.ChildrenWrapper, null,
            react_1.default.createElement(ButtonWithDetails_styles_1.ContentWrapper, null,
                react_1.default.createElement(ButtonWithDetails_styles_1.Title, null, children),
                react_1.default.createElement(ButtonWithDetails_styles_1.Details, null, details)),
            react_1.default.createElement(icons_1.Icon, { name: iconName, color: props.disabled ? "text.disabled" : selectedColor }))));
};
exports.ButtonWithDetails = ButtonWithDetails;
