"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLockBodyScroll = void 0;
var react_1 = require("react");
function useLockBodyScroll(isOpen) {
    (0, react_1.useLayoutEffect)(function () {
        var originalStyle = window.getComputedStyle(document.body).overflow;
        if (isOpen) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = originalStyle;
        }
        return function () { return (document.body.style.overflow = originalStyle); };
    }, [isOpen]);
}
exports.useLockBodyScroll = useLockBodyScroll;
