"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Accordion = void 0;
var react_1 = __importStar(require("react"));
var design_system_1 = require("../../design-system");
var icons_1 = require("../../icons");
var Accordion_hooks_1 = require("./Accordion.hooks");
var Accordion_styles_1 = require("./Accordion.styles");
var Accordion = function (_a) {
    var icon = _a.icon, accordionKey = _a.accordionKey, title = _a.title, children = _a.children, props = __rest(_a, ["icon", "accordionKey", "title", "children"]);
    var _b = (0, Accordion_hooks_1.useAccordionLogic)(props.isActive, props.setActiveKey), isActive = _b[0], setIsActive = _b[1];
    var updateStatus = (0, react_1.useCallback)(function () { return setIsActive(accordionKey); }, [setIsActive, accordionKey]);
    return (react_1.default.createElement(Accordion_styles_1.AccordionContainer, null,
        react_1.default.createElement(Accordion_styles_1.AccordionTitleWrapper, { onClick: updateStatus, "data-testid": props["data-testid"], "data-testelement": "accordion" },
            react_1.default.createElement(design_system_1.Box, { display: "flex", justifyContent: "center", alignItems: "center" },
                icon && (react_1.default.createElement(design_system_1.Box, { display: "flex" },
                    react_1.default.createElement(icons_1.Icon, { mr: "sm", name: icon }))),
                react_1.default.createElement(design_system_1.Header4, null, title)),
            react_1.default.createElement(design_system_1.Box, { display: "flex" },
                react_1.default.createElement(Accordion_styles_1.StyledIcon, { name: isActive ? "expandLess" : "expandMore" }))),
        isActive && (react_1.default.createElement(design_system_1.Box, { px: "sm", py: "xs", "data-testid": "content-".concat(props["data-testid"]) }, children))));
};
exports.Accordion = Accordion;
