"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buttonIconMarginsMap = exports.buttonIconSizesMap = exports.RoundButtonContainer = exports.ButtonContainer = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var styled_system_1 = require("styled-system");
var design_system_1 = require("../../design-system");
var reusableStyles = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-style: solid;\n  border-width: 1px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  position: relative;\n  font-family: var(--font-family-default);\n  line-height: var(--line-height-body);\n  font-weight: 700;\n\n  &:focus {\n    outline: none;\n  }\n\n  &::after {\n    content: \" \";\n    position: absolute;\n    width: 0;\n    height: 0;\n    right: 50%;\n    bottom: 50%;\n    border-radius: 50%;\n    background: ", ";\n  }\n\n  &:disabled {\n    background: var(--color-table-disabledBackground);\n    color: var(--color-text-disabled);\n    border-color: var(--color-table-disabledBackground);\n    cursor: not-allowed;\n\n    &::after {\n      display: none !important;\n    }\n  }\n"], ["\n  border-style: solid;\n  border-width: 1px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  position: relative;\n  font-family: var(--font-family-default);\n  line-height: var(--line-height-body);\n  font-weight: 700;\n\n  &:focus {\n    outline: none;\n  }\n\n  &::after {\n    content: \" \";\n    position: absolute;\n    width: 0;\n    height: 0;\n    right: 50%;\n    bottom: 50%;\n    border-radius: 50%;\n    background: ", ";\n  }\n\n  &:disabled {\n    background: var(--color-table-disabledBackground);\n    color: var(--color-text-disabled);\n    border-color: var(--color-table-disabledBackground);\n    cursor: not-allowed;\n\n    &::after {\n      display: none !important;\n    }\n  }\n"])), function (_a) {
    var bType = _a.bType;
    return bType === "primary" ? "rgba(255, 255, 255, 0.2)" : "rgba(22, 22, 87, 0.1)";
});
var buttonAnimation = (0, styled_components_1.keyframes)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  25% {\n    width: 2rem;\n    height: 2rem;\n    right: calc(50% - 1rem);\n    bottom: calc(50% - 1rem);\n    border-radius: 50%;\n  }\n  50% {\n    width: 50%;\n    height: 100%;\n    border-radius: 25%;\n    right: 25%;\n    bottom: 0;\n  }\n  100% {\n    width: 100%;\n    height: 100%;\n    border-radius: 0;\n    right: 0;\n    bottom: 0;\n  }\n"], ["\n  25% {\n    width: 2rem;\n    height: 2rem;\n    right: calc(50% - 1rem);\n    bottom: calc(50% - 1rem);\n    border-radius: 50%;\n  }\n  50% {\n    width: 50%;\n    height: 100%;\n    border-radius: 25%;\n    right: 25%;\n    bottom: 0;\n  }\n  100% {\n    width: 100%;\n    height: 100%;\n    border-radius: 0;\n    right: 0;\n    bottom: 0;\n  }\n"])));
var buttonStyles = (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  border-radius: 4px;\n\n  ", ";\n"], ["\n  ", ";\n  border-radius: 4px;\n\n  ", ";\n"])), reusableStyles, function (_a) {
    var bType = _a.bType, size = _a.size, bVariant = _a.bVariant;
    return !((bType === null || bType === void 0 ? void 0 : bType.includes("loading")) || (bVariant === null || bVariant === void 0 ? void 0 : bVariant.includes("ghost")) || (size === null || size === void 0 ? void 0 : size.includes("social"))) && (0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      &:focus:not(:active) {\n        &::after {\n          animation: ", " var(--transition-default);\n        }\n      }\n    "], ["\n      &:focus:not(:active) {\n        &::after {\n          animation: ", " var(--transition-default);\n        }\n      }\n    "])), buttonAnimation);
});
var roundButtonStyles = (0, styled_components_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  border-radius: 50%;\n"], ["\n  ", ";\n  border-radius: 50%;\n"])), reusableStyles);
var containedVariant = (0, styled_system_1.variant)({
    prop: "bType",
    variants: {
        neutral: {
            borderColor: "neutral.main",
            backgroundColor: "neutral.main",
            color: "other.white",
            "&:hover,&:focus": {
                borderColor: "neutral.hoverBg",
                backgroundColor: "neutral.hoverBg",
            },
        },
        primary: {
            borderColor: "primary.main",
            backgroundColor: "primary.main",
            color: "other.white",
            "&:hover,&:focus": {
                borderColor: "primary.hoverBg",
                backgroundColor: "primary.hoverBg",
            },
        },
        info: {
            borderColor: "info.main",
            backgroundColor: "info.main",
            color: "other.white",
            "&:hover,&:focus": {
                borderColor: "info.hoverBg",
                backgroundColor: "info.hoverBg",
            },
        },
        success: {
            borderColor: "success.main",
            backgroundColor: "success.main",
            color: "other.white",
            "&:hover,&:focus": {
                borderColor: "success.hoverBg",
                backgroundColor: "success.hoverBg",
            },
        },
        error: {
            borderColor: "error.main",
            backgroundColor: "error.main",
            color: "other.white",
            "&:hover,&:focus": {
                borderColor: "error.hoverBg",
                backgroundColor: "error.hoverBg",
            },
        },
        warning: {
            borderColor: "warning.main",
            backgroundColor: "warning.main",
            color: "other.white",
            "&:hover,&:focus": {
                borderColor: "warning.hoverBg",
                backgroundColor: "warning.hoverBg",
            },
        },
        "neutral-loading": {
            borderColor: "neutral.hoverBg",
            backgroundColor: "neutral.hoverBg",
            color: "other.white",
        },
        "primary-loading": {
            borderColor: "primary.hoverBg",
            backgroundColor: "primary.hoverBg",
            color: "other.white",
        },
        "info-loading": {
            borderColor: "info.hoverBg",
            backgroundColor: "info.hoverBg",
            color: "other.white",
        },
        "success-loading": {
            borderColor: "success.hoverBg",
            backgroundColor: "success.hoverBg",
            color: "other.white",
        },
        "error-loading": {
            borderColor: "error.hoverBg",
            backgroundColor: "error.hoverBg",
            color: "other.white",
        },
        "warning-loading": {
            borderColor: "warning.hoverBg",
            backgroundColor: "warning.hoverBg",
            color: "warning.white",
        },
    },
});
var outlinedVariant = (0, styled_system_1.variant)({
    prop: "bType",
    variants: {
        neutral: {
            borderColor: "neutral.main",
            backgroundColor: "other.white",
            color: "neutral.main",
            "&:hover,&:focus": {
                backgroundColor: "neutral.bg",
            },
        },
        primary: {
            borderColor: "primary.main",
            backgroundColor: "other.white",
            color: "primary.main",
            "&:hover,&:focus": {
                backgroundColor: "primary.bg",
            },
        },
        info: {
            borderColor: "info.main",
            backgroundColor: "other.white",
            color: "info.main",
            "&:hover,&:focus": {
                backgroundColor: "info.bg",
            },
        },
        success: {
            borderColor: "success.main",
            backgroundColor: "other.white",
            color: "success.main",
            "&:hover,&:focus": {
                backgroundColor: "success.bg",
            },
        },
        error: {
            borderColor: "error.main",
            backgroundColor: "other.white",
            color: "error.main",
            "&:hover,&:focus": {
                backgroundColor: "error.bg",
            },
        },
        warning: {
            borderColor: "warning.main",
            backgroundColor: "other.white",
            color: "warning.main",
            "&:hover,&:focus": {
                backgroundColor: "warning.bg",
            },
        },
        "neutral-loading": {
            borderColor: "neutral.main",
            backgroundColor: "neutral.bg",
            color: "neutral.main",
        },
        "primary-loading": {
            borderColor: "primary.main",
            backgroundColor: "primary.bg",
            color: "primary.main",
        },
        "info-loading": {
            borderColor: "info.main",
            backgroundColor: "info.bg",
            color: "info.main",
        },
        "success-loading": {
            borderColor: "success.main",
            backgroundColor: "success.bg",
            color: "success.main",
        },
        "error-loading": {
            borderColor: "error.main",
            backgroundColor: "error.bg",
            color: "error.main",
        },
        "warning-loading": {
            borderColor: "warning.main",
            backgroundColor: "warning.bg",
            color: "warning.main",
        },
    },
});
var ghostVariant = (0, styled_system_1.variant)({
    prop: "bType",
    variants: {
        neutral: {
            borderWidth: "0 !important",
            backgroundColor: "other.white",
            color: "neutral.main",
            "&:hover,&:focus": {
                backgroundColor: "neutral.bg",
            },
        },
        primary: {
            borderWidth: "0 !important",
            backgroundColor: "other.white",
            color: "primary.main",
            "&:hover,&:focus": {
                backgroundColor: "primary.bg",
            },
        },
        info: {
            borderWidth: "0 !important",
            backgroundColor: "other.white",
            color: "info.main",
            "&:hover,&:focus": {
                backgroundColor: "info.bg",
            },
        },
        success: {
            borderWidth: "0 !important",
            backgroundColor: "other.white",
            color: "success.main",
            "&:hover,&:focus": {
                backgroundColor: "success.bg",
            },
        },
        error: {
            borderWidth: "0 !important",
            backgroundColor: "other.white",
            color: "error.main",
            "&:hover,&:focus": {
                backgroundColor: "error.bg",
            },
        },
        warning: {
            borderWidth: "0 !important",
            backgroundColor: "other.white",
            color: "warning.main",
            "&:hover,&:focus": {
                backgroundColor: "warning.bg",
            },
        },
        "neutral-loading": {
            borderWidth: "0 !important",
            backgroundColor: "neutral.bg",
            color: "neutral.main",
        },
        "primary-loading": {
            borderWidth: "0 !important",
            backgroundColor: "primary.bg",
            color: "primary.main",
        },
        "info-loading": {
            borderWidth: "0 !important",
            backgroundColor: "info.bg",
            color: "info.main",
        },
        "success-loading": {
            borderWidth: "0 !important",
            backgroundColor: "success.bg",
            color: "success.main",
        },
        "error-loading": {
            borderWidth: "0 !important",
            backgroundColor: "error.bg",
            color: "error.main",
        },
        "warning-loading": {
            borderWidth: "0 !important",
            backgroundColor: "warning.bg",
            color: "warning.main",
        },
    },
});
var socialVariant = (0, styled_system_1.variant)({
    prop: "bType",
    variants: {
        google: {
            borderColor: "social.google",
            backgroundColor: "social.google",
            color: "other.white",
            fontFamily: "roboto",
            fontWeight: "medium",
            boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.24)",
            "&:hover": {
                borderColor: "social.googleHover",
                backgroundColor: "social.googleHover",
                boxShadow: "0px 0px 3px 3px rgba(66, 133, 244, 0.3)",
            },
            "&:focus": {
                borderColor: "social.googleFocus",
                backgroundColor: "social.googleFocus",
                boxShadow: "0px 0px 3px 3px rgba(66, 133, 244, 0.3)",
            },
        },
        facebook: {
            borderColor: "social.facebook",
            backgroundColor: "social.facebook",
            color: "other.white",
            "&:hover": {
                borderColor: "social.facebookHover",
                backgroundColor: "social.facebookHover",
            },
            "&:focus": {
                borderColor: "social.facebookFocus",
                backgroundColor: "social.facebookFocus",
            },
        },
    },
});
var getButtonVariant = function (bVariant) {
    switch (bVariant) {
        case "contained":
            return containedVariant;
        case "outlined":
            return outlinedVariant;
        case "ghost":
            return ghostVariant;
        case "social":
            return socialVariant;
    }
};
var buttonSizeVariant = (0, styled_system_1.variant)({
    prop: "size",
    variants: {
        s: {
            fontSize: "xs",
            px: "xs",
            py: "0.375rem",
        },
        m: {
            fontSize: "sm",
            px: "sm",
            py: "0.3125rem",
        },
        l: {
            fontSize: "lg",
            px: "md",
            py: "0.5625rem",
        },
        social: {
            fontSize: "0.875rem",
            px: "md",
            py: "0.53125rem",
        },
    },
});
exports.ButtonContainer = (0, styled_components_1.default)(design_system_1.Box).attrs(function (_a) {
    var disabled = _a.disabled, bVariant = _a.bVariant;
    return ({
        elevation: bVariant === "contained" && !disabled ? "2" : undefined,
    });
})(buttonSizeVariant, function (_a) {
    var bVariant = _a.bVariant;
    return bVariant && getButtonVariant(bVariant);
}, buttonStyles);
var roundButtonSizeVariant = (0, styled_system_1.variant)({
    prop: "size",
    variants: {
        l: {
            fontSize: "lg",
            width: "3.25rem",
            height: "3.25rem",
        },
        m: {
            fontSize: "sm",
            width: "2.25rem",
            height: "2.25rem",
        },
        s: {
            fontSize: "xs",
            width: "1.875rem",
            height: "1.875rem",
        },
    },
});
exports.RoundButtonContainer = (0, styled_components_1.default)(design_system_1.Box)(function (_a) {
    var bVariant = _a.bVariant;
    return bVariant && getButtonVariant(bVariant);
}, roundButtonSizeVariant, roundButtonStyles);
exports.buttonIconSizesMap = {
    s: "xs",
    m: "sm",
    l: "sm",
    social: null,
};
exports.buttonIconMarginsMap = {
    s: "xs",
    m: "sm",
    l: "sm",
    social: null,
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
