import { useMutation } from '@apollo/client';
import { Application, ApplicationSettings, Lease } from '@theguarantors/tg-modern-business';
import gql from 'graphql-tag';

export interface DealDetailsUpdateVariables {
  dealId: number;
  detailsToUpdate: {
    lease: Partial<Lease>;
    applicationSettings: Partial<ApplicationSettings['data']>;
    application: Partial<Application>;
  };
}

export interface MutationData {
  dealDetailsUpdate: {
    lease: Lease | null;
    applicationSettings: ApplicationSettings['data'] | null;
  };
}

export const DealDetailsUpdateMutation = gql`
  mutation DealDetailsUpdate($dealId: Int!, $detailsToUpdate: DealDetailsUpdateInput!) {
    dealDetailsUpdate(dealId: $dealId, detailsToUpdate: $detailsToUpdate) {
      lease {
        id
        leaseStartDate
        leaseEndDate
        addressUnit
        monthlyRent
        freeRent
        prepaidRent
      }
      applicationSettings {
        comment
      }
      application {
        quote {
          ... on ApplicationQuote {
            premiumAmount
            penalSum
            amountSDR
            coverageMonths
            premiumRate
            rateFilingData
            riskCategory
            carrier
            premium
            addRiskPremium
            lgPremiumAmount
            sdrPremiumAmount
          }
        }
      }
    }
  }
`;

export const useDealDetailsUpdate = () => {
  const [dealDetailsUpdate, { loading, error, data }] = useMutation<MutationData, DealDetailsUpdateVariables>(
    DealDetailsUpdateMutation,
  );

  return {
    dealDetailsUpdate,
    loading,
    error,
    data: data?.dealDetailsUpdate,
  };
};
