import React from 'react';
// import { useLocation } from 'react-router-dom';
import { BaseRoutes } from '../components/router/router.constants';
import { history } from '../api/apollo/client';
import { Loading } from '../components/loading';
import { useTgAuth } from '../hooks/use-tg-auth';
import configuration from '../config/configuration';

export const TGAuthCallbackHandler = () => {
  const { isTGAuthAuthenticated, setIsTGAuthAuthenticated } = useTgAuth();
  const searchParams = new URLSearchParams(window.location.search);
  const access_token = searchParams.get('access_token');

  if (isTGAuthAuthenticated) {
    history.push(BaseRoutes.DEALS);
    return null;
  }

  if (!access_token) {
    window.location.replace(`${configuration().tgAuthUrls.loginUrl}/okta/msa`);
  }

  localStorage.setItem('tgAccessToken', String(access_token));
  setIsTGAuthAuthenticated(true);

  return <Loading />;
};
