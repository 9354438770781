import React from 'react';
import { Header } from '@theguarantors/ui-kit-v3/design-system';
import { PropertiesTable } from './Properties.table';
import { Redirect } from 'react-router-dom';
import { useIsUserHasPermission } from '../../hooks/use-is-user-has-permission';

export const Properties: React.FC = () => {
  const showProperties = useIsUserHasPermission('properties');

  if (!showProperties) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Header align="left" pb="md" color="neutral.main">
        Properties
      </Header>
      <PropertiesTable />
    </>
  );
};
