"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextAreaComponent = void 0;
var react_1 = __importStar(require("react"));
var styles_1 = require("../styles");
var Input_styles_1 = require("./Input.styles");
var useInputBase_hook_1 = require("./useInputBase.hook");
function TextAreaComponentC(props) {
    var _a = (0, useInputBase_hook_1.useInputBase)(props), active = _a.active, handleMouseOver = _a.handleMouseOver, handleMouseOut = _a.handleMouseOut, cls = _a.cls, handleOnFocus = _a.handleOnFocus, handleOnBlur = _a.handleOnBlur, handleOnLabelClick = _a.handleOnLabelClick, placeholder = _a.placeholder, labelAndPlaceholderPresented = _a.labelAndPlaceholderPresented, label = _a.label, input = _a.input, name = _a.name;
    return (react_1.default.createElement(Input_styles_1.Container, { isActive: active, onMouseOver: handleMouseOver, onMouseOut: handleMouseOut, disabled: props.disabled, readonly: props.readOnly },
        react_1.default.createElement(Input_styles_1.Textarea, __assign({}, props, { className: cls, id: name, onFocus: handleOnFocus, onBlur: handleOnBlur, ref: input, placeholder: labelAndPlaceholderPresented ? placeholder : undefined })),
        react_1.default.createElement(styles_1.Label, { onClick: handleOnLabelClick, htmlFor: props.name },
            label || placeholder,
            " ",
            props.required ? react_1.default.createElement(styles_1.RequiredSymbol, null, "*") : null)));
}
exports.TextAreaComponent = (0, react_1.memo)(TextAreaComponentC);
