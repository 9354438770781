import React from 'react';
import { Box, Table } from '@theguarantors/ui-kit-v3';
import { TableColumn } from '@theguarantors/ui-kit-v3/components/Table/Table.types';
import { PropertiesData, useProperties } from '../../hooks/use-properties';
import { Loading } from '../../components/loading';

const columns: Array<TableColumn<keyof PropertiesData>> = [
  { Header: 'Property ID', accessor: 'id' },
  { Header: 'Property Name', accessor: 'name' },
  { Header: 'Leasing Office', accessor: 'leasingOffice' },
  { Header: 'Company', accessor: 'company' },
];

export const PropertiesTable = () => {
  const { properties, loading } = useProperties();

  if (loading) {
    return <Loading />;
  }

  return (
    <Box mb="lg">
      <Table<keyof PropertiesData> globalFiltering sortable columns={columns} data={properties} />
    </Box>
  );
};
