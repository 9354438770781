import gql from 'graphql-tag';

const PropertyTypeDef = gql`
  type PrimaryRole {
    contact: ContactEntity
  }

  type PropertyEntity {
    id: Int!
    displayName: String!
    mainAddress: String!
    state: String
    city: String
    zipCode: String
    primaryRole: PrimaryRole
    defaultCoverageRules: PropertyDefaultCoverageRules
  }

  extend type ApplicationEntity {
    lease: LeaseEntity
  }

  type PropertyDefaultCoverageRules {
    denied: PropertyTierCoverageRule
    conditional: PropertyTierCoverageRule
    approved: PropertyTierCoverageRule
  }

  type PropertyTierCoverageRule {
    lgCoverage: String
    sdrCoverage: String
    sdrCoverageType: String
  }
`;

export default [PropertyTypeDef];
