"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sidebar = void 0;
var react_1 = __importStar(require("react"));
var design_system_1 = require("../../design-system");
var GoogleMap_1 = require("../GoogleMap");
var Sidebar_styles_1 = require("./Sidebar.styles");
exports.Sidebar = (0, react_1.memo)(function (_a) {
    var _b = _a.addressDetails, addressDetails = _b === void 0 ? { coordinates: { lat: 0, lng: 0 } } : _b, personalDetails = _a.personalDetails, secondaryPersonDetails = _a.secondaryPersonDetails, landlordDetails = _a.landlordDetails, coverageAmounts = _a.coverageAmounts, coverageStartDate = _a.coverageStartDate, googleKey = _a.googleKey, props = __rest(_a, ["addressDetails", "personalDetails", "secondaryPersonDetails", "landlordDetails", "coverageAmounts", "coverageStartDate", "googleKey"]);
    return (react_1.default.createElement(Sidebar_styles_1.Container, { "data-testid": props["data-testid"] },
        addressDetails && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(Sidebar_styles_1.Header, null, "Your Address"),
            react_1.default.createElement(Sidebar_styles_1.Body, null,
                react_1.default.createElement(Sidebar_styles_1.LogoSection, null, addressDetails === null || addressDetails === void 0 ? void 0 : addressDetails.logo),
                react_1.default.createElement(design_system_1.Header4, { mb: "xs" }, addressDetails === null || addressDetails === void 0 ? void 0 : addressDetails.propertyName),
                react_1.default.createElement(design_system_1.Text, null, addressDetails === null || addressDetails === void 0 ? void 0 :
                    addressDetails.street,
                    ", ", addressDetails === null || addressDetails === void 0 ? void 0 :
                    addressDetails.city),
                react_1.default.createElement(design_system_1.Text, null, addressDetails === null || addressDetails === void 0 ? void 0 :
                    addressDetails.state,
                    ", ", addressDetails === null || addressDetails === void 0 ? void 0 :
                    addressDetails.zip),
                react_1.default.createElement(design_system_1.Box, { my: "sm", height: "10rem" },
                    react_1.default.createElement(GoogleMap_1.GoogleMap, { "data-testid": props["googleMap-data-testid"], googleKey: googleKey, coordinates: addressDetails.coordinates }))),
            react_1.default.createElement(Sidebar_styles_1.Hr, null))),
        coverageStartDate && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(Sidebar_styles_1.Header, null, "Coverage Start Date"),
            react_1.default.createElement(Sidebar_styles_1.Body, null,
                react_1.default.createElement(Sidebar_styles_1.Row, null,
                    react_1.default.createElement(Sidebar_styles_1.IconSection, { name: "today", color: "emphasis.medium", size: "var(--space-md)" }),
                    react_1.default.createElement(design_system_1.Text, { ml: "sm", pt: "xxs" }, coverageStartDate))),
            react_1.default.createElement(Sidebar_styles_1.Hr, null))),
        personalDetails && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(Sidebar_styles_1.Header, null, "People on Policy"),
            react_1.default.createElement(Sidebar_styles_1.Body, null,
                react_1.default.createElement(Sidebar_styles_1.Row, null,
                    react_1.default.createElement(Sidebar_styles_1.IconSection, { name: "person", color: "emphasis.medium", size: "var(--space-md)" }),
                    react_1.default.createElement(Sidebar_styles_1.ColumnBlock, null,
                        react_1.default.createElement(design_system_1.Header4, null, personalDetails === null || personalDetails === void 0 ? void 0 :
                            personalDetails.firstName,
                            " ", personalDetails === null || personalDetails === void 0 ? void 0 :
                            personalDetails.lastName),
                        react_1.default.createElement(design_system_1.Text, null, personalDetails === null || personalDetails === void 0 ? void 0 : personalDetails.mail))),
                landlordDetails &&
                    landlordDetails.map(function (landlord) { return (react_1.default.createElement(Sidebar_styles_1.Row, null,
                        react_1.default.createElement(Sidebar_styles_1.IconSection, { name: "person", color: "emphasis.medium", size: "var(--space-md)" }),
                        react_1.default.createElement(Sidebar_styles_1.ColumnBlock, null,
                            react_1.default.createElement(design_system_1.Text, null, landlord.mail)))); }),
                secondaryPersonDetails && (react_1.default.createElement(Sidebar_styles_1.Row, null,
                    react_1.default.createElement(Sidebar_styles_1.IconSection, { name: "person", color: "emphasis.medium", size: "var(--space-md)" }),
                    react_1.default.createElement(Sidebar_styles_1.ColumnBlock, null,
                        react_1.default.createElement(design_system_1.Text, null, secondaryPersonDetails === null || secondaryPersonDetails === void 0 ? void 0 : secondaryPersonDetails.mail))))),
            react_1.default.createElement(Sidebar_styles_1.Hr, null))),
        coverageAmounts && coverageAmounts.length === 6 && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(Sidebar_styles_1.Header, null, "Coverage Amounts"),
            react_1.default.createElement(Sidebar_styles_1.Body, null, coverageAmounts.map(function (coverage) { return (react_1.default.createElement(Sidebar_styles_1.Row, null,
                react_1.default.createElement(Sidebar_styles_1.IconSection, { name: "verifiedUser", color: "emphasis.medium", size: "var(--space-md)" }),
                react_1.default.createElement(Sidebar_styles_1.ColumnBlock, null,
                    react_1.default.createElement(design_system_1.Header4, null, coverage.type),
                    react_1.default.createElement(design_system_1.Text, null, coverage.coverageAmount)))); }))))));
});
