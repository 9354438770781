"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapInputContainer = exports.DetailContainer = exports.PaymentDetailsContainer = exports.DetailsContainer = exports.GaiContainer = exports.AmountLabel = exports.AmountContainerV2 = exports.AmountContainer = exports.CardHeader2 = exports.CoverageDetails = exports.SectionContainer = exports.ContentContainer = exports.PolicyConfirmationCardContainerV2 = exports.PolicyConfirmationCardContainer = exports.TermsAndConditionCardContainer = exports.SettingCardContainer = exports.RibbonDiv = exports.SelectionCardContainer = exports.Container = exports.tabletsAndDesktopContainer = exports.reusableCardStyles = exports.bodyText = void 0;
var styled_components_1 = __importStar(require("styled-components"));
var design_system_1 = require("../../design-system");
var Card_types_1 = require("./Card.types");
exports.bodyText = (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: var(--space-sm);\n  line-height: var(--space-md);\n  margin: var(--space-clear);\n"], ["\n  font-size: var(--space-sm);\n  line-height: var(--space-md);\n  margin: var(--space-clear);\n"])));
exports.reusableCardStyles = (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: var(--space-xs);\n  position: relative;\n  background: var(--color-other-white);\n"], ["\n  border-radius: var(--space-xs);\n  position: relative;\n  background: var(--color-other-white);\n"])));
exports.tabletsAndDesktopContainer = (0, design_system_1.forTabletsAndDesktop)((0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-radius: var(--space-xs);\n"], ["\n  border-radius: var(--space-xs);\n"]))));
var cardWidth = "42rem";
var switchCardWidth = "21rem";
var selectionCardWidth = "17.25rem";
exports.Container = (0, styled_components_1.default)(design_system_1.Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  overflow: hidden;\n  padding: var(--space-xxl);\n  background: var(--color-other-white);\n  border-radius: var(--space-xs);\n  max-width: ", ";\n  text-align: center;\n  width: ", ";\n  h1,\n  p::first-of-type {\n    margin-bottom: 4rem;\n  }\n  p {\n    ", "\n    text-align: left;\n  }\n  h1 {\n    margin-bottom: var(--space-lg);\n    text-align: ", ";\n  }\n  button {\n    margin: 0 auto;\n    margin-bottom: var(--space-md);\n    margin-top: 4rem;\n  }\n"], ["\n  position: relative;\n  overflow: hidden;\n  padding: var(--space-xxl);\n  background: var(--color-other-white);\n  border-radius: var(--space-xs);\n  max-width: ", ";\n  text-align: center;\n  width: ", ";\n  h1,\n  p::first-of-type {\n    margin-bottom: 4rem;\n  }\n  p {\n    ", "\n    text-align: left;\n  }\n  h1 {\n    margin-bottom: var(--space-lg);\n    text-align: ", ";\n  }\n  button {\n    margin: 0 auto;\n    margin-bottom: var(--space-md);\n    margin-top: 4rem;\n  }\n"])), cardWidth, function (props) { return (props.showToggleSwitch ? switchCardWidth : "100%"); }, exports.bodyText, function (props) { return (props.showToggleSwitch ? "left" : "center"); });
exports.SelectionCardContainer = (0, styled_components_1.default)(design_system_1.Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  overflow: hidden;\n  padding: ", ";\n  width: ", ";\n\n  ", "\n"], ["\n  position: relative;\n  overflow: hidden;\n  padding: ", ";\n  width: ", ";\n\n  ", "\n"])), function (props) { return (props.isMobile ? "var(--space-sm) var(--space-lg)" : "var(--space-sm) var(--space-xxl)"); }, function (props) { return (props.isMobile ? "100%" : selectionCardWidth); }, exports.reusableCardStyles);
var handleRibbonVariant = function (variant) {
    switch (variant) {
        case Card_types_1.VariantEnum.disabled:
            return "var(--color-text-disabled)";
        case Card_types_1.VariantEnum.primary:
            return "var(--color-primary-main)";
        case Card_types_1.VariantEnum.primaryV2:
            return "var(--color-primaryV2-main)";
        default:
            return "var(--color-text-highlightlight)";
    }
};
exports.RibbonDiv = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: var(--space-xs) var(--space-xs) var(--space-clear) var(--space-clear);\n  position: absolute;\n  top: var(--space-clear);\n  height: 1.125rem;\n  left: var(--space-clear);\n  right: var(--space-clear);\n  z-index: 99;\n"], ["\n  background-color: ", ";\n  border-radius: var(--space-xs) var(--space-xs) var(--space-clear) var(--space-clear);\n  position: absolute;\n  top: var(--space-clear);\n  height: 1.125rem;\n  left: var(--space-clear);\n  right: var(--space-clear);\n  z-index: 99;\n"])), function (props) { return handleRibbonVariant(props.variant); });
exports.SettingCardContainer = (0, styled_components_1.default)(design_system_1.Box)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  max-width: 56rem;\n  padding: var(--space-lg) var(--space-xxl) var(--space-md) var(--space-xxl);\n  ", "\n"], ["\n  max-width: 56rem;\n  padding: var(--space-lg) var(--space-xxl) var(--space-md) var(--space-xxl);\n  ", "\n"])), exports.reusableCardStyles);
exports.TermsAndConditionCardContainer = (0, styled_components_1.default)(design_system_1.Box)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: ", ";\n  max-width: 49rem;\n  text-align: center;\n  width: 100%;\n  button {\n    margin: var(--space-clear) auto;\n    margin-bottom: var(--space-sm);\n    margin-top: 4rem;\n  }\n\n  ", "\n"], ["\n  padding: ", ";\n  max-width: 49rem;\n  text-align: center;\n  width: 100%;\n  button {\n    margin: var(--space-clear) auto;\n    margin-bottom: var(--space-sm);\n    margin-top: 4rem;\n  }\n\n  ", "\n"])), function (props) { return (props.isMobile ? "var(--space-md)" : "var(--space-xxl)"); }, exports.reusableCardStyles);
exports.PolicyConfirmationCardContainer = (0, styled_components_1.default)(design_system_1.Box)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: var(--space-lg) var(--space-xxl);\n  max-width: 47rem;\n  text-align: center;\n  width: 100%;\n  button {\n    margin: var(--space-clear) auto;\n    margin-bottom: var(--space-sm);\n    margin-top: 4rem;\n  }\n  p {\n    text-align: left;\n  }\n  h1 {\n    margin: var(--space-clear);\n    margin-bottom: var(--space-lg);\n  }\n  ", "\n"], ["\n  padding: var(--space-lg) var(--space-xxl);\n  max-width: 47rem;\n  text-align: center;\n  width: 100%;\n  button {\n    margin: var(--space-clear) auto;\n    margin-bottom: var(--space-sm);\n    margin-top: 4rem;\n  }\n  p {\n    text-align: left;\n  }\n  h1 {\n    margin: var(--space-clear);\n    margin-bottom: var(--space-lg);\n  }\n  ", "\n"])), exports.reusableCardStyles);
exports.PolicyConfirmationCardContainerV2 = (0, styled_components_1.default)(design_system_1.Box)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: var(--space-xl) var(--space-xxl);\n  max-width: 42rem;\n  width: 100%;\n  h1 {\n    margin: var(--space-clear);\n    margin-bottom: var(--space-lg);\n  }\n  ", "\n"], ["\n  padding: var(--space-xl) var(--space-xxl);\n  max-width: 42rem;\n  width: 100%;\n  h1 {\n    margin: var(--space-clear);\n    margin-bottom: var(--space-lg);\n  }\n  ", "\n"])), exports.reusableCardStyles);
exports.ContentContainer = styled_components_1.default.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  ", ";\n\n  & h3 {\n    text-align: ", ";\n    margin: var(--space-clear);\n    margin-bottom: var(--space-xs);\n    color: var(--color-neutral-main);\n  }\n  & p {\n    color: var(--color-neutral-main);\n    margin-bottom: var(--space-xs) !important;\n    ", "\n    text-align: ", ";\n  }\n"], ["\n  ", ";\n\n  & h3 {\n    text-align: ", ";\n    margin: var(--space-clear);\n    margin-bottom: var(--space-xs);\n    color: var(--color-neutral-main);\n  }\n  & p {\n    color: var(--color-neutral-main);\n    margin-bottom: var(--space-xs) !important;\n    ", "\n    text-align: ", ";\n  }\n"])), function (props) {
    return props.isMobile
        ? (0, styled_components_1.css)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n          text-align: center;\n          width: 100%;\n          margin-left: var(--space-clear);\n          margin-bottom: var(--space-lg);\n        "], ["\n          text-align: center;\n          width: 100%;\n          margin-left: var(--space-clear);\n          margin-bottom: var(--space-lg);\n        "]))) : (0, styled_components_1.css)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n          text-align: left;\n          width: 29.5rem;\n          margin-left: 3.75rem;\n          margin-bottom: var(--space-clear);\n        "], ["\n          text-align: left;\n          width: 29.5rem;\n          margin-left: 3.75rem;\n          margin-bottom: var(--space-clear);\n        "])));
}, function (props) { return (props.isMobile ? "center" : "left"); }, exports.bodyText, function (props) { return (props.isMobile ? "center" : "left"); });
exports.SectionContainer = styled_components_1.default.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  height: 15rem;\n  overflow-y: auto;\n  border: 1px solid var(--color-other-outlineBorder);\n  padding: var(--space-sm);\n  padding-top: var(--space-xxs);\n  margin-bottom: var(--space-lg);\n  & p {\n    color: var(--color-neutral-main);\n  }\n  &:last-child {\n    margin-bottom: var(--space-clear);\n  }\n"], ["\n  height: 15rem;\n  overflow-y: auto;\n  border: 1px solid var(--color-other-outlineBorder);\n  padding: var(--space-sm);\n  padding-top: var(--space-xxs);\n  margin-bottom: var(--space-lg);\n  & p {\n    color: var(--color-neutral-main);\n  }\n  &:last-child {\n    margin-bottom: var(--space-clear);\n  }\n"])));
exports.CoverageDetails = styled_components_1.default.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  & ul {\n    list-style-type: none;\n    padding-left: var(--space-clear);\n    & li {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      color: var(--color-text-body);\n      & p {\n        margin: 12px var(--space-clear) !important;\n      }\n      & :first-child {\n        font-weight: bold;\n      }\n    }\n  }\n"], ["\n  & ul {\n    list-style-type: none;\n    padding-left: var(--space-clear);\n    & li {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      color: var(--color-text-body);\n      & p {\n        margin: 12px var(--space-clear) !important;\n      }\n      & :first-child {\n        font-weight: bold;\n      }\n    }\n  }\n"])));
exports.CardHeader2 = (0, styled_components_1.default)(design_system_1.Header2)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (props) { return props.variant === Card_types_1.VariantEnum.primaryV2 && "color: var(--color-primaryV2-main)"; });
exports.AmountContainer = styled_components_1.default.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  padding: var(--space-sm) var(--space-clear) var(--space-md);\n  border-bottom: 1px solid var(--color-text-body);\n  display: flex;\n  align-items: center;\n  & h1 {\n    margin: var(--space-clear);\n    margin-right: 0.625rem;\n    color: var(--color-primary-main);\n  }\n"], ["\n  padding: var(--space-sm) var(--space-clear) var(--space-md);\n  border-bottom: 1px solid var(--color-text-body);\n  display: flex;\n  align-items: center;\n  & h1 {\n    margin: var(--space-clear);\n    margin-right: 0.625rem;\n    color: var(--color-primary-main);\n  }\n"])));
exports.AmountContainerV2 = styled_components_1.default.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  padding: ", ";\n  display: flex;\n  align-items: end;\n"], ["\n  padding: ", ";\n  display: flex;\n  align-items: end;\n"])), function (props) {
    return props.isMobile
        ? "var(--space-md) var(--space-clear) var(--space-sm)"
        : "var(--space-sm) var(--space-clear) var(--space-xs)";
});
exports.AmountLabel = styled_components_1.default.span(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: bold;\n  line-height: 1.7rem;\n  margin: var(--space-clear);\n  margin-right: var(--space-xs);\n  color: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: bold;\n  line-height: 1.7rem;\n  margin: var(--space-clear);\n  margin-right: var(--space-xs);\n  color: ", ";\n"])), function (props) { return (props.isMobile ? "var(--space-xl)" : "var(--space-lg)"); }, function (props) {
    return props.variant === Card_types_1.VariantEnum.primaryV2 ? "var(--color-primaryV2-main)" : "var(--color-primary-main)";
});
exports.GaiContainer = styled_components_1.default.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  & a {\n    text-decoration: none;\n    color: var(--color-neutral-main);\n    overflow-wrap: break-word;\n    hyphens: auto;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  & a {\n    text-decoration: none;\n    color: var(--color-neutral-main);\n    overflow-wrap: break-word;\n    hyphens: auto;\n  }\n"])));
exports.DetailsContainer = styled_components_1.default.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  display: flex;\n  margin-top: ", ";\n  flex-direction: ", ";\n  gap: ", ";\n"], ["\n  display: flex;\n  margin-top: ", ";\n  flex-direction: ", ";\n  gap: ", ";\n"])), function (props) { return (props.isMobile ? "var(--space-md)" : "var(--space-sm)"); }, function (props) { return (props.isMobile ? "column" : "row"); }, function (props) { return (props.isMobile ? "var(--space-lg)" : "var(--space-xl)"); });
exports.PaymentDetailsContainer = styled_components_1.default.div(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), function (props) { return (props.isMobile ? "var(--space-md)" : "var(--space-sm)"); });
exports.DetailContainer = styled_components_1.default.div(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  text-align: left;\n  margin-top: var(--space-lg);\n  & ul {\n    list-style-type: none;\n    padding-left: var(--space-clear);\n    & li {\n      display: flex;\n      &:not(:last-child) {\n        margin-bottom: var(--space-sm);\n      }\n      & svg {\n        margin-right: 0.625rem;\n        display: block;\n      }\n      & p {\n        margin: var(--space-clear);\n      }\n    }\n  }\n"], ["\n  text-align: left;\n  margin-top: var(--space-lg);\n  & ul {\n    list-style-type: none;\n    padding-left: var(--space-clear);\n    & li {\n      display: flex;\n      &:not(:last-child) {\n        margin-bottom: var(--space-sm);\n      }\n      & svg {\n        margin-right: 0.625rem;\n        display: block;\n      }\n      & p {\n        margin: var(--space-clear);\n      }\n    }\n  }\n"])));
exports.MapInputContainer = (0, styled_components_1.default)(design_system_1.Box)(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n  height: 20rem;\n  flex-direction: ", ";\n  min-width: ", ";\n  max-width: 37.5rem;\n  & button {\n    position: fixed;\n    bottom: 1.625rem;\n    right: 2.125rem;\n  }\n  ", "\n"], ["\n  display: flex;\n  position: relative;\n  height: 20rem;\n  flex-direction: ", ";\n  min-width: ", ";\n  max-width: 37.5rem;\n  & button {\n    position: fixed;\n    bottom: 1.625rem;\n    right: 2.125rem;\n  }\n  ", "\n"])), function (props) { return (props.isMobile ? "column" : "row"); }, function (props) { return (props.isMobile ? "initial" : "30rem"); }, exports.reusableCardStyles);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24;
