"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transitions = exports.lineHeights = exports.fontFamilies = exports.fontWeights = exports.fontSizes = void 0;
__exportStar(require("./types"), exports);
__exportStar(require("./primitives"), exports);
exports.fontSizes = {
    xs: "0.75rem",
    sm: "1rem",
    md: "1.25rem",
    lg: "1.5rem",
    xl: "2rem",
    xxl: "2.5rem",
    xxxl: "3rem",
};
exports.fontWeights = {
    medium: 500,
    bold: 700,
};
exports.fontFamilies = {
    default: "'Gilroy Sans', sans-serif",
    heading: "\"Tiempos Headline\", Georgia, serif",
    roboto: "'Roboto', sans-serif",
};
exports.lineHeights = {
    heading: "1.25",
    body: "1.5",
};
exports.transitions = {
    none: "none",
    quick: "100ms",
    default: "333ms",
};
