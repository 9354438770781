"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCategory = void 0;
const application_1 = require("./types/application");
const getCategory = ({ userCategory, location, isV2RateFiling, applicationId, logger, isLowCategory, }) => {
    let category = userCategory;
    if (isV2RateFiling && location) {
        category = location === 'NY' && !isLowCategory ? application_1.RISK_CATEGORY.MEDIUM : application_1.RISK_CATEGORY.LOW;
    }
    logger.info('Got final category', { applicationId, leaseAddressState: location, category });
    return category;
};
exports.getCategory = getCategory;
