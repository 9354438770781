"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GooglePlaceInput = void 0;
var react_1 = __importStar(require("react"));
var react_places_autocomplete_1 = __importStar(require("react-places-autocomplete"));
var design_system_1 = require("../../design-system");
var hooks_1 = require("../../hooks");
var Input_1 = require("../Input");
var GooglePlaceInput_styles_1 = require("./GooglePlaceInput.styles");
var GooglePlaceInput_types_1 = require("./GooglePlaceInput.types");
var GooglePlaceInput = function (_a) {
    var placeholder = _a.placeholder, id = _a.id, label = _a.label, onPlaceError = _a.onPlaceError, onPlaceSelect = _a.onPlaceSelect, onPlaceSelectError = _a.onPlaceSelectError, required = _a.required, invalid = _a.invalid, apiKey = _a.apiKey, onBlur = _a.onBlur, onChange = _a.onChange, props = __rest(_a, ["placeholder", "id", "label", "onPlaceError", "onPlaceSelect", "onPlaceSelectError", "required", "invalid", "apiKey", "onBlur", "onChange"]);
    var scriptStatus = (0, hooks_1.useLoadGoogleScript)(apiKey);
    var _b = (0, react_1.useState)(""), errorStatus = _b[0], setErrorStatus = _b[1];
    var _c = (0, react_1.useState)(false), addAddressManually = _c[0], setAddAddressManually = _c[1];
    var onError = (0, react_1.useCallback)(function (status, clearSuggestion) {
        setErrorStatus(status);
        clearSuggestion();
        onPlaceError && onPlaceError(status, clearSuggestion);
    }, [onPlaceError]);
    var handleSelect = (0, react_1.useCallback)(function (address) { return __awaiter(void 0, void 0, void 0, function () {
        var response, latLong, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, (0, react_places_autocomplete_1.geocodeByAddress)(address)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, react_places_autocomplete_1.getLatLng)(response === null || response === void 0 ? void 0 : response[0])];
                case 2:
                    latLong = _a.sent();
                    setErrorStatus("");
                    onPlaceSelect && onPlaceSelect(address, response, latLong);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    if (error_1) {
                        onPlaceSelectError === null || onPlaceSelectError === void 0 ? void 0 : onPlaceSelectError(typeof error_1 === "string" ? error_1 : "Something went wrong");
                        if (typeof error_1 !== "string") {
                            // eslint-disable-next-line
                            console.log("Something went wrong", error_1);
                        }
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [onPlaceSelect, onPlaceSelectError]);
    var handleChange = (0, react_1.useCallback)(function (value) {
        setAddAddressManually(false);
        setErrorStatus("");
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    }, [onChange]);
    var onAddManually = (0, react_1.useCallback)(function () { return setAddAddressManually(true); }, []);
    var renderErrorBlock = react_1.default.useMemo(function () {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            errorStatus === "UNKNOWN_ERROR" && (react_1.default.createElement(design_system_1.Box, { elevation: "6", display: "block", py: "xs" },
                react_1.default.createElement(design_system_1.Text, { py: "sm", pl: "sm", color: "error.main" }, "Request could not be processed due to a server error"))),
            errorStatus === "ZERO_RESULTS" && !addAddressManually && (react_1.default.createElement(design_system_1.Box, { elevation: "6", display: "block", py: "xs" },
                react_1.default.createElement(design_system_1.Box, { display: "flex", py: "xs", px: "sm" },
                    react_1.default.createElement(GooglePlaceInput_styles_1.SuggestionIcons, { mr: "xs", name: "exploreOff", color: "error.main" }),
                    react_1.default.createElement(design_system_1.Box, { mt: "xxs" },
                        react_1.default.createElement(design_system_1.Header4, { display: "block" }, "Address Not Found"),
                        react_1.default.createElement("div", null,
                            react_1.default.createElement(design_system_1.Text, null, "Please review the address you entered or "),
                            react_1.default.createElement(design_system_1.Header4, { color: "primary.main", pl: "xxs", textDecoration: "underline", onClick: onAddManually, cursor: "pointer" },
                                " ",
                                "add address manually"))))))));
    }, [errorStatus, addAddressManually, onAddManually]);
    var renderSuggestions = react_1.default.useCallback(function (loading, suggestions, getSuggestionItemProps) {
        return (react_1.default.createElement(design_system_1.Box, { elevation: "6", display: "block", py: "xs" },
            loading && (react_1.default.createElement(design_system_1.Box, { py: "xs", textAlign: "center" },
                react_1.default.createElement(design_system_1.Text, null, "Loading..."))),
            suggestions.map(function (suggestion) { return (react_1.default.createElement(react_1.default.Fragment, { key: suggestion.placeId },
                react_1.default.createElement(GooglePlaceInput_styles_1.MapSuggestion, __assign({ active: suggestion.active, display: "flex", py: "xs", px: "sm" }, getSuggestionItemProps(suggestion)),
                    react_1.default.createElement(GooglePlaceInput_styles_1.SuggestionIcons, { mr: "xs", name: "locationOn", color: suggestion.active ? "other.white" : "primary.main" }),
                    react_1.default.createElement(design_system_1.Text, null, suggestion.description),
                    suggestion.active && (react_1.default.createElement(GooglePlaceInput_styles_1.SuggestionIcons, { ml: "xs", name: "check", color: suggestion.active ? "other.white" : "primary.main" }))))); })));
    }, []);
    return (react_1.default.createElement(design_system_1.Box, { mb: "xs", "data-testid": props["data-testid"], "data-testelement": "googlePlaceInput" }, scriptStatus === GooglePlaceInput_types_1.ScriptStatus.READY && (react_1.default.createElement(react_places_autocomplete_1.default, __assign({ onError: onError, onSelect: handleSelect, onChange: handleChange }, props), function (_a) {
        var getInputProps = _a.getInputProps, suggestions = _a.suggestions, getSuggestionItemProps = _a.getSuggestionItemProps, loading = _a.loading;
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(Input_1.Input, __assign({}, getInputProps({
                id: id,
                placeholder: placeholder,
                label: label,
                required: required,
                invalid: invalid,
                onBlur: onBlur,
            }))),
            !suggestions.length ? renderErrorBlock : renderSuggestions(loading, suggestions, getSuggestionItemProps)));
    }))));
};
exports.GooglePlaceInput = GooglePlaceInput;
