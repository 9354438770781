"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectComponent = void 0;
var react_1 = __importStar(require("react"));
var Autocomplete_list_1 = require("../Autocomplete/Autocomplete.list");
var Select_styles_1 = require("./Select.styles");
var defaultOptionRender = function (option) { return option.value; };
var SelectComponent = function (_a) {
    var _b;
    var _c = _a.options, options = _c === void 0 ? [] : _c, onChange = _a.onChange, onFocus = _a.onFocus, onBlur = _a.onBlur, _d = _a.optionRender, optionRender = _d === void 0 ? defaultOptionRender : _d, _value = _a.value, props = __rest(_a, ["options", "onChange", "onFocus", "onBlur", "optionRender", "value"]);
    var _e = (0, react_1.useState)(false), isFocused = _e[0], setFocus = _e[1];
    var value = (_b = options.find(function (option) { return option.option === _value; })) === null || _b === void 0 ? void 0 : _b.value;
    var onSelect = function (option) {
        onChange(option.option);
    };
    var onFocusInput = function (e) {
        setFocus(true);
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
    };
    var onBlurInput = function (e) {
        setFocus(false);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    };
    return (react_1.default.createElement(Select_styles_1.SelectContainer, null,
        react_1.default.createElement(Select_styles_1.SelectInputComponent, __assign({}, props, { isSelect: true, value: value || "", onFocus: onFocusInput, onBlur: onBlurInput })),
        react_1.default.createElement(Autocomplete_list_1.AutocompleteList, { items: options, onSelect: onSelect, active: isFocused, isLoading: false, suggestionRender: optionRender })));
};
exports.SelectComponent = SelectComponent;
