import { PropertiesData } from './use-properties';

export const fakeData: PropertiesData[] = [
  { id: 1245, name: 'Jackson Park', leasingOffice: 'Jackson Park Leasing', company: 'Tishman Speyer' },
  { id: 961, name: 'Via', leasingOffice: '57 West Leasing', company: 'The Durst Organization' },
  { id: 960, name: 'EOS', leasingOffice: 'EOS Leasing', company: 'The Durst Organization' },
  { id: 1756, name: 'The Helena', leasingOffice: '57 West Leasing', company: 'The Durst Organization' },
  { id: 124, name: '10 Halletts Point', leasingOffice: 'Halletts Point Leasing', company: 'The Durst Organization' },
  { id: 28103, name: 'The Max', leasingOffice: 'TF Cornerstone Max Leasing', company: 'TF Cornerstone' },
  { id: 28100, name: '505 W 37th Street', leasingOffice: 'TF Cornerstone 505 Leasing', company: 'TF Cornerstone' },
  { id: 28099, name: '455 W 37th Street', leasingOffice: 'TF Cornerstone 505 Leasing', company: 'TF Cornerstone' },
  { id: 28097, name: 'Plaza West', leasingOffice: 'TF Cornerstone 505 Leasing', company: 'TF Cornerstone' },
  { id: 28105, name: '33 Bond Street', leasingOffice: 'TF Cornerstone 33 Bond Leasing', company: 'TF Cornerstone' },
  { id: 20455, name: '2 Gold Street', leasingOffice: 'TF Cornerstone 2 Gold Leasing', company: 'TF Cornerstone' },
  { id: 28094, name: '201 Pearl Street', leasingOffice: 'TF Cornerstone 2 Gold Leasing', company: 'TF Cornerstone' },
  { id: 28098, name: '45 Wall Street', leasingOffice: 'TF Cornerstone 45 Wall Leasing', company: 'TF Cornerstone' },
  { id: 28095, name: 'Chelsea Centro', leasingOffice: 'TF Cornerstone Chelsea Leasing', company: 'TF Cornerstone' },
  { id: 23781, name: '1265 N. Mathew St.', leasingOffice: ' RPM Bakersfield', company: 'Real Property Management' },
  { id: 23854, name: '1334 N. Atkins Pl.', leasingOffice: ' RPM Bakersfield', company: 'Real Property Management' },
  { id: 23804, name: '738 E. Laurel Ave.', leasingOffice: ' RPM Bakersfield', company: 'Real Property Management' },
  {
    id: 23764,
    name: '1090 Stonegate Cove Dr.',
    leasingOffice: ' RPM Bakersfield',
    company: 'Real Property Management',
  },
  { id: 23751, name: '368 E. Poplar Ave.', leasingOffice: ' RPM Bakersfield', company: 'Real Property Management' },
  { id: 23849, name: '1211 Atkins Way', leasingOffice: ' RPM Bakersfield', company: 'Real Property Management' },
  { id: 23754, name: '1904 W. Roby Ave.', leasingOffice: ' RPM Bakersfield', company: 'Real Property Management' },
  { id: 23776, name: '1895 W. Roby Ave.', leasingOffice: ' RPM Bakersfield', company: 'Real Property Management' },
  { id: 23888, name: '730 Gerry Ln.', leasingOffice: ' RPM Bakersfield', company: 'Real Property Management' },
  {
    id: 23844,
    name: '1080 Stonegate Cove Dr.',
    leasingOffice: ' RPM Bakersfield',
    company: 'Real Property Management',
  },
  { id: 23877, name: '1749 W. Pamela Ct.', leasingOffice: ' RPM Bakersfield', company: 'Real Property Management' },
  { id: 23774, name: '80 N. Prospect St.', leasingOffice: ' RPM Bakersfield', company: 'Real Property Management' },
  { id: 23784, name: '1670 Terry Ct.', leasingOffice: ' RPM Bakersfield', company: 'Real Property Management' },
  {
    id: 22305,
    name: '2975 Van Ness Avenue',
    leasingOffice: 'Greentree Leasing',
    company: 'Greentree Property Management',
  },
  {
    id: 22315,
    name: '3210 Gough Street',
    leasingOffice: 'Greentree Leasing',
    company: 'Greentree Property Management',
  },
  { id: 22372, name: '50 Joice Street', leasingOffice: 'Greentree Leasing', company: 'Greentree Property Management' },
  {
    id: 22407,
    name: "691 O'Farrell Street",
    leasingOffice: 'Greentree Leasing',
    company: 'Greentree Property Management',
  },
  {
    id: 22369,
    name: '500-506 Bartlett Street',
    leasingOffice: 'Greentree Leasing',
    company: 'Greentree Property Management',
  },
  {
    id: 22209,
    name: '1401 Jones Street',
    leasingOffice: 'Greentree Leasing',
    company: 'Greentree Property Management',
  },
  {
    id: 22292,
    name: '2600 Van Ness Avenue',
    leasingOffice: 'Greentree Leasing',
    company: 'Greentree Property Management',
  },
  {
    id: 22219,
    name: '1500-1514 Geneva Avenue',
    leasingOffice: 'Greentree Leasing',
    company: 'Greentree Property Management',
  },
  {
    id: 22222,
    name: '1520 Gough Street',
    leasingOffice: 'Greentree Leasing',
    company: 'Greentree Property Management',
  },
  { id: 22199, name: '124 Mason Street', leasingOffice: 'Greentree Leasing', company: 'Greentree Property Management' },
  {
    id: 22397,
    name: '645 Stockton Street',
    leasingOffice: 'Greentree Leasing',
    company: 'Greentree Property Management',
  },
  {
    id: 22431,
    name: '840 California Street',
    leasingOffice: 'Greentree Leasing',
    company: 'Greentree Property Management',
  },
  { id: 18624, name: 'Mosser Towers', leasingOffice: 'Mosser NorCal Leasing Team', company: 'Mosser' },
  { id: 18637, name: '825 Post', leasingOffice: 'Mosser LA Leasing Team', company: 'Mosser' },
  {
    id: 18586,
    name: '1700 California St. - Residential',
    leasingOffice: 'Mosser NorCal Leasing Team',
    company: 'Mosser',
  },
  { id: 18566, name: '6500 Yucca', leasingOffice: 'Mosser LA Leasing Team', company: 'Mosser' },
  { id: 18585, name: '952 Sutter', leasingOffice: 'Mosser NorCal Leasing Team', company: 'Mosser' },
  { id: 18571, name: '6434 Yucca', leasingOffice: 'Mosser LA Leasing Team', company: 'Mosser' },
  { id: 18567, name: '500 S. Westmoreland', leasingOffice: 'Mosser NorCal Leasing Team', company: 'Mosser' },
  { id: 18564, name: '1417 N. Bronson', leasingOffice: 'Mosser LA Leasing Team', company: 'Mosser' },
  {
    id: 979,
    name: 'The Beaux Arts - 307 East 44th St',
    leasingOffice: 'Brodsky - Enclave Leasing',
    company: 'Brodsky',
  },
  {
    id: 873,
    name: 'Beaux Arts Apartment - 310 East 44th St',
    leasingOffice: 'Brodsky - Brooklyn Leasing 1',
    company: 'Brodsky',
  },
  { id: 992, name: 'One Columbus Place', leasingOffice: 'Brodsky - Brooklyn Leasing 2', company: 'Brodsky' },
  { id: 871, name: '360 W 43rd', leasingOffice: 'Brodsky - Lincoln Square Leasing', company: 'Brodsky' },
  { id: 998, name: 'The Carteret', leasingOffice: 'Brodsky - 360W43/420W42 Leasing', company: 'Brodsky' },
  { id: 995, name: '433 West 21st Street', leasingOffice: 'Brodsky - Beaux Arts Leasing', company: 'Brodsky' },
  { id: 989, name: 'West Pierre', leasingOffice: 'Brodsky - OCP Leasing', company: 'Brodsky' },
  { id: 988, name: 'South Pierre', leasingOffice: 'Brodsky - Chelsea Leasing', company: 'Brodsky' },
  { id: 1024, name: '19 West 69th Street', leasingOffice: 'Brodsky - East Village Leasing', company: 'Brodsky' },
];
