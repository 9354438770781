"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutocompleteComponent = void 0;
var react_1 = __importStar(require("react"));
var Input_component_1 = require("../Input/Input.component");
var Autocomplete_list_1 = require("./Autocomplete.list");
var Autocomplete_styles_1 = require("./Autocomplete.styles");
var Autocomplete_utils_1 = require("./Autocomplete.utils");
var AutocompleteComponent = function (_a) {
    var onRequestSuggestions = _a.onRequestSuggestions, _onSelect = _a.onSelect, _onChange = _a.onChange, suggestions = _a.suggestions, _value = _a.value, _b = _a.valueRender, valueRender = _b === void 0 ? Autocomplete_utils_1.defaultValueRender : _b, isLoading = _a.isLoading, _c = _a.minThreshold, minThreshold = _c === void 0 ? 3 : _c, _d = _a.suggestionRender, suggestionRender = _d === void 0 ? function (item) { return item.value; } : _d, onBlur = _a.onBlur, onFocus = _a.onFocus, controlKey = _a.controlKey, props = __rest(_a, ["onRequestSuggestions", "onSelect", "onChange", "suggestions", "value", "valueRender", "isLoading", "minThreshold", "suggestionRender", "onBlur", "onFocus", "controlKey"]);
    var selected = (0, react_1.useMemo)(function () { return (0, Autocomplete_utils_1.stringToAutocompleteItem)(_value, suggestions); }, [_value, suggestions]);
    var _e = (0, react_1.useState)(selected || null), selectedItem = _e[0], setSelectedItem = _e[1];
    var value = selectedItem ? valueRender(selectedItem) : "";
    var _f = (0, react_1.useState)(value), stateValue = _f[0], setValue = _f[1];
    var _g = (0, react_1.useState)(false), isFocused = _g[0], setFocus = _g[1];
    var _h = (0, react_1.useState)(true), isCollapsed = _h[0], setCollapsed = _h[1];
    var active = isFocused && !isCollapsed && stateValue.length >= minThreshold;
    (0, react_1.useEffect)(function () {
        if (JSON.stringify(selected) !== JSON.stringify(selectedItem)) {
            setSelectedItem(selected || null);
        }
    }, [selected, selectedItem]);
    (0, react_1.useEffect)(function () {
        if (selectedItem) {
            setValue(valueRender(selectedItem));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selectedItem)]);
    var onChange = function (e) {
        setCollapsed(false);
        setFocus(true);
        var value = e.target.value;
        setValue(value);
        _onChange === null || _onChange === void 0 ? void 0 : _onChange(e);
        if (value.length < minThreshold) {
            return null;
        }
        onRequestSuggestions === null || onRequestSuggestions === void 0 ? void 0 : onRequestSuggestions(value);
    };
    var onSuggestionSelected = function () {
        setFocus(false);
        setCollapsed(true);
    };
    var onSelect = function (item) {
        if (JSON.stringify(item === null || item === void 0 ? void 0 : item.option) !== JSON.stringify(selected === null || selected === void 0 ? void 0 : selected.option)) {
            _onSelect(item);
            setSelectedItem(item);
        }
        onSuggestionSelected();
    };
    var onInputFocus = function (e) {
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
        setFocus(true);
        if (!minThreshold) {
            setCollapsed(false);
        }
    };
    var onInputBlur = function (e) {
        setFocus(false);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    };
    return (react_1.default.createElement(Autocomplete_styles_1.AutocompleteContainer, { "data-testid": props["data-testid"] ? "container-".concat(props["data-testid"]) : undefined },
        react_1.default.createElement(Input_component_1.InputComponent, __assign({}, props, { "data-testelement": "autocompleteInput", autoComplete: "off", value: stateValue, isIconVisible: true, icon: active ? "expandLess" : "expandMore", onChange: onChange, onFocus: onInputFocus, onBlur: onInputBlur })),
        react_1.default.createElement(Autocomplete_list_1.AutocompleteList, { suggestionRender: suggestionRender, active: active, items: suggestions, onSelect: onSelect, isLoading: isLoading, "data-testid": props["data-testid"], "data-testelement": "autocompleteList" })));
};
exports.AutocompleteComponent = AutocompleteComponent;
