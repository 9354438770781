import { useMediaQuery } from '@material-ui/core';

/**
 * Represents the viewport state.
 */
export enum ResponsiveBreakpoint {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

/**
 * This hook will either return `ResponsiveBreakpoint.Desktop` or `ResponsiveBreakpoint.Mobile` depending on
 * the current width of the viewport. The current (only) breakpoint is at 1100px.
 */
export const useResponsive = (): ResponsiveBreakpoint => {
  const isDesktop = useMediaQuery('(min-width: 1100px)');
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1099px)');

  return isDesktop
    ? ResponsiveBreakpoint.Desktop
    : isTablet
    ? ResponsiveBreakpoint.Tablet
    : ResponsiveBreakpoint.Mobile;
};
