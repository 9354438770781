"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsOnTop = void 0;
var react_1 = require("react");
function useIsOnTop(offset) {
    if (offset === void 0) { offset = 50; }
    var _a = (0, react_1.useState)(true), isOnTop = _a[0], toggleTop = _a[1];
    (0, react_1.useLayoutEffect)(function () {
        var onScroll = function () {
            toggleTop((window === null || window === void 0 ? void 0 : window.pageYOffset) <= offset);
        };
        window === null || window === void 0 ? void 0 : window.addEventListener("scroll", onScroll);
        return function () {
            window === null || window === void 0 ? void 0 : window.removeEventListener("scroll", onScroll);
        };
    });
    return isOnTop;
}
exports.useIsOnTop = useIsOnTop;
