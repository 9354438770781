"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalComponent = void 0;
var react_1 = __importStar(require("react"));
var framer_motion_1 = require("framer-motion");
var hooks_1 = require("../../hooks");
var icons_1 = require("../../icons");
var Modal_types_1 = require("./Modal.types");
var v1styles = __importStar(require("./styles/Modal.v1.styles"));
var v2styles = __importStar(require("./styles/Modal.v2.styles"));
var stylesMap = (_a = {},
    _a[Modal_types_1.ModalVersion.V1] = v1styles,
    _a[Modal_types_1.ModalVersion.V2] = v2styles,
    _a);
exports.ModalComponent = (0, react_1.memo)(function (_a) {
    var scrollable = _a.scrollable, isOpen = _a.isOpen, children = _a.children, title = _a.title, _b = _a.onClose, onClose = _b === void 0 ? function () { } : _b, _c = _a.version, version = _c === void 0 ? Modal_types_1.ModalVersion.V1 : _c, _d = _a.height, height = _d === void 0 ? "60%" : _d, _e = _a.width, width = _e === void 0 ? version === Modal_types_1.ModalVersion.V1 ? "60%" : "" : _e, _f = _a.borderRadius, borderRadius = _f === void 0 ? "0.5rem" : _f, headingContent = _a.headingContent, props = __rest(_a, ["scrollable", "isOpen", "children", "title", "onClose", "version", "height", "width", "borderRadius", "headingContent"]);
    var containerRef = (0, react_1.useRef)(null);
    var styles = stylesMap[version];
    var LayerContainer = styles.LayerContainer, Container = styles.Container, InnerContainer = styles.InnerContainer, CloseSection = styles.CloseSection, Content = styles.Content, Heading = styles.Heading, Substrate = styles.Substrate, Title = styles.Title;
    var isInfoModal = title === "InfoModal";
    (0, hooks_1.useLockBodyScroll)(isOpen);
    var substrateOnClick = (0, react_1.useCallback)(function (e) {
        var _a;
        if ((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.contains(e.target)) {
            return;
        }
        onClose();
    }, [onClose]);
    return (react_1.default.createElement(framer_motion_1.AnimatePresence, { initial: false, "data-testid": "".concat(props["data-testid"]) }, isOpen && (react_1.default.createElement(LayerContainer, { "data-testid": "layer-".concat(props["data-testid"]) },
        react_1.default.createElement(Substrate, { onClick: substrateOnClick, "data-testid": "subStrate-".concat(props["data-testid"]) },
            react_1.default.createElement(Container, { ref: containerRef, containerHeight: height, containerWidth: width, containerBorderRadius: borderRadius, isInfoModal: isInfoModal, "data-testid": "container-".concat(props["data-testid"]) },
                headingContent,
                react_1.default.createElement(InnerContainer, null,
                    react_1.default.createElement(CloseSection, { isInfoModal: isInfoModal, onClick: onClose, "data-testid": "closeSection-".concat(props["data-testid"]) },
                        react_1.default.createElement(icons_1.Icon, { name: "close", color: "neutral.main", size: "xs" })),
                    title !== "InfoModal" && (react_1.default.createElement(Heading, { "data-testid": "heading-".concat(props["data-testid"]) },
                        react_1.default.createElement(Title, null, title))),
                    react_1.default.createElement(Content, { scrollable: scrollable, isInfoModal: isInfoModal, "data-testid": "content-".concat(props["data-testid"]) }, children))))))));
});
