import { ApolloError, MutationFunction, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

interface ProcessEvaluatedApplicationVariables {
  applicationId: number;
  approvalStatus: string;
}

interface ProcessEvaluatedApplicationResponse {
  processEvaluatedApplication: {
    applicationId?: number;
    applicationStatus?: string;
    error?: { message?: string };
  };
}

interface ProcessEvaluatedApplicationResult {
  data?: ProcessEvaluatedApplicationResponse['processEvaluatedApplication'] | null;
  loading: boolean;
  error?: ApolloError;
  processEvaluatedApplication: MutationFunction<
    ProcessEvaluatedApplicationResponse,
    ProcessEvaluatedApplicationVariables
  >;
}

export const ProcessEvaluationMutation = gql`
  mutation ProcessEvaluatedApplication($applicationId: Int!, $approvalStatus: String!) {
    processEvaluatedApplication(applicationId: $applicationId, approvalStatus: $approvalStatus) {
      applicationId
      applicationStatus
      error {
        message
      }
    }
  }
`;

export const useProcessEvaluatedApplication = (): ProcessEvaluatedApplicationResult => {
  const [processEvaluatedApplication, { data, loading, error }] = useMutation<
    ProcessEvaluatedApplicationResponse,
    ProcessEvaluatedApplicationVariables
  >(ProcessEvaluationMutation);

  return { data: data?.processEvaluatedApplication, loading, error, processEvaluatedApplication };
};
