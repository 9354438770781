"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccordionLogic = void 0;
var react_1 = require("react");
var utils_1 = require("../../utils");
var useAccordionLogic = function (manual, setFunc) {
    if (setFunc === void 0) { setFunc = utils_1.noon; }
    var _a = (0, react_1.useState)(false), isActive = _a[0], _setIsActive = _a[1];
    var setIsActive = function () { return _setIsActive(function (current) { return !current; }); };
    if (manual !== undefined) {
        return [manual, setFunc];
    }
    return [isActive, setIsActive];
};
exports.useAccordionLogic = useAccordionLogic;
