"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROPERTY_LEVELS = exports.PROPERTY_TYPES = void 0;
var PROPERTY_TYPES;
(function (PROPERTY_TYPES) {
    PROPERTY_TYPES["SINGLE"] = "single";
    PROPERTY_TYPES["COMMUNITY"] = "community";
    PROPERTY_TYPES["CONDO"] = "condo";
})(PROPERTY_TYPES = exports.PROPERTY_TYPES || (exports.PROPERTY_TYPES = {}));
var PROPERTY_LEVELS;
(function (PROPERTY_LEVELS) {
    PROPERTY_LEVELS["PROPERTY"] = "property";
    PROPERTY_LEVELS["BUILDING"] = "building";
    PROPERTY_LEVELS["PHASE"] = "phase";
})(PROPERTY_LEVELS = exports.PROPERTY_LEVELS || (exports.PROPERTY_LEVELS = {}));
