import { APPLICATION_STATUSES, SCREENING_STATUSES } from '@theguarantors/tg-modern-business';

export const allowedScreeningStatusesForFinalEvaluation = [
  SCREENING_STATUSES.APPROVED,
  SCREENING_STATUSES.DENIED,
  SCREENING_STATUSES.CONDITIONAL,
];

export const allowedApplicationStatusesForPreApprovalEvaluation = [
  APPLICATION_STATUSES.PENDING_LEASE_DETAILS,
  APPLICATION_STATUSES.APPROVED,
];
