import { FetchDataFn, FetchDataFnArgs, TableColumn } from '@theguarantors/ui-kit-v3/components/Table/Table.types';
import { Table, Box } from '@theguarantors/ui-kit-v3';
import { Icon } from '@theguarantors/ui-kit-v3/icons';
import React, { useCallback, useEffect } from 'react';
import { CellProps, Renderer } from 'react-table';
import { Link } from 'react-router-dom';
import { debounce } from '@material-ui/core';

import { useApplications } from '../../hooks/use-applications';
import { mapAppStatusToIconProps } from '../../utils/mapAppStatusToIconProps';
import { usePaginatedApplications } from '../../hooks/use-paginated-applications';
import { FeatureFlag, useFeatureFlag } from '../../hooks/use-feature-flag';
import { ApplicationData, useDealsData } from './useDealsData';
import { getAmountString } from '../../pages/details/details.utils';
import { Loading } from '../loading';
import './deal-list.scss';

export const DealsList: React.FC = () => {
  const {
    applications,
    loading: applicationsLoading,
    called: applicationsCalled,
    fetchApplications,
  } = useApplications();

  const {
    paginatedApplications,
    loading: paginatedApplicationsLoading,
    called: paginatedApplicationsCalled,
    fetchPaginatedApplications,
  } = usePaginatedApplications();

  const [, { isReady, isEnabled: isServerSidePaginationEnabled }] = useFeatureFlag(
    FeatureFlag.SERVER_SIDE_PAGINATION_MSA_ADMIN,
  );
  const dealsData = useDealsData(applications);

  const rentCellRenderer: Renderer<CellProps<ApplicationData, string>> = useCallback(
    ({ value }) => getAmountString(value),
    [],
  );
  const idCellRenderer: Renderer<CellProps<ApplicationData, number | string>> = useCallback(
    ({ value }: { readonly value: number | string }) => value && <Link to={`/applications/${value}`}>{value}</Link>,
    [],
  );
  const statusCellRenderer: Renderer<CellProps<ApplicationData, string>> = useCallback(
    ({ value }) => (
      <Box display="flex" alignItems="center" style={{ textTransform: 'capitalize' }}>
        <Icon size="xs" {...mapAppStatusToIconProps(value)} mr="xs" />
        <b>{value}</b>
      </Box>
    ),
    [],
  );

  const fsMaskedRenderer: Renderer<CellProps<ApplicationData, string>> = useCallback(
    ({ value }) => <span className="fs-mask">{value}</span>,
    [],
  );

  const dealsListColumns: Array<TableColumn<keyof ApplicationData>> = [
    { Header: 'Status', accessor: 'status', Cell: statusCellRenderer },
    { Header: 'Applicant', accessor: 'applicant', Cell: fsMaskedRenderer },
    { Header: 'Email', accessor: 'email', Cell: fsMaskedRenderer },
    { Header: 'Building - Unit', accessor: 'buildingAndUnit' },
    { Header: 'Lease Start', accessor: 'leaseStartDate' },
    { Header: 'Rent', accessor: 'monthlyRent', Cell: rentCellRenderer },
    { Header: 'App ID', accessor: 'id', Cell: idCellRenderer },
  ];

  useEffect(() => {
    if (isReady && !isServerSidePaginationEnabled) {
      void fetchApplications();
      return;
    }

    if (isReady && isServerSidePaginationEnabled) {
      void fetchPaginatedApplications({
        variables: {
          pagination: {
            pageIndex: 1,
            pageSize: 25,
          },
        },
      });
    }
  }, [fetchApplications, fetchPaginatedApplications, isReady, isServerSidePaginationEnabled]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback<FetchDataFn<ApplicationData>>(
    debounce((event: FetchDataFnArgs<ApplicationData>) => {
      void fetchPaginatedApplications({
        variables: {
          pagination: {
            pageIndex: Number(event.pageIndex) + 1,
            globalFilter: event.globalFilter,
            sortBy: event.sortBy,
            pageSize: event.pageSize,
          },
        },
      });
    }, 500),
    [fetchPaginatedApplications],
  );

  const data = (isServerSidePaginationEnabled ? paginatedApplications?.data : dealsData) ?? [];

  if (applicationsLoading || (paginatedApplicationsLoading && !paginatedApplicationsCalled) || !isReady) {
    return <Loading />;
  }

  return (
    <div className="deals-list">
      {isServerSidePaginationEnabled && paginatedApplicationsLoading && <Loading />}
      {((!applicationsLoading && applicationsCalled) ||
        (!paginatedApplicationsLoading && paginatedApplicationsCalled)) && (
        <Table<keyof ApplicationData>
          sortable
          pagination
          globalFiltering
          manualPagination={isServerSidePaginationEnabled}
          manualGlobalFilter={isServerSidePaginationEnabled}
          manualSortBy={isServerSidePaginationEnabled}
          fetchData={isServerSidePaginationEnabled ? fetchData : undefined}
          data={data}
          columns={dealsListColumns}
          pageSize={25}
          itemsCount={isServerSidePaginationEnabled ? paginatedApplications?.total : undefined}
          searchInputProps={{ className: 'fs-mask' }}
        />
      )}
    </div>
  );
};
