import gql from 'graphql-tag';

const ProcessEvaluatedApplicationTypeDef = gql`
  extend type Mutation {
    processEvaluatedApplication(applicationId: Int!, approvalStatus: String!): ProcessEvaluatedApplicationResponse
  }

  type ProcessEvaluatedApplicationError {
    message: String
  }

  type ProcessEvaluatedApplicationResponse {
    applicationId: Int
    applicationStatus: String
    error: ProcessEvaluatedApplicationError
  }
`;

export default [ProcessEvaluatedApplicationTypeDef];
