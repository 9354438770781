"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.classnames = void 0;
function classnames(defaultClassName, classnamesMap) {
    var cls = [defaultClassName];
    if (classnamesMap) {
        for (var className in classnamesMap) {
            if (classnamesMap.hasOwnProperty(className) && classnamesMap[className]) {
                cls.push(className);
            }
        }
    }
    return cls.join(" ");
}
exports.classnames = classnames;
