import gql from 'graphql-tag';

const PolicyTypeDef = gql`
  extend type Mutation {
    vouchPremium(applicationId: Int!, isVouchedPremium: Boolean!): PolicyEntity
  }
  extend type Query {
    policy(id: Int!): PolicyEntity
    generateESDocument(policyId: Int!, documentType: String!, fileName: String): String
  }
  type PolicyEntity {
    id: Int!
    bondNumber: String
    applications: [ApplicationSnapshot]
    status: String!
    carrier: String
    deal: DealSnapshot!
    lease: LeaseSnapshot!
    premium: PremiumSnapshot
    documents: [DocumentSnapshot]
    startAt: String!
    endAt: String!
    createdAt: String!
    updatedAt: String!
    deletedAt: String
    previousPolicyId: Int
  }
  type OrganizationEntity {
    id: Int!
    city: String!
    name: String!
    email: String!
    state: String!
    street: String!
    country: String!
    zipCode: String!
    displayName: String!
  }
  type PremiumSnapshot {
    amount: Float!
    context: PremiumContextSnapshot
  }
  type PremiumContextSnapshot {
    premium: Float!
    premiumAmountWithoutUpfrontDiscount: Float
    premiumAmountBeforeTax: Float
    penalSum: Float!
    amountSDR: Float!
    termFactor: Float!
    leaseMonths: Int!
    monthlyRent: Float!
    premiumRate: Float!
    isRenewalBond: Boolean!
    addRiskPremium: Float!
    coverageMonths: Float!
    fullRentAmount: Float!
    electedCollateral: Float!
    discountCollateral: Float!
    totalCoverageAmount: Float!
    collateralDiscountFactor: Float!
    amountSaved: Float!
  }

  type LeaseSnapshot {
    id: Int!
    property: PropertySnapshot!
    addressZip: String!
    addressCity: String!
    addressUnit: String!
    monthlyRent: Float!
    addressState: String!
    leaseEndDate: String!
    organization: OrganizationSnapshot!
    addressStreet: String!
    leaseStartDate: String!
    actualMoveInDate: String
    expectedMoveInDate: String!
    rentCoverage: Float
    depositsCoverage: Float
    freeRent: Float
    prepaidRent: Float
  }

  type PropertySnapshot {
    id: Int!
    name: String
    mainAddress: String
    city: String
    state: String
    zipCode: String
    displayName: String
    propertyType: String
    llc: String
  }

  type OrganizationSnapshot {
    id: Int!
    name: String
    email: String
    street: String
    city: String
    state: String
    country: String
    zipCode: String
    displayName: String
  }

  type ApplicationSnapshot {
    id: Int!
    addressLine1: String
    addressLine2: String
    addressCity: String
    addressState: String
    addressZip: String
    addressCountry: String
    firstName: String
    lastName: String
    phoneNumber: String
    quote: ApplicationQuote
    paymentMethodType: String
    normalizedFields: NormalizedFields
    applicantEmail: String!
  }

  type DealSnapshot {
    id: Int!
    carrier: String
    product: String
    dealName: String
  }

  type DocumentSnapshot {
    id: Int!
    type: String!
    fileId: Int
  }

  extend type Mutation {
    policyUpdate(
      id: Int!
      applicationId: Int!
      isSkipPayment: Boolean
      actionType: String!
      refundInCents: Int
      returnedPremiumInCents: Int
    ): Int
  }
`;

export default [PolicyTypeDef];
