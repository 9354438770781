import React, { useMemo } from 'react';
import { InfoContainer } from '@theguarantors/ui-kit-v3';
import { Box } from '@theguarantors/ui-kit-v3/design-system';
import { InfoContainerData } from '@theguarantors/ui-kit-v3/components/InfoContainer/InfoContainer.types';
import { getInfoContainerDataRow } from '../../details.utils';
import { ReadableAnswers } from '../../details.constants';
import { DefaultCoverageRulesEntity } from '../../../../api/api-types';

interface DefaultCoverageTiersInfoProps {
  readonly data: DefaultCoverageRulesEntity | undefined;
}

const getCoverageInfo = (value: string | number | undefined | null) => {
  if (value === undefined || value === null || value === '' || value === 'Not Applicable') {
    return ReadableAnswers.NULL;
  }

  return typeof value === 'number' ? String(value) : value;
};

export const DefaultCoverageTiersInfo: React.FC<DefaultCoverageTiersInfoProps> = ({
  data,
  ...rest
}): React.ReactElement => {
  const defaultCoveragesData = useMemo(() => {
    const result: InfoContainerData[] = [
      getInfoContainerDataRow(
        '',
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Box width="50%" display="flex" justifyContent="flex-end">
            <div>Rent Coverage</div>
          </Box>
          <Box width="50%" display="flex" justifyContent="flex-end">
            <div>Deposit Coverage</div>
          </Box>
        </Box>,
      ),
      getInfoContainerDataRow(
        'Approved',
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="right">
          <Box width="50%" display="flex" justifyContent="flex-end">
            <b>{getCoverageInfo(data?.approved?.lgCoverage)}</b>
          </Box>
          <Box width="50%" display="flex" justifyContent="flex-end">
            <b>{getCoverageInfo(data?.approved?.sdrCoverage)}</b>
          </Box>
        </Box>,
      ),
      getInfoContainerDataRow(
        'Conditionally Approved',
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="right">
          <Box width="50%" display="flex" justifyContent="flex-end">
            <b>{getCoverageInfo(data?.conditional?.lgCoverage)}</b>
          </Box>
          <Box width="50%" display="flex" justifyContent="flex-end">
            <b>{getCoverageInfo(data?.conditional?.sdrCoverage)}</b>
          </Box>
        </Box>,
      ),
      getInfoContainerDataRow(
        'Denied',
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="right">
          <Box width="50%" display="flex" justifyContent="flex-end">
            <b>{getCoverageInfo(data?.denied?.lgCoverage)}</b>
          </Box>
          <Box width="50%" display="flex" justifyContent="flex-end">
            <b>{getCoverageInfo(data?.denied?.sdrCoverage)}</b>
          </Box>
        </Box>,
      ),
    ];
    return result;
  }, [data]);

  return (
    <InfoContainer
      color="neutral.main"
      title="Building’s Default Coverage Levels"
      data={defaultCoveragesData}
      dataBlockColProps={{ style: { textTransform: 'capitalize' } }}
      extra={{
        icon: 'lock',
        tooltipTitle: 'This information can`t be edited',
        tooltipId: 'dLargeButton',
      }}
      {...rest}
    />
  );
};
