import gql from 'graphql-tag';

export const DocumentTypeDef = gql`
  extend type Query {
    documentUrl(documentId: Int!, fileId: Int!, fileName: String): String
  }

  type DocumentsEntity {
    id: String!
    type: String!
    fileId: Int
    url: String
  }
`;

export default [DocumentTypeDef];
