import gql from 'graphql-tag';

const LeaseUpdateTypeDef = gql`
  extend type Mutation {
    leaseUpdate(leaseId: Int!, applicationId: Int!, lease: UpdateLeaseInput!): LeaseEntity
  }

  input UpdateLeaseInput {
    leaseStartDate: String
    leaseEndDate: String
    addressUnit: String
    monthlyRent: Float
    rentCoverage: Float
    depositsCoverage: Float
    freeRent: Float
    prepaidRent: Float
  }
`;

export default [LeaseUpdateTypeDef];
