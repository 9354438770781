"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Icon = void 0;
var react_1 = __importStar(require("react"));
var styled_system_1 = require("styled-system");
var design_system_1 = require("../design-system");
var utils_1 = require("../design-system/utils");
var hooks_1 = require("../hooks");
var Icons_list_1 = require("./Icons.list");
var sizeSetter = function (_value, breakpoint) {
    var value = (0, utils_1.getResponsiveAttrValue)(_value, breakpoint);
    return (0, styled_system_1.get)(design_system_1.iconSizes, value || "sm") || value;
};
var Icon = function (_a) {
    var name = _a.name, _color = _a.color, _secondColor = _a.secondColor, _thirdColor = _a.thirdColor, _size = _a.size, _width = _a.width, _height = _a.height, _b = _a.variation, variation = _b === void 0 ? undefined : _b, _c = _a.active, active = _c === void 0 ? false : _c, props = __rest(_a, ["name", "color", "secondColor", "thirdColor", "size", "width", "height", "variation", "active"]);
    var breakpoint = (0, hooks_1.useBreakpointState)();
    var __width = _size || _width;
    var __height = _size || _height;
    var Component = (0, react_1.useMemo)(function () { return Icons_list_1.iconsMap[name]; }, [name]);
    var color = (0, react_1.useMemo)(function () { return (0, styled_system_1.get)(design_system_1.colors, _color) || _color; }, [_color]);
    var secondColor = (0, react_1.useMemo)(function () { return (0, styled_system_1.get)(design_system_1.colors, _secondColor) || _secondColor; }, [_secondColor]);
    var thirdColor = (0, react_1.useMemo)(function () { return (0, styled_system_1.get)(design_system_1.colors, _thirdColor) || _thirdColor; }, [_thirdColor]);
    var width = (0, react_1.useMemo)(function () { return sizeSetter(__width, breakpoint); }, [breakpoint, __width]);
    var height = (0, react_1.useMemo)(function () { return sizeSetter(__height, breakpoint); }, [breakpoint, __height]);
    var extraProps = (0, react_1.useMemo)(function () { return ({
        width: width,
        height: height,
        color: color,
        secondColor: secondColor,
        thirdColor: thirdColor,
    }); }, [color, height, secondColor, thirdColor, width]);
    var _d = (0, react_1.useState)(false), hovered = _d[0], setHovered = _d[1];
    var onMouseLeave = (0, react_1.useCallback)(function () { return setHovered(false); }, []);
    var onMouseEnter = (0, react_1.useCallback)(function (key) {
        setHovered(true);
    }, []);
    var iconStyle = {
        backgroundColor: "var(--color-other-white)",
        border: false,
        color: active && hovered ? "var(--color-logo-green)" : "var(--color-other-white)",
    };
    switch (variation) {
        case "stand-alone":
            iconStyle.backgroundColor = "none";
            iconStyle.color = hovered ? "var(--color-primary-main)" : "var(--color-neutral-main)";
            break;
        case "neutral":
            iconStyle.backgroundColor = active
                ? "var(--color-neutral-main)"
                : hovered
                    ? "var(--color-table-disabledBackground)"
                    : "var(--color-other-white)";
            iconStyle.color = active ? iconStyle.color : "var(--color-neutral-main)";
            iconStyle.border = !active;
            break;
        case "primary":
            iconStyle.backgroundColor = active
                ? "var(--color-neutral-main)"
                : hovered
                    ? "var(--color-primary-hoverBg)"
                    : "var(--color-primary-main)";
            break;
        case "info":
            iconStyle.backgroundColor = active
                ? "var(--color-decorative-darkPurple)"
                : hovered
                    ? "var(--color-info-hoverBg)"
                    : "var(--color-info-main)";
            break;
        default:
            iconStyle = undefined;
            break;
    }
    return react_1.default.createElement(Component, __assign({ onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave }, extraProps, props, iconStyle));
};
exports.Icon = Icon;
