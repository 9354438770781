import { Deals } from '../../pages/deals/deals';
import { Properties } from '../../pages/properties';
import { Details } from '../../pages/details/details';
import { TechnicalDifficultiesPage } from '../../pages/technical-difficulties/technical-difficulties';
import { LoginPage } from '../../pages/login';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Auth0CallbackHandler } from '../../pages/auth0-login-callback';
import { BaseRoutes } from './router.constants';
import { LogoutPage } from '../../pages/logout';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from '../loading';
import { FeatureFlag, useFeatureFlag } from '../../hooks/use-feature-flag';
import { TGAuthCallbackHandler } from '../../pages/tg-auth-callback';
import { useTgAuth } from '../../hooks/use-tg-auth';
import configuration from '../../config/configuration';

const { APPLICATIONS, DEALS, PROPERTIES, TECHNICAL_DIFFICULTIES, LOGIN, AUTH_CALLBACK, LOGOUT, TG_AUTH_CALLBACK } =
  BaseRoutes;

const PublicRoutes = () => (
  <Switch>
    <Route exact path={LOGIN} component={LoginPage} />
    <Route exact path={AUTH_CALLBACK} component={Auth0CallbackHandler} />
    <Redirect from="*" to={LOGIN} />
  </Switch>
);

const PrivateRoutes = () => {
  const [propertiesFlag] = useFeatureFlag(FeatureFlag.PROPERTIES_FLAG);
  const isPropertiesFlagEnabled = propertiesFlag === 'on';

  return (
    <Switch>
      {isPropertiesFlagEnabled && <Route exact path={PROPERTIES} component={Properties} />}
      <Route exact path={DEALS} component={Deals} />
      <Route path={`${APPLICATIONS}/:applicationId`} component={Details} />
      <Route exact path={`${TECHNICAL_DIFFICULTIES}/`} component={TechnicalDifficultiesPage} />
      <Route exact path={LOGOUT} component={LogoutPage} />
      <Redirect from="*" to={DEALS} />
    </Switch>
  );
};

export const Router = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />;
};

export const TGAuthRouter = () => {
  const [propertiesFlag] = useFeatureFlag(FeatureFlag.PROPERTIES_FLAG);
  const isPropertiesFlagEnabled = propertiesFlag === 'on';

  type BaseTGRoutes = {
    [key: string]: {
      path: string;
      exact: boolean;
      publicRoute: boolean;
      component: React.FC;
      hidden?: boolean;
    };
  };

  const BaseTGRoutes: BaseTGRoutes = {
    PROPERTIES: {
      path: PROPERTIES,
      exact: true,
      publicRoute: false,
      component: Properties,
      hidden: !isPropertiesFlagEnabled,
    },
    TG_AUTH_CALLBACK: {
      path: TG_AUTH_CALLBACK,
      exact: true,
      publicRoute: true,
      component: TGAuthCallbackHandler,
    },
    DEALS: {
      path: DEALS,
      exact: true,
      publicRoute: false,
      component: Deals,
    },
    APPLICATIONS: {
      path: `${APPLICATIONS}/:applicationId`,
      exact: false,
      publicRoute: false,
      component: Details,
    },
    TECHNICAL_DIFFICULTIES: {
      path: TECHNICAL_DIFFICULTIES,
      exact: true,
      publicRoute: false,
      component: TechnicalDifficultiesPage,
    },
    LOGOUT: {
      path: LOGOUT,
      exact: true,
      publicRoute: false,
      component: LogoutPage,
    },
  };

  return (
    <Switch>
      {Object.entries(BaseTGRoutes)
        .filter(([, route]) => !route.hidden)
        .map(([key, value]) => {
          const { publicRoute } = value;
          if (publicRoute) {
            return <Route key={key} {...value} />;
          }

          return <ProtectedRoute key={key} {...value} />;
        })}
      <Redirect from="*" to={DEALS} />
    </Switch>
  );
};

const ProtectedRoute = ({ ...rest }) => {
  const { isTGAuthAuthenticated } = useTgAuth();
  if (!isTGAuthAuthenticated) {
    window.location.replace(`${configuration().tgAuthUrls.loginUrl}/okta/msa`);
    return null;
  }

  return <Route {...rest} />;
};
