"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iconSizes = void 0;
exports.iconSizes = {
    xxxs: "0.5rem",
    xxs: "0.75rem",
    xs: "1rem",
    sm: "1.25rem",
    md: "2.8rem",
    lg: "4rem",
    xl: "6rem",
};
