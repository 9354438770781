import gql from 'graphql-tag';

export const RecordTypeDef = gql`
  extend type Query {
    report(id: Int!): ReportEntity
    applicationReport(applicationId: Int!): ReportEntity
  }

  type ReportEntity {
    id: Int!
    service: String!
    status: String!
    serviceData: ReportServiceData
    contactData: ContactEntity!
    createdAt: String
    updatedAt: String
    contactId: Int!
    referenceType: String!
    referenceId: Int!
    provider: ReportProvider
  }

  type ReportServiceData {
    response: ReportServiceDataResponse
  }

  type ReportServiceDataResponse {
    ficoScore: Int
    isSsnMatched: Boolean
    ficoReasonsCount: Int
    isLastNameMatched: Boolean
    unpaidCollections: Boolean
    isFirstNameMatched: Boolean
    bankruptcyDateFiled: Boolean
    isDateOfBirthMatched: Boolean
    bankruptcyCurrentDispositionDate: String
  }

  type ReportProvider {
    id: Int!
    name: String!
    alias: String!
    dataSchema: ReportProviderDataSchema!
    createdAt: String
    updatedAt: String
  }

  type ReportProviderDataSchema {
    ficoscore: String!
  }
`;

export default [RecordTypeDef];
