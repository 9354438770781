"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogoLoader = void 0;
var react_1 = __importStar(require("react"));
var design_system_1 = require("../../design-system");
var interval;
var stepsCount = 100;
var LogoLoader = function (props) {
    var _a = (0, react_1.useState)(0), currentStep = _a[0], setCurrentStep = _a[1];
    (0, react_1.useEffect)(function () {
        interval = setInterval(function () { return setCurrentStep(function (step) { return (step === stepsCount - 1 ? 0 : step + 1); }); }, parseInt(design_system_1.theme.transitions.default) / 10);
        return function () { return clearInterval(interval); };
    }, []);
    return (react_1.default.createElement(design_system_1.Box, __assign({ as: "svg", width: "4rem", viewBox: "0 0 45 45" }, props),
        react_1.default.createElement("g", { clipPath: "url(#clip0_1_48)" },
            react_1.default.createElement("path", { d: "M22.5014 45.0029C34.9286 45.0029 45.0029 34.9286 45.0029 22.5014C45.0029 10.0742 34.9286 0 22.5014 0C10.0742 0 0 10.0742 0 22.5014C0 34.9286 10.0742 45.0029 22.5014 45.0029Z", fill: "url(#grad2)" }),
            react_1.default.createElement("path", { d: "M23.0289 21.0336H27.2173C26.5608 23.717 24.7547 25.3912 22.4986 25.3912C19.2763 25.3912 16.4238 22.8942 16.4238 18.2557C16.4238 13.6173 19.3537 11.2722 22.3724 11.2722C24.9554 11.2722 26.7701 12.5967 27.3836 15.5036H30.7062C29.8891 11.6793 26.5895 9.03039 22.553 9.03039C17.2609 9.03039 13.6058 13.0066 13.6058 18.5883C13.6058 24.1699 17.2093 27.8423 21.5812 27.8423C24.7261 27.8423 27.6215 25.9444 28.2694 22.209V27.5814H30.7062V18.8405H23.0318V21.0365L23.0289 21.0336Z", fill: "#000" }),
            react_1.default.createElement("path", { d: "M30.7033 30.7177H14.1247V34.4645H30.7033V30.7177Z", fill: "#000" })),
        react_1.default.createElement("defs", null,
            react_1.default.createElement("clipPath", { id: "clip0_1_48" },
                react_1.default.createElement("rect", { width: "45", height: "45", fill: "white" })),
            react_1.default.createElement("linearGradient", { id: "grad2", x1: "0%", y1: "100%", x2: "0%", y2: "-250%" },
                react_1.default.createElement("stop", { offset: "0%", style: { stopColor: "#3DB157", stopOpacity: 1 } }),
                react_1.default.createElement("stop", { offset: "".concat(Math.min(100, currentStep), "%"), style: { stopColor: "#c4c4c4", stopOpacity: 1 } })))));
};
exports.LogoLoader = LogoLoader;
