import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#3F3CEC',
      dark: '#0010B8',
    },
    secondary: {
      main: '#1FDDB3',
      light: '#E9F8F8',
    },
    background: {
      default: '#FFF',
    },
    error: {
      main: '#AF332C',
    },
  },
  overrides: {
    MuiTextField: {},
  },
  typography: {
    fontFamily: 'Gilroy',
    fontWeightRegular: 500,
    fontSize: 16,
    h2: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
      color: '#161657',
      fontFamily: 'Tiempos Headline',
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: '2.250rem',
      color: '#73739A',
      fontFamily: 'Tiempos Headline',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
      color: '#161657',
    },
  },
});
