"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Table = void 0;
var react_1 = __importStar(require("react"));
var react_table_1 = require("react-table");
var components_1 = require("..");
var design_system_1 = require("../../design-system");
var icons_1 = require("../../icons");
var utils_1 = require("../../utils");
var Table_pagination_1 = require("./Table.pagination");
var Table_styles_1 = require("./Table.styles");
var Table_utils_1 = require("./Table.utils");
var useCombinedRefs = function () {
    var refs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        refs[_i] = arguments[_i];
    }
    var targetRef = react_1.default.useRef();
    react_1.default.useEffect(function () {
        refs.forEach(function (ref) {
            if (!ref)
                return;
            if (typeof ref === "function") {
                ref(targetRef.current);
            }
            else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);
    return targetRef;
};
var IndeterminateCheckbox = react_1.default.forwardRef(function (_a, ref) {
    var indeterminate = _a.indeterminate, type = _a.type, rest = __rest(_a, ["indeterminate", "type"]);
    var defaultRef = react_1.default.useRef(null);
    var resolvedRef = useCombinedRefs(ref, defaultRef);
    react_1.default.useEffect(function () {
        if (resolvedRef === null || resolvedRef === void 0 ? void 0 : resolvedRef.current) {
            resolvedRef.current.indeterminate = indeterminate !== null && indeterminate !== void 0 ? indeterminate : false;
        }
    }, [resolvedRef, indeterminate]);
    return react_1.default.createElement(components_1.CheckboxNative, __assign({ hideNextElement: true, ref: resolvedRef }, rest));
});
function Table(_a) {
    var _b, _c, _d, _e, _f;
    var data = _a.data, editable = _a.editable, cellRenderer = _a.cellRenderer, updateMyData = _a.updateMyData, validateMyData = _a.validateMyData, _g = _a.skipPageReset, skipPageReset = _g === void 0 ? false : _g, columns = _a.columns, _h = _a.sortable, sortable = _h === void 0 ? false : _h, defaultSortColumn = _a.defaultSortColumn, _j = _a.pagination, pagination = _j === void 0 ? false : _j, _k = _a.globalFiltering, globalFiltering = _k === void 0 ? false : _k, _l = _a.rowSelectable, rowSelectable = _l === void 0 ? false : _l, _m = _a.deletable, deletable = _m === void 0 ? false : _m, _o = _a.onDelete, onDelete = _o === void 0 ? function () { } : _o, topBarActions = _a.topBarActions, _p = _a.getHeaderProps, getHeaderProps = _p === void 0 ? utils_1.noonEmptyObj : _p, _q = _a.getColumnProps, getColumnProps = _q === void 0 ? utils_1.noonEmptyObj : _q, _r = _a.getRowProps, getRowProps = _r === void 0 ? utils_1.noonEmptyObj : _r, _s = _a.getCellProps, getCellProps = _s === void 0 ? utils_1.noonEmptyObj : _s, fetchData = _a.fetchData, _t = _a.isShowCounter, isShowCounter = _t === void 0 ? true : _t, _u = _a.pageSize, pageSize = _u === void 0 ? 10 : _u, _v = _a.pageIndex, pageIndex = _v === void 0 ? 0 : _v, itemsCount = _a.itemsCount, _w = _a.manualPagination, manualPagination = _w === void 0 ? false : _w, _x = _a.manualGlobalFilter, manualGlobalFilter = _x === void 0 ? false : _x, _y = _a.manualSortBy, manualSortBy = _y === void 0 ? false : _y, searchInputProps = _a.searchInputProps;
    var plugins = [];
    var initialState = {};
    var pageCount = itemsCount && Math.ceil(itemsCount / pageSize);
    if (globalFiltering) {
        plugins = __spreadArray(__spreadArray([], plugins, true), [react_table_1.useGlobalFilter], false);
    }
    if (sortable) {
        plugins = __spreadArray(__spreadArray([], plugins, true), [react_table_1.useSortBy], false);
        if (defaultSortColumn) {
            initialState = __assign(__assign({}, initialState), { sortBy: [
                    {
                        id: defaultSortColumn,
                        desc: false,
                    },
                ] });
        }
    }
    if (pagination) {
        initialState = __assign(__assign({}, initialState), { pageIndex: pageIndex, pageSize: pageSize });
        plugins = __spreadArray(__spreadArray([], plugins, true), [react_table_1.usePagination], false);
    }
    if (rowSelectable) {
        plugins = __spreadArray(__spreadArray([], plugins, true), [
            react_table_1.useRowSelect,
            function (hooks) {
                hooks.visibleColumns.push(function (columns) { return __spreadArray([
                    // Let's make a column for selection
                    {
                        id: "selection",
                        Header: function (_a) {
                            var getToggleAllPageRowsSelectedProps = _a.getToggleAllPageRowsSelectedProps;
                            return (react_1.default.createElement(IndeterminateCheckbox, __assign({}, getToggleAllPageRowsSelectedProps())));
                        },
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: function (_a) {
                            var row = _a.row;
                            return react_1.default.createElement(IndeterminateCheckbox, __assign({}, row.getToggleRowSelectedProps()));
                        },
                    }
                ], columns, true); });
            },
        ], false);
    }
    var editableOptions = editable ? { defaultColumn: { Cell: cellRenderer }, updateMyData: updateMyData, validateMyData: validateMyData } : {};
    var table = react_table_1.useTable.apply(void 0, __spreadArray([__assign({ columns: columns, data: data, autoResetPage: !skipPageReset, initialState: initialState, pageCount: pageCount, manualPagination: manualPagination, manualGlobalFilter: manualGlobalFilter, manualSortBy: manualSortBy }, editableOptions)], plugins, false));
    var globalFilterFn = (0, react_1.useCallback)(function (event) {
        table.setGlobalFilter(event.target.value.trim());
    }, [table]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (((_a = table === null || table === void 0 ? void 0 : table.state) === null || _a === void 0 ? void 0 : _a.pageIndex) >= (table === null || table === void 0 ? void 0 : table.pageCount)) {
            table.previousPage();
        }
    }, [(_b = table === null || table === void 0 ? void 0 : table.state) === null || _b === void 0 ? void 0 : _b.pageIndex, table === null || table === void 0 ? void 0 : table.pageCount, table.previousPage, table]);
    (0, react_1.useEffect)(function () {
        if (manualPagination && fetchData) {
            fetchData({
                pageIndex: table.state.pageIndex || null,
                pageSize: table.state.pageSize || null,
                sortBy: manualSortBy ? table.state.sortBy : null,
                globalFilter: manualGlobalFilter ? table.state.globalFilter : null,
            });
        }
    }, [
        fetchData,
        manualPagination,
        manualGlobalFilter,
        manualSortBy,
        table.state.pageIndex,
        table.state.pageSize,
        table.state.sortBy,
        table.state.globalFilter,
    ]);
    var selectedRowIds = Object.keys((_d = (_c = table === null || table === void 0 ? void 0 : table.state) === null || _c === void 0 ? void 0 : _c.selectedRowIds) !== null && _d !== void 0 ? _d : {});
    var selectedCount = rowSelectable ? selectedRowIds === null || selectedRowIds === void 0 ? void 0 : selectedRowIds.length : 0;
    var deleteButtonEnabled = !!selectedCount;
    var handleDelete = react_1.default.useCallback(function () { return onDelete(selectedRowIds); }, [onDelete, selectedRowIds]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        (globalFiltering || topBarActions) && (react_1.default.createElement(Table_styles_1.TopBarContainer, null,
            react_1.default.createElement(design_system_1.Box, { display: "flex", alignItems: "center" },
                react_1.default.createElement("div", null, globalFiltering && react_1.default.createElement(Table_utils_1.SearchInput, { onChange: globalFilterFn, extraProps: searchInputProps })),
                react_1.default.createElement("div", null, rowSelectable && (react_1.default.createElement(design_system_1.Box, { mr: "md", fontWeight: "bold" }, !!selectedCount && "".concat(selectedCount, " Selected")))),
                react_1.default.createElement("div", null, deletable && deleteButtonEnabled && (react_1.default.createElement(design_system_1.Box, { display: "flex", color: "primary.main", cursor: "pointer", fontWeight: "bold", onClick: handleDelete },
                    react_1.default.createElement(icons_1.Icon, { name: "delete", size: "sm", mr: "xs" }),
                    "Delete")))),
            react_1.default.createElement("div", null, topBarActions))),
        react_1.default.createElement(Table_styles_1.TableContainer, __assign({}, table.getTableProps()),
            react_1.default.createElement(Table_utils_1.TableHeader, { headerGroups: table.headerGroups, sortable: sortable, getColumnProps: getColumnProps, getHeaderProps: getHeaderProps }),
            react_1.default.createElement(Table_utils_1.TableBody, { getTableBodyProps: table.getTableBodyProps, list: pagination ? table.page : table.rows, prepareRow: table.prepareRow, getRowProps: getRowProps, getColumnProps: getColumnProps, getCellProps: getCellProps })),
        !table.rows.length && globalFiltering && react_1.default.createElement(Table_utils_1.NoResultsMessage, null, "No Results"),
        pagination && Boolean(table.rows.length) && (react_1.default.createElement(Table_pagination_1.TablePagination, { isShowCounter: isShowCounter, gotoPage: table.gotoPage, canPreviousPage: table.canPreviousPage, nextPage: table.nextPage, previousPage: table.previousPage, canNextPage: table.canNextPage, pageCount: table.pageCount, pageIndex: (_e = table === null || table === void 0 ? void 0 : table.state) === null || _e === void 0 ? void 0 : _e.pageIndex, pageOptions: table.pageOptions, pageSize: (_f = table === null || table === void 0 ? void 0 : table.state) === null || _f === void 0 ? void 0 : _f.pageSize, itemsCount: manualPagination ? itemsCount || table.rows.length : table.rows.length }))));
}
exports.Table = Table;
