"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentYear = exports.months = exports.generateYearsOptions = void 0;
var generateYearsOptions = function (firstYear, lastYear) {
    var years = [];
    for (var i = firstYear; i <= lastYear; i++) {
        years.push({ value: "".concat(i), label: "".concat(i) });
    }
    return years;
};
exports.generateYearsOptions = generateYearsOptions;
exports.months = [
    { value: "january", label: "January" },
    { value: "february", label: "February" },
    { value: "march", label: "March" },
    { value: "april", label: "April" },
    { value: "may", label: "May" },
    { value: "june", label: "June" },
    { value: "july", label: "July" },
    { value: "august", label: "August" },
    { value: "september", label: "September" },
    { value: "october", label: "October" },
    { value: "november", label: "November" },
    { value: "december", label: "December" },
];
var getCurrentYear = function () {
    return new Date().getFullYear();
};
exports.getCurrentYear = getCurrentYear;
