import gql from 'graphql-tag';

const ApplicationSettingsTypeDef = gql`
  extend type Mutation {
    applicationSettingsUpdate(
      applicationId: Int!
      applicationSettingsData: ApplicationSettingsInput!
    ): ApplicationSettings
  }

  input ApplicationSettingsInput {
    comment: String
    firstMonthlyPaymentDate: String
    isMonthlyPayment: Boolean
    isVouchedPremium: Boolean
  }
`;

export default [ApplicationSettingsTypeDef];
