"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Label = exports.Indicator = exports.Input = void 0;
var styled_components_1 = __importStar(require("styled-components"));
exports.Input = styled_components_1.default.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &[type=\"checkbox\"],\n  &[type=\"radio\"] {\n    display: none;\n  }\n"], ["\n  &[type=\"checkbox\"],\n  &[type=\"radio\"] {\n    display: none;\n  }\n"])));
var notDisabledLabelCss = function (checked) { return (0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &:hover {\n    border-color: var(--color-neutral-main);\n  }\n\n  ", ";\n"], ["\n  &:hover {\n    border-color: var(--color-neutral-main);\n  }\n\n  ", ";\n"])), checked
    ? (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        && {\n          border-color: var(--color-neutral-main);\n          border-width: 2px;\n        }\n      "], ["\n        && {\n          border-color: var(--color-neutral-main);\n          border-width: 2px;\n        }\n      "]))) : "\n    margin: 1px;\n  "); };
var borderedLabelCss = function (checked, disabled) { return (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-style: solid;\n  border-width: 1px;\n  border-color: var(--color-table-disabledBackground);\n  border-radius: 4px;\n\n  ", ";\n"], ["\n  border-style: solid;\n  border-width: 1px;\n  border-color: var(--color-table-disabledBackground);\n  border-radius: 4px;\n\n  ", ";\n"])), !disabled && notDisabledLabelCss(checked)); };
exports.Indicator = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 2.5rem;\n  height: 2.5rem;\n  margin-right: var(--space-xs);\n  position: relative;\n  background: transparent ", ";\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: var(--transition-default);\n\n  svg {\n    width: 1.125rem;\n    height: 1.125rem;\n  }\n"], ["\n  width: 2.5rem;\n  height: 2.5rem;\n  margin-right: var(--space-xs);\n  position: relative;\n  background: transparent ", ";\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: var(--transition-default);\n\n  svg {\n    width: 1.125rem;\n    height: 1.125rem;\n  }\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled && "!important";
});
exports.Label = styled_components_1.default.label(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  margin: 0;\n  cursor: ", ";\n  padding: var(--space-xs);\n  color: ", ";\n\n  &:hover {\n    ", " {\n      background: ", ";\n    }\n  }\n\n  ", ";\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  margin: 0;\n  cursor: ", ";\n  padding: var(--space-xs);\n  color: ", ";\n\n  &:hover {\n    ", " {\n      background: ", ";\n    }\n  }\n\n  ", ";\n"])), function (_a) {
    var disabled = _a.disabled;
    return (disabled ? "not-allowed" : "pointer");
}, function (_a) {
    var disabled = _a.disabled;
    return "var(--color-".concat(disabled ? "text-emphasisLow" : "neutral-main", ")");
}, exports.Indicator, function (_a) {
    var isChecked = _a.isChecked, colorType = _a.colorType;
    return "var(--color-".concat(isChecked ? "".concat(colorType, "-bg") : "neutral-bg", ")");
}, function (_a) {
    var bordered = _a.bordered, isChecked = _a.isChecked, disabled = _a.disabled;
    return (bordered ? borderedLabelCss(isChecked, disabled) : "");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
