import compareAsc from 'date-fns/compareAsc/index';
import parse from 'date-fns/parse/index';

export const leaseStartDateSort = (a: string, b: string) => {
  if (!a && !b) {
    return 0;
  }

  if (!a && b) {
    return -1;
  }

  if (!b && a) {
    return 1;
  }

  return compareAsc(parse(a, 'yyyy-MM-dd', new Date()), parse(b, 'yyyy-MM-dd', new Date()));
};
