"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TermsAndConditionCard = void 0;
var react_1 = __importStar(require("react"));
var components_1 = require("..");
var design_system_1 = require("../../design-system");
var hooks_1 = require("../../hooks");
var Card_styles_1 = require("./Card.styles");
var HeadingRibbon_1 = require("./HeadingRibbon");
exports.TermsAndConditionCard = (0, react_1.memo)(function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? "default" : _b, onClick = _a.onClick, isChecked = _a.isChecked, message = _a.message, heading = _a.heading, subMessage = _a.subMessage, buttonText = _a.buttonText, props = __rest(_a, ["variant", "onClick", "isChecked", "message", "heading", "subMessage", "buttonText"]);
    var breakpointState = (0, hooks_1.useBreakpointState)();
    var isMobile = breakpointState === "mobile";
    return (react_1.default.createElement(Card_styles_1.TermsAndConditionCardContainer, { "data-testid": props["data-testid"], isMobile: isMobile, filter: 1 },
        react_1.default.createElement(HeadingRibbon_1.HeadingRibbon, { variant: variant }),
        heading && (react_1.default.createElement(design_system_1.Header3, { mb: "lg", mt: variant === "default" ? "clear" : "sm" }, heading)),
        react_1.default.createElement(Card_styles_1.SectionContainer, null, message && react_1.default.createElement("div", null, message)),
        react_1.default.createElement("div", null,
            react_1.default.createElement(design_system_1.Text, null, "A detailed listing of all policy coverages, government levied taxes, and assessments will be provided with your policy. Your insurance policy may include optional endorsements."),
            react_1.default.createElement("p", null,
                "I have read, understand and agree to the Automatic Payment ",
                react_1.default.createElement("a", { href: "/" }, "Terms and Conditions"),
                ", and intend this checkbox to serve as my electronic signature accepting such terms and conditions."),
            react_1.default.createElement(components_1.Checkbox, { name: "demo", children: "I acknowledge that I have read the agreement.", colorType: "primary" })),
        buttonText && react_1.default.createElement(components_1.Button, { size: isMobile ? "m" : "l" }, buttonText),
        subMessage && react_1.default.createElement(design_system_1.Text, null, subMessage)));
});
