"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoundButton = exports.Button = void 0;
var react_1 = __importStar(require("react"));
var icons_1 = require("../../icons");
var Button_styles_1 = require("./Button.styles");
var Button_utils_1 = require("./Button.utils");
exports.Button = (0, react_1.memo)((0, react_1.forwardRef)(function (_a, ref) {
    var round = _a.round, _b = _a.size, size = _b === void 0 ? "m" : _b, children = _a.children, loading = _a.loading, _c = _a.bType, bType = _c === void 0 ? "primary" : _c, _d = _a.bVariant, bVariant = _d === void 0 ? "contained" : _d, startIcon = _a.startIcon, endIcon = _a.endIcon, props = __rest(_a, ["round", "size", "children", "loading", "bType", "bVariant", "startIcon", "endIcon"]);
    var ButtonComponent = round ? Button_styles_1.RoundButtonContainer : Button_styles_1.ButtonContainer;
    var selectedColor = (0, react_1.useMemo)(function () { return (0, Button_utils_1.getColorByVariant)(bVariant, bType); }, [bVariant, bType]);
    var iconProps = (0, react_1.useCallback)(function (start, icon) { return ({
        width: Button_styles_1.buttonIconSizesMap[size],
        height: Button_styles_1.buttonIconSizesMap[size],
        ml: start ? 0 : Button_styles_1.buttonIconMarginsMap[size],
        mr: start ? Button_styles_1.buttonIconMarginsMap[size] : 0,
        name: icon,
        color: props.disabled ? "text.disabled" : selectedColor,
    }); }, [size, props.disabled, selectedColor]);
    return (react_1.default.createElement(ButtonComponent, __assign({ ref: ref, as: "button", size: size, loading: loading === null || loading === void 0 ? void 0 : loading.toString(), bType: loading ? "".concat(bType, "-loading") : bType, bVariant: bVariant }, props, { "data-testelement": round ? "roundButton" : "button" }),
        startIcon && react_1.default.createElement(icons_1.Icon, __assign({}, iconProps(true, startIcon))),
        children,
        endIcon && react_1.default.createElement(icons_1.Icon, __assign({}, iconProps(false, endIcon)))));
}));
var iconSizes = {
    s: "0.66rem",
    m: "1rem",
    l: "1.5rem",
};
var RoundButton = function (_a) {
    var _b = _a.size, size = _b === void 0 ? "m" : _b, _c = _a.bType, bType = _c === void 0 ? "primary" : _c, _d = _a.bVariant, bVariant = _d === void 0 ? "contained" : _d, iconName = _a.iconName, props = __rest(_a, ["size", "bType", "bVariant", "iconName"]);
    var selectedColor = (0, react_1.useMemo)(function () { return (0, Button_utils_1.getColorByVariant)(bVariant, bType); }, [bVariant, bType]);
    return (react_1.default.createElement(exports.Button, __assign({ round: true }, props, { size: size, bType: bType, bVariant: bVariant }),
        react_1.default.createElement(icons_1.Icon, { name: iconName, color: props.disabled ? "text.disabled" : selectedColor, width: iconSizes[size], height: iconSizes[size] })));
};
exports.RoundButton = RoundButton;
