import React, { ReactNode } from 'react';
import { Box, Button, Modal } from '@theguarantors/ui-kit-v3';
import { Icon } from '@theguarantors/ui-kit-v3/icons';
import { Header2, MicroText, Text } from '@theguarantors/ui-kit-v3/design-system';

interface EvaluationResultModalProps {
  readonly isOpen: boolean;
  readonly isLoading: boolean;
  readonly evaluationResultStatus: 'approved' | 'denied' | 'failed';
  readonly errorMessage?: string;
  readonly closeModal: () => void;
  readonly continueHandler?: () => void;
}

interface EvaluationModalTexts {
  icon: ReactNode;
  header: string;
  subheader: string;
  text?: string;
}

const headerTextByStatus: Record<EvaluationResultModalProps['evaluationResultStatus'], EvaluationModalTexts> = {
  approved: {
    icon: <Icon name="checkCircle" color="neutral.hoverBg" size="7rem" />,
    header: 'Renter Approved',
    subheader: 'Would you like to continue?',
  },
  denied: {
    icon: <Icon name="close" color="other.body" size="5rem" my="1rem" />,
    header: 'Renter Denied',
    subheader: 'Would you like to continue?',
  },
  failed: {
    icon: <Icon name="error" color="error.main" size="7rem" />,
    header: 'Error',
    subheader: 'Operation could not be completed.',
    text: 'Please try again. If error continues, notify your manager for further steps.',
  },
};

export const EvaluationResultModal: React.FC<EvaluationResultModalProps> = ({
  errorMessage,
  evaluationResultStatus,
  isOpen,
  closeModal,
  continueHandler,
  isLoading,
}) => {
  const displayContinueButton = evaluationResultStatus === 'approved' || evaluationResultStatus === 'denied';
  const displayErrorMessage = evaluationResultStatus === 'failed' && errorMessage;
  const microText: string | undefined = headerTextByStatus[evaluationResultStatus].text;

  return (
    <Modal
      data-testid="evaluation-result-modal"
      isOpen={isOpen}
      headingContent={<Box width="100%" height="1rem" display="flex" backgroundColor="primary.main" />}
      height="auto"
      width="38rem"
      title=""
      onClose={closeModal}
    >
      <Box px="4rem" textAlign="center" mb="4rem">
        <Box mb="1.5rem" fontWeight="bold">
          {headerTextByStatus[evaluationResultStatus].icon}
        </Box>
        <Box mb="1.5rem" color="neutral.hoverBg">
          <Header2>{headerTextByStatus[evaluationResultStatus].header}</Header2>
        </Box>
        <Box mb=".5rem">
          <Text size="text-lg">
            {displayErrorMessage ? errorMessage : headerTextByStatus[evaluationResultStatus].subheader}
          </Text>
        </Box>
        {microText && (
          <Box>
            <MicroText>{microText}</MicroText>
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button size="m" bType="neutral" bVariant="outlined" onClick={closeModal}>
          Go Back
        </Button>
        {displayContinueButton && (
          <Button size="m" ml="1rem" onClick={continueHandler}>
            {isLoading ? (
              <>
                <Icon name="loading" color="other.white" marginRight="xs" />
                Loading
              </>
            ) : (
              'Continue'
            )}
          </Button>
        )}
      </Box>
    </Modal>
  );
};
